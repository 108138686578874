import React ,{useState}from 'react';

import Grid from '@material-ui/core/Grid';
import Image from '../../../assets/Images/HomeHair.png';
import Image1 from '../../../assets/Images/cover.jpg';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import StorefrontIcon from '@material-ui/icons/Storefront';
import DateRangeIcon from '@material-ui/icons/DateRange';

import './D6_halo_cardpltweb7.css';
import Avatar from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import { PayDialog1 } from './D6_halo_cardpltweb1copy';

import Loc from '@material-ui/icons/LocationOnOutlined';
import HomePh from '@material-ui/icons/PhoneIphoneOutlined';
import WorkPh from '@material-ui/icons/PhoneInTalkOutlined';
import WorkEmail from '@material-ui/icons/EmailOutlined';
import Website from '@material-ui/icons/LanguageOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { QRCode } from 'react-qrcode-logo';
import YouTube from '@material-ui/icons/YouTube';
import Telegram from '@material-ui/icons/Telegram';
import GoogleMeetIcon from '@material-ui/icons/Duo';
import CreditCard from '@material-ui/icons/CreditCard';
import reactCSS from 'reactcss'

export default function CardPltWeb7(props) {
 

  const {name,email,designation,organization,web,mobile,mobile1,image,facebook,instagram,whatsapp,linkedIn,twitter,gitHub,youTube,telegram
    ,googleMeet,sun,location,mon,tue,wed,thur,fri,sat,headline,image3,pay1,pay2,pay3,pay1Value,pay2Value,pay3Value,note,stat,color1,color2,colorT,snapChat}=props
    
    const [active,setActive]=useState(mobile?'m1':mobile1?'m2':'m1')
    
    const [qr1,setQr1]=useState('card')

    const date=new Date()                                
    const [active1,setActive1]=useState(date.getDay()===1?'mon':date.getDay()===2?'tue':date.getDay()===3?'wed':date.getDay()===4?'thur':date.getDay()===5?'fri':date.getDay()===6?'sat':date.getDay()===0?'sun':'mon')
    // const [colorA,setColorA]=useState(color1?color1:'#7ec3de')
    // const [colorB,setColorB]=useState(color2?color2:'#ffffdd')
    const val1=`https://halodigital.app/${props.username?props.username:props.key1}`
    const val2='https://halodigital.app/'
      
                                                          
   const handleClose = () => {

      setOpen1(false);
 
      setOpen2(false)


      setOpen(false);



    };
    const [open, setOpen] = React.useState(false);
  
    const [open2, setOpen2] = React.useState(false);

    const [open1, setOpen1] = React.useState(false);
    const image2=`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/profile/${props.image1?props.image1:null}`
    const image5=`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/profile/${props.image4?props.image4:null}`

    const styles = reactCSS({
      'default': {
        PltBoxContainerWeb7: {
          width: '350px',
      minHeight:'550px',
      display:'flex',
      flexDirection:'column',
      borderRadius:'19px',
      
      // backgroundColor:`${color1?color1:color}`,
      background:`linear-gradient(180deg,${color1?color1:'#E64085'} 20%,${color2?color2:'#ffffdd'} 100%)`,
  boxShadow:'0 0 2px rgba(15, 15, 15, 0.28)',
      // backgroundColor:"linearGradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6))",
  overflow:'hidden',
      opacity: '2px',
      flexDirection:'column',
      justifyContent: 'flex-start',
      /* padding: 021.8em; */
      // paddingBottom: '0.8em',
      top:'2px',
      position: 'relative',
      fontFamily: "var(--fontfamily)",
      zIndex:"7",
      bottom:'10px',
      justifyContent:'flex-end',
    
   
        },
        present_day: {
          // backgroundColor: '#ffff',
    borderRadius: '50%',
    borderColor:`${colorT?colorT:'black'}`,

    borderWidth: '1px',
    borderStyle: 'solid'
        }}})

  return (
    <div className="PltDivW7">
      
    <div style={styles.PltBoxContainerWeb7}  >
    <div>
    <img src={props.image4&&stat=='0'&&!image3?Image1:props.image4&&stat=='1'&&!image3?image5:image3?URL.createObjectURL(image3):Image1}  className='PltTopContainerWeb3'/>       </div>
       {/* <div className="PltBackDropLeftW12">
       </div>
       <div className="PltBackDropRightW12">
       </div> */}
       
       <div className="PltAvatarCont7">
       <Avatar alt="Remy Sharp" src={props.image1?image2:image?URL.createObjectURL(image):Image} className="PltAvatarPic7"/>
       </div>
            <div style={{color:colorT?colorT:'black'}} className="D6_CARD_PltW7_Grid_Name">
            {name?name:!name?'Your Name':null} 
            </div>
            <div style={{color:colorT?colorT:'black'}} className="D6_CARD_PltW7_Grid_headline">
            {headline?headline:!name?'Headline':null}

            </div>
            <div style={{color:colorT?colorT:'black'}} className="D6_CARD_PltW7_Grid_desig">
            {designation?designation:!name?'Your Designation':null} 
            </div>
            <Grid container >
               <Grid container item xs={6} style={{color:colorT?colorT:'black'}} className="D6_Card_PltW7_Loc_Grid">
               <Grid  item xs={4}>
                        {
                                  !name||location?
   <Loc onClick={()=>{location.includes('://')?window.open(location,'_blank'):window.open(`https://www.google.co.in/maps/place/${location}`,'_blank')}}/>:null}
                        </Grid>
                      
                </Grid>
                <Grid container style={{color:colorT?colorT:'black'}} item xs={6} className="D6_Card_PltW7_BH_Grid">
                      <Grid container item xs={10}>
                      
                        { mon?
                        <Grid  style={date.getDay()===1?styles.present_day:null} onClick={()=>setActive1('mon')}item xs={2}  >
                          M
                        </Grid>:null}
                        { tue?<Grid style={date.getDay()===2?styles.present_day:null} onClick={()=>setActive1('tue')}item xs={2}>
                          T
                        </Grid>:null}
                        {wed?
                        <Grid style={date.getDay()===3?styles.present_day:null} onClick={()=>setActive1('wed')} item xs={2}>
                          W
                        </Grid>:null}
                        {thur?
                        <Grid style={date.getDay()===4?styles.present_day:null} onClick={()=>setActive1('thur')} item xs={2}>
                          T
                        </Grid>:null}
                        {fri?
                        <Grid style={date.getDay()===5?styles.present_day:null} onClick={()=>setActive1('fri')} item xs={2}>
                          F
                        </Grid>:null}
                        {sat?
                        <Grid style={date.getDay()===6?styles.present_day:null}  onClick={()=>setActive1('sat')} item xs={2}>
                          S
                        </Grid>:null}
                        </Grid>
                    
                        {sun?
                        <Grid style={date.getDay()===0?styles.present_day:null} onClick={()=>setActive1('sun')} item xs={2}>
                          S
                        </Grid>:null}
                        <Grid item xs={12}>
                        {
                       
                       (active1==='sun'&&sun?sun:
                       (active1==='mon')&&mon?mon
                       :(active1==='tue'&&tue?tue
                       :(active1==='wed'&&wed?wed
                       :(active1==='thur'&&thur?thur
                       :(active1==='fri'&&fri?fri
                       :(active1==='sat'&&sat?sat
                       :mon
           
                       ))))))}                        </Grid>
                </Grid>
            </Grid>
            <div style={{color:colorT?colorT:'black'}} className="D6_CARD_PltW7_Grid_comp">
                    {organization?organization:!name?'Your Organization':null} 
                    </div>
            <Grid container spacing={0} direction='row' item xs={12} >
                <Grid  container item xs={8} >
                    <div className="D6_Card_PltW7_ContactData_Grid">
                    <div >
                       {/* {name?name:'Your Name'}  */}
                    </div>
                    <div >
                    {/* {designation?designation:'Your Designation'}  */}
                    </div>
                    {/* <div className="D6_CARD_PltW7_Grid_comp">
                    {organization?organization:!name?'Your Organization':null} 
                    </div> */}
                    <div >
                       {/* Headline */}
                    </div>
                    <div  style={{color:colorT?colorT:'black'}} className="D6_CARD_PltW7_Grid_email">
                    <Grid onClick={()=>window.open(`mailto:${email}`)} container direction="row" alignItems="center" item xs={12}>
                    <Grid item xs={2}>{
                                  !name||email?<WorkEmail /> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                  {email?email:!name?'Your Email':null}
                                </Grid>
                        </Grid>
                    </div>
                    <div style={{color:colorT?colorT:'black'}} className="D6_CARD_PltW7_Grid_web">
                          <Grid onClick={()=>{web.includes('://')?window.open(web,'_blank'):window.open(`https://${web}`,'_blank')}}container direction="row" alignItems="center" item xs={12}>
                          <Grid item xs={2}>
                                  {!name||web?
                                  <Website /> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                {web?web:!name?'Your Website':null}         
                                </Grid>
                          </Grid>
                    </div>
                    <div style={{color:colorT?colorT:'black'}} className="D6_CARD_PltW7_Grid_tel">
                         <Grid container direction="row" alignItems="center" item xs={12}>
                         <Grid item xs={2}>
                                  {!name||mobile?
                                  <HomePh onClick={()=>setActive('m1')}/>:null}                        
                                </Grid>
                                
                                <Grid item xs={2}>
                                {!name||mobile1?
                                  <WorkPh onClick={()=>setActive('m2')}/>:null}
                                </Grid>
                                <Grid onClick={()=>window.location.href=`tel:${mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:null}`} item xs={8}>
                                {mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:!name?'Your Mobile Number':!mobile&&mobile1?mobile1:!mobile1&&mobile?mobile:null} 
                                </Grid>
                        </Grid>     
                          </div>
                    </div>
                  </Grid>
                <Grid container direction='row' item xs={4} className="D6_Card_PltW7_QRCont_Grid">
                      <Grid item xs={12} >
                         
                      </Grid>
                      <Grid onClick={() => setOpen(!open)} item xs={12} className="D6_Card_PltW7_QR_Grid">
                      <QRCode
          size={90}
          value={props.key1?val1:val2}
        //   logoImage={logo}
        ecLevel='H'
          logoOpacity='1'
          qrStyle='dots'
          // logoWidth='60'
          quietZone='5'
          fgColor='#000000'
        
        />           
                      </Grid>
                      <Dialog
                          disableScrollLock={ true }
                          open={open}
                          // PaperComponent={PaperComponent}
                          aria-labelledby="draggable-dialog-title"
                        >
                     <Grid  item xs={12} className="D6_Card_ClsW1_QR_Grid">
                                          <QRCode
                                          
                              size={200}
                              value={qr1=='business'&&props.business&&props.business==1?`https://halodigital.app/business/${props.username?props.username:props.key1}`:`https://halodigital.app/${props.username?props.username:props.key1}`}
                              //   logoImage={logo}
                            ecLevel='H'
                              logoOpacity='1'
                              qrStyle='dots'
                              // logoWidth='60'
                              quietZone='5'
                              fgColor='#000'
                            
                            /> 

<Grid container justifyContent='center' sm={12} xs={12}>
<div onClick={()=>{setQr1('card')}} className='change_qr' style={qr1=='card'?{borderBottomStyle:'solid',borderBottomWidth:'2px'}:{borderBottomStyle:"none"}} >Card</div>
{props.business&&props.business==1?<div onClick={()=>{setQr1('business')}} className='change_qr' style={qr1=='business'?{borderBottomStyle:'solid',borderBottomWidth:'2px'}:{borderBottomStyle:"none"}}>Business</div>
:null}
</Grid>

                            <div onClick={()=>{setOpen(false)}} style={{marginTop:'10px',fontWeight:900,backgroundColor:'black',color:'white'}} >Back</div>
                            </Grid>   </Dialog>
                </Grid>
            </Grid>
            <Grid container direction='column' item xs={12}  >
                <Grid container spacing={1} direction="column" item xs={12}>
                           {/* <Grid  item xs={2}>
                            <FacebookIcon  className="D6_Card_Opt_Fields"/>                        
                          </Grid>
                          <Grid item xs={2}>
                            <WhatsAppIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid item xs={2}>
                            <LinkedInIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid item xs={2}>
                            <InstagramIcon className="D6_Card_Opt_Fields"/>                       
                          </Grid>
                          <Grid  item xs={2}>
                             <GitHubIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid item xs={2}>
                            <TwitterIcon  className="D6_Card_Opt_Fields"/>
                          </Grid> */}

                </Grid>
            </Grid>
    </div>
    <Grid container  item xs={12}  alignItems="center" className="PltBackDropBottomW3">
               {/* <Grid container item xs={12}></Grid> */}
                <Grid container spacing={1}  item xs={12} alignItems="center"  >
                <Grid container item xs={12} alignItems="center" >
                  <div className="D6_Bottom_SMCont"></div>
                </Grid>
                {facebook?

<Grid className="D6_Bottom_SM" item xs={2}>
<FacebookIcon onClick={()=>{facebook.includes('://')?window.open(facebook,'_blank'):window.open(`https://www.facebook.com/${facebook}`,'_blank')}} />                        
 </Grid>:null}
 {whatsapp?
 <Grid  className="D6_Bottom_SM" item xs={2}>
                  <WhatsAppIcon onClick={()=>{whatsapp.includes('://')?window.open(whatsapp,'_blank'):window.open(`https://wa.me/${whatsapp}`,'_blank')}} />
 </Grid>:null}
 {linkedIn?
 <Grid className="D6_Bottom_SM" item xs={2}>
                  <LinkedInIcon   onClick={()=>{linkedIn.includes('://')?window.open(linkedIn,'_blank'):window.open(`https://www.linkedin.com/in/${linkedIn}`,'_blank')}}/>
 </Grid>:null}
 {instagram?
 <Grid className="D6_Bottom_SM" item xs={2}>
                  <InstagramIcon onClick={()=>instagram.includes('://')?window.open(instagram,'_blank'): window.open(`https://www.instagram.com/${instagram}`,'_blank')} />                       
 </Grid>:null}
 {gitHub?
 <Grid  className="D6_Bottom_SM" item xs={2}>
                    <StorefrontIcon onClick={()=>{gitHub.includes('://')?window.open(gitHub,'_blank'):window.open(`${gitHub}`,'_blank')}}  />
 </Grid>:null}
 {twitter?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <TwitterIcon  onClick={()=>{twitter.includes('://')?window.open(twitter,'_blank'): window.open(`https://twitter.com/${twitter}`,'_blank')}} />
 </Grid>:null}

 
 {youTube?

<Grid className="D6_Bottom_SM" item xs={2}>
   <YouTube onClick={()=>{youTube.includes('://')?window.open(youTube,'_blank'):window.open(`https://www.youtube.com/watch?v=${youTube}`,'_blank')}} />                        
 </Grid>:null}
 {telegram?
 <Grid  className="D6_Bottom_SM" item xs={2}>
   <Telegram onClick={()=>{telegram.includes('://')?window.open(telegram,'_blank'):window.open(`https://t.me/${telegram}`,'_blank')}} />
 </Grid>:null}
 {googleMeet?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <DateRangeIcon   onClick={()=>{googleMeet.includes('://')?window.open(googleMeet,'_blank'):window.open(`https://meet.google.com/${googleMeet}`,'_blank')}}/>
 </Grid>:null}

 {(pay1&&(pay1Value||props.imageQr1))||(pay2&&(pay2Value||props.imageQr2))||(pay3&&(pay3Value||props.imageQr3))?
              <Grid onClick={()=>setOpen1(true)}  className="D6_Bottom_SM" item xs={2}>
                <AccountBalanceIcon />
              </Grid>:null}
              {note?
              <Grid onClick={()=>setOpen2(true)} className="D6_Bottom_SM" item xs={2}>
                <SpeakerNotesIcon />
              </Grid>:null}
              {snapChat?<Grid onClick={()=>{snapChat.includes('://')?window.open(snapChat,'_blank'):window.open(`https://www.snapchat.com/add/${snapChat}`,'_blank')}}className="D6_Bottom_SM" item xs={2}>
                          <i class="fab fa-snapchat-ghost snap_plt_card"></i>
 </Grid>:null}
            <Dialog

disableScrollLock={ true }
open={open1}
      fullWidth
    // maxWidth='sm'
      // style={{width}}
      onClose={handleClose}
      // aria-labelledby="draggable-dialog-title"
      // maxWidth='xl'
    >
     <PayDialog1 pay1={pay1} pay2={pay2} pay3={pay3} pay1Value={pay1Value} payValue={pay2Value} pay3Value={pay3Value} pay1ValueN={props.pay1ValueN} pay2ValueN={props.pay2ValueN} pay3ValueN={props.pay3ValueN}
            imageQr1={props.imageQr1} imageQr2={props.imageQr2} imageQr3={props.imageQr3}   /> 
  </Dialog>
  <Dialog
disableScrollLock={ true }
open={open2}
      
    // maxWidth='sm'
      // style={{width}}
      onClose={handleClose}
      // aria-labelledby="draggable-dialog-title"
      // maxWidth='xl'
    >
      <div style={{margin:'10px'}} >
      <TextField 
      
                        value={note}
                        disabled
                        style={{width:'300px'}}
          label="Notes"
          placeholder="Write your notes here.."
          multiline
          rows={7}

          variant="outlined"
        />        
        </div>
  </Dialog>

                </Grid>
            </Grid>
    </div>
  );
}
