import React from 'react'
import CardPltWeb1copy from './PltCard/D6_halo_cardpltweb1copy'
import Grid from '@material-ui/core/Grid';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import './animation.css'
import CardPltWeb5Copy from './PltCard/D6_halo_cardpltweb5copy';
import CardPltWeb3CopyH from './PltCard/D6_halo_cardpltweb3copyH';
import CardPltWeb1CopyH from './PltCard/D6_halo_cardpltweb1copyH';
import CardPltWeb5CopyH from './PltCard/D6_halo_cardpltweb5copyH';
import CardPltWeb6CopyH from './PltCard/D6_halo_cardpltweb6copyH';
import CardPltWeb3Copy from './PltCard/D6_halo_cardpltweb3copy';
import CardPltWeb2CopyH from './PltCard/D6_halo_cardpltweb2copyH';
import CardSigweb1copyH from './SigCard/D6_halo_cardsigweb1copyH';
import CardClsweb1H from './ClsCard/D6_halo_cardclsweb1H';
import CardClsweb2H from './ClsCard/D6_halo_cardclsweb2H';
import CardClsweb3H from './ClsCard/D6_halo_cardclsweb3H';
const D6_DASAC_Contact = () => {
    return (
        <>  
        
          <CardPltWeb5Copy />
          <CardPltWeb3Copy/>
          <CardSigweb1copyH/>
          <CardPltWeb1CopyH/>
          <CardPltWeb2CopyH/>
          <CardPltWeb3CopyH/>
          <CardPltWeb5CopyH/>
          <CardPltWeb6CopyH/>
          <CardClsweb1H />
          <CardClsweb2H />
          <CardClsweb3H />

          


        </>
    )
}

export default D6_DASAC_Contact
