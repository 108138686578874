import React from 'react'
// import CardPltWeb1 from './D6_halo_cardpltweb1'
import CardPltWeb1copy from './D6_halo_cardpltweb1copy'
// import CardPltWeb2 from './D6_halo_cardpltweb2'
import CardPltWeb2copy from './D6_halo_cardpltweb2copy'
// import CardPltWeb3 from './D6_halo_cardpltweb3'
import CardPltWeb3Copy from './D6_halo_cardpltweb3copy'
import CardPltWeb5Copy from './D6_halo_cardpltweb5copy'
import CardPltWeb6Copy from './D6_halo_cardpltweb6copy'
import CardPltWeb7 from './D6_halo_cardpltweb7'

export default function D6_halo_cardpltweb(props) {
  const{card}=props
  const {name,email,designation,organization,web,mobile,mobile1,image,image1,facebook,instagram,whatsapp,linkedIn,twitter,gitHub,youTube,telegram
    ,googleMeet,location,sun,mon,tue,wed,thur,fri,sat,color1,color2,headline,key1,image3,image4,pay1,pay2,pay3,pay1Value,pay2Value,pay3Value,note,
  stat,colorT,snapChat,username,business,pay1ValueN,pay2ValueN,pay3ValueN,imageQr1,imageQr2,imageQr3}=props
 
  return (
    <div>{card==='1'?
      <CardPltWeb1copy name={name} email={email} designation={designation} 
      organization={organization} web={web} mobile={mobile} mobile1={mobile1}  image={image} image1={image1}
      facebook={facebook} instagram={instagram} headline={headline} key1={key1}
                whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube}
                telegram={telegram} googleMeet={googleMeet} pay1={pay1} pay1Value={pay1Value} pay2={pay2}pay2Value={pay2Value} pay3={pay3}pay3Value={pay3Value}  pay1ValueN={pay1ValueN} pay2ValueN={pay2ValueN}  pay3ValueN={pay3ValueN}
                
                imageQr1={imageQr1} imageQr2={imageQr2} imageQr3={imageQr3}
                
                
                note={note} username={username} snapChat={snapChat} business={business} />
      :card==='2'?
      <CardPltWeb2copy location={location} name={name} email={email} designation={designation} 
      organization={organization} web={web} key1={key1} mobile1={mobile1}  mobile={mobile} image={image} image1={image1}
      facebook={facebook} color1={color1} instagram={instagram} headline={headline} username={username}
                whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube}



                pay1ValueN={pay1ValueN} pay2ValueN={pay2ValueN}  pay3ValueN={pay3ValueN}
                
                imageQr1={imageQr1} imageQr2={imageQr2} imageQr3={imageQr3}
                telegram={telegram} googleMeet={googleMeet}  pay1={pay1} pay1Value={pay1Value} pay2={pay2}pay2Value={pay2Value} pay3={pay3}pay3Value={pay3Value} note={note} snapChat={snapChat} business={business} />
                :card==='3'?

      <CardPltWeb3Copy location={location} name={name} email={email} designation={designation} 
      organization={organization} web={web} key1={key1} mobile1={mobile1}  mobile={mobile} image={image} image1={image1}
      facebook={facebook} instagram={instagram} headline={headline} stat={stat}


      pay1ValueN={pay1ValueN} pay2ValueN={pay2ValueN}  pay3ValueN={pay3ValueN}
                
                imageQr1={imageQr1} imageQr2={imageQr2} imageQr3={imageQr3}
      whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube} username={username}
      telegram={telegram} googleMeet={googleMeet} sun={sun} mon={mon}tue={tue}wed={wed}thur={thur}fri={fri} sat={sat} image3={image3} image4={image4}
      pay1={pay1} pay1Value={pay1Value} pay2={pay2}pay2Value={pay2Value} pay3={pay3}pay3Value={pay3Value} note={note} snapChat={snapChat} business={business}/>
    :card==='4'?
    <CardPltWeb5Copy location={location} name={name} email={email} designation={designation} 
    organization={organization} web={web} key1={key1} mobile1={mobile1}  mobile={mobile} image={image} image1={image1}
    facebook={facebook} instagram={instagram} headline={headline} stat={stat} username={username}
    whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube}

    pay1ValueN={pay1ValueN} pay2ValueN={pay2ValueN}  pay3ValueN={pay3ValueN}
                
                imageQr1={imageQr1} imageQr2={imageQr2} imageQr3={imageQr3}
    telegram={telegram} googleMeet={googleMeet} sun={sun} mon={mon}tue={tue}wed={wed}thur={thur}fri={fri} sat={sat} image3={image3} image4={image4}
    pay1={pay1} pay1Value={pay1Value} pay2={pay2}pay2Value={pay2Value} pay3={pay3}pay3Value={pay3Value} note={note} snapChat={snapChat} business={business}/>
    :card==='5'?<CardPltWeb6Copy location={location} name={name} email={email} designation={designation} 
    organization={organization} web={web} key1={key1} mobile1={mobile1}  mobile={mobile} image={image} image1={image1}
    facebook={facebook} instagram={instagram} headline={headline} stat={stat}username={username}
    whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube}

    pay1ValueN={pay1ValueN} pay2ValueN={pay2ValueN}  pay3ValueN={pay3ValueN}
                
                imageQr1={imageQr1} imageQr2={imageQr2} imageQr3={imageQr3}
    telegram={telegram} googleMeet={googleMeet} sun={sun} mon={mon}tue={tue}wed={wed}thur={thur}fri={fri} sat={sat} image3={image3} image4={image4}
    pay1={pay1} pay1Value={pay1Value} pay2={pay2}pay2Value={pay2Value} pay3={pay3}pay3Value={pay3Value} note={note} snapChat={snapChat} business={business}/>
    :<CardPltWeb7 location={location} name={name} email={email} designation={designation} username={username}
    organization={organization} web={web} key1={key1} mobile1={mobile1}  mobile={mobile} image={image} image1={image1}
    facebook={facebook} instagram={instagram} headline={headline} stat={stat}

    pay1ValueN={pay1ValueN} pay2ValueN={pay2ValueN}  pay3ValueN={pay3ValueN}
                
                imageQr1={imageQr1} imageQr2={imageQr2} imageQr3={imageQr3}
    whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube}
    telegram={telegram} googleMeet={googleMeet} sun={sun} mon={mon}tue={tue}wed={wed}thur={thur}fri={fri} sat={sat} image3={image3} image4={image4}
    pay1={pay1} pay1Value={pay1Value} pay2={pay2}pay2Value={pay2Value} pay3={pay3}pay3Value={pay3Value} note={note}
    color1={color1} color2={color2} colorT={colorT} snapChat={snapChat} business={business}/>

  }
    </div>
  )
}

