import React ,{useState,useRef}from 'react';


import Grid from '@material-ui/core/Grid';
import Halo_Pass_Template from '../HomePage/Home_WelcomeSection/Halo_Pass';


export default function HaloPassPreview(props){
    const {template}=props
    return(
        <Grid style={{justifyContent:'center',marginTop:'200px'}} container>
            <Grid sm={12} xs={12}></Grid>
            <Halo_Pass_Template template={template} />
        </Grid>
    )
}