import React,{useEffect,useRef,useState} from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './D6_halo_pricingcls.css'
import {useCookies} from 'react-cookie'
import Dialog from '@material-ui/core/Dialog';
import D6_FE_AccountBox from '../SignUPForm/D6_FE_AccountBox';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

export default function Pricing_Pass(props) {
    const{live,scroll1,available,template}=props
    const [value, setValue] = React.useState(0);
    const{country,countryF}=props
    const[auth,setAuth]=useCookies(['auth'])
    const[filter,setFilter]=React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [down, setDown] = React.useState(false);

    const myRef=useRef(null)

    const handleClose = () => {
        setOpen(false);
        setOpen1(false)
        setOpen2(false)
      };

    async function CountryFilter(){
        if(auth.auth){
        let filter1=await country.filter((count)=>
      
        (count.planCategory.toLowerCase().includes('halo pass'))
        // && (count.countryName.toLowerCase().includes(auth.auth.country.toLowerCase()))
      
      
      
      )
      if(filter1[0]){
      setFilter(filter1)}
      else{
      setFilter(country.filter((count)=>
      (count.planCategory.toLowerCase().includes('halo pass'))
    //   &&(count.countryName.toLowerCase().includes('usa'))
    ))
      }
        }
        else{
          setFilter(country.filter((count)=>
      (count.planCategory.toLowerCase().includes('halo pass')
      )
    //   &&(count.countryName.toLowerCase().includes('usa'))
      ))
        }
      }
      
      useEffect(() => {
        if(myRef&&scroll1){
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  
        
        }      
             
              }, [])
      
      useEffect(()=>{ 
        if(country[0]){
      
      
      if(countryF){
      setFilter(country.filter((count)=>
      (count.planCategory.toLowerCase().includes('halo pass'))&&
      
      (count.countryName.toLowerCase().includes(countryF))
      
      
      
      ))
      
      
      
      }
      if(!countryF&&country[0]){
      CountryFilter()
      
      // if(!filter[0]){
      
      
      
      
      
      // }
      
      }
      
        }
      
      else{
        setFilter([])
      }
            
        },[country,countryF,auth.auth])

        const handleChange = (event, newValue) => {
            setValue(newValue);
          };
          const updatePlan=(value)=>{
            if(auth.auth){  
              if(value=='prime')  
              {
                if(template&&template.passes&&template.passes>50){
                  setDown(true)
                }
                else{
                  props.category('halo pass')
                  props.updatePlan(value)
                  }
              }
              else{
              props.category('halo pass')
              props.updatePlan(value)
              }
        }
        else{
            setOpen(true)
        }
        }
        const updatePlan1=(val)=>{
          if(auth.auth){ 
            props.category('halo pass')
            props.updatePlan(val)
            props.currentPlan()
           }
          else{
            setOpen(true) 
          }
        }
const Downgrade=()=>{
  props.downgrade()
   props.category('halo pass')
              props.updatePlan('prime')
}
        const checkAvailability=(value)=>{
          var prof=available.length+auth.auth.profiles
          var cards=available.length+auth.auth.cards


if(prof&&cards>0){
  if(prof<=cards){

  props.category('halo pass')
  props.updatePlan(value)

  }
else{
  setOpen1(true)
}
}
else{
  setOpen1(true)
}

        }
    return (
        <>
            
            <div ref={myRef} className="D6_PricingCont_GridCls">
           

                    <Grid container item sm={12} xs={12} className="D6_PersCont_GridCls">
                    <Dialog
      disableScrollLock={ true }
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox live={live}handleClose={handleClose} />     
    </Dialog>
    <Dialog
      disableScrollLock={ true }
      open={open2}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
<Share live={live} handleClose={handleClose}/>    </Dialog>
<Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>All cards are linked with business profiles</strong>
       <br /> <strong>Please upgrade your plan to purchase business profile</strong>

      </Alert>
      </Snackbar>

      <Snackbar open={down}  anchorOrigin={{vertical:'top', horizontal:'center'}} >
     
     <Alert severity="info"
      action={
        <>
         <Button className='btn_A' onClick={()=>Downgrade()} color='success'   size="small">
           Select
         </Button>
         <Button  className='btn_A' onClick={()=>setDown(false)} color="success" size="small">
         Cancel
       </Button>
       </>
       }
     >
       Plan downgradation will result in deletion of previous created passes
     </Alert>
     </Snackbar>
                        <Grid  item sm={12} xs={12}>
                                <div className="D6_Pers_GridCls">
                                    HALO SMART PASS
                                </div>
                        </Grid>
      {template&&template.passes&&template.passes>50?                  <Grid  item sm={4} xs={12}>
  
  <Paper className="D6_Pers_GridCls1">

     <div className="D6_PricingPers_HeadCls">CURRENT PRIME ({filter[0]?filter[0].currencySymbol+template.amount:null}*)</div> 
     {/* <div className="D6_PricingPers_SubHeadCls">Free for 90 days with sign-up</div>  */}
      <ul>
          <li className="D6_PricingPers_TextDescCls">
          Digital passes for your business to manage subscriptions/memberships     </li>
          <li className="D6_PricingPers_TextDescCls">
          Create/manage upto 50 passes          </li>
          <li className="D6_PricingPers_TextDescCls">
          Choose among Scan & Go or Smart Pass
	
  </li>
          <li className="D6_PricingPers_TextDescCls">
          Enable/Disable the pass anytime          </li>
          <li className="D6_PricingPers_TextDescCls">
          Track & analyse the usage from dashboard                    </li>
          <div className="D6_PricingPers_TextDescPers">
          *Billed annually 
          </div>
          <div className="D6_PricingPers_TextDescPersPlt">
          &nbsp;
      </div>
      </ul>
      <div className="D6_PricingPers_SelectPersPlt">
      <Button  className="D6_PricingButtonPersCls" variant="contained" onClick={()=>updatePlan1('prime')}>Select</Button>
      </div>
  </Paper>
</Grid>:null}
                        <Grid  item sm={4} xs={12}>
  
                            <Paper className="D6_Pers_GridCls1">
   
                               <div className="D6_PricingPers_HeadCls">PRIME ({filter[0]?filter[0].currencySymbol+filter[0].amount:null}*)</div> 
                               {/* <div className="D6_PricingPers_SubHeadCls">Free for 90 days with sign-up</div>  */}
                               <ul>
          <li className="D6_PricingPers_TextDescCls">
          Digital passes for your business to manage subscriptions/memberships          </li>
          <li className="D6_PricingPers_TextDescCls">
          Create/manage upto 50 passes          </li>
          <li className="D6_PricingPers_TextDescCls">
          Choose among Scan & Go or Smart Pass
	
  </li>
          <li className="D6_PricingPers_TextDescCls">
          Enable/Disable the pass anytime          </li>
          <li className="D6_PricingPers_TextDescCls">
          Track & analyse the usage from dashboard                    </li>
          <div className="D6_PricingPers_TextDescPers">
          *Billed annually 
          </div>
          <div className="D6_PricingPers_TextDescPersPlt">
          &nbsp;
      </div>
      </ul>
                                <div className="D6_PricingPers_SelectPersPlt">
                                <Button  className="D6_PricingButtonPersCls" variant="contained" onClick={()=>updatePlan('prime')}>Select</Button>
                                </div>
                            </Paper>
                        </Grid>
                        {/* <Grid  item sm={4} xs={12}>
                            <Paper className="D6_Pers_GridCls1">
                            <div className="D6_PricingPers_HeadCls">INFINITY</div> 
                                <li className="D6_PricingPers_TextDescCls">
                                    Digital catalogue for your products/services
                                </li>
                                <li className="D6_PricingPers_TextDescCls">
                                    List/manage as many products/services as you need
                                </li>
                                <li className="D6_PricingPers_TextDescCls">
Enable/Disable your business profile or listing

                                </li>
                                <li className="D6_PricingPers_TextDescCls">
                                    Special customised pricing plans
                                </li>
                                <li className="D6_PricingPers_TextDescCls">
                                    Followers receive notifications for new updates     
                                                          </li>
                              
                                

                                <div className="D6_PricingPers_TextDescCls">
                                     &nbsp;
                                </div>
                                <div className="D6_PricingPers_TextDescCls">
                                     &nbsp;
                                </div>
                                <div className="D6_PricingPers_SelectPersPlt">
                                <Button onClick={()=>setOpen2(true)}  className="D6_PricingButtonPersCls" variant="contained">Email</Button>
                                </div>
                            </Paper>
                        </Grid> */}
                    </Grid>
            </div>
        </>
    )
}

function Share(props){
  const{live}=props
  const [email,setEmail]=useState('')
  const [contact,setContact]=useState('')
  const [requirements,setRequirements]=useState('')


  const [send,setSending]=useState(false)

const handleSubmit=()=>{
  setSending(true)
  const formData=new FormData()
  formData.append('email',email)
  formData.append('req',requirements)
  formData.append('contact',contact)
  formData.append('category','Custom Business Profile')


  {


    fetch(live+'share'
    ,
      {
  
        method:'PUT',
   
        body:formData
        })
        .then(res=> res.json())
        .then((jsonRes)=>SendRes(jsonRes))

        
      
        .catch(error=>console.log(error))
      
      }
      const SendRes=(resp)=>{
        if(resp.detail==='Mail Sent')
        {
          props.handleClose()
        }
      }

}
return(
  <div >
            <Grid container spacing={0} xs={12} >
            <ValidatorForm 
              //  useRef="form"
              style={{width:'330px'}}
                onSubmit={handleSubmit}
                className='validator-form'
               
            >
              <Grid  style={{margin:'20px'}}item xs={12}>
          
              <TextValidator
                      label="Your Email"

                      onChange={(e)=>setEmail(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='email'

                      value={email}     
                      validators={['required','isEmail']}
                      errorMessages={['Email is required']}
                  />
                   <TextValidator
                      label="Your Contact No."

                      onChange={(e)=>setContact(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='number'

                      value={contact}     
                      validators={['required']}
                      errorMessages={['Contact number is required']}
                  />
                   <TextValidator
                      label=" Your Message"

                      onChange={(e)=>setRequirements(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='text'

                      value={requirements}     
                      validators={['required']}
                      errorMessages={['Please provide description']}
                  />

         

                </Grid>
                <Grid  style={{margin:'20px'}}item xs={12}>
                  <Button type='submit' className="D6_Info_Button">
                  { !send?' Send':'sending'}
                  </Button>
                  </Grid>
                  </ValidatorForm>
              </Grid>

    </div>
)


}
