import React,{useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Cards from './cards';
import {useCookies} from 'react-cookie'
import MyProf from './profile';

import Logo from '../../assets/Images/HaloLogo.svg'
import { Grid, Paper } from '@material-ui/core';
import Payments from './payments';
import AddressBook from './addressBook';
import Admin from './admin';
import Businee from './business';
import Halo_Pass from './pass';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,

    }),
    marginTop:'92px',
    zIndex:90,
    backgroundColor:'#61126b'
  
  },
  
  appBarShift: {

    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop:'80px'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft(props) {
  const{live,countryF}=props
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const[auth]=useCookies(['auth'])
const[load,setLoad]=useState(null)
  const [screen,setScreen]=React.useState('Dashboard');
  const[cards,setCards]=useState([])
  const [business,setBusiness]=useState([])
  const [Pmember,setPMember]=useState([])
  const[template,setTemplate]=useState('')

  useEffect(() => {
 fetch(live+`cards?q=${auth.auth.memberID}`,
//  fetch(`http://127.0.0.1:8000/cards?q=${auth.auth.memberID}`,
 { method: 'GET',
 headers:{
   'Content-Type' :'application/json'  
 },})
 .then(res => res.json())
 .then(resp=>setCards(resp))
  }, [load])
  useEffect(() => {
 if(!auth.auth){
   window.location.href='/signin'
 }
  }, [auth.auth])

  useEffect(() => {
    fetch(live+`businessList?q=${auth.auth.memberID}`,
         { method: 'GET',
         headers:{
           'Content-Type' :'application/json'  
         },})
         .then(res => res.json())
         .then(resp=>setBusiness(resp))
      }
  , [])
  

  useEffect(() => {
    if(auth.auth){
    fetch(live+`memberRef?q=${auth.auth.memberID}`,
   //  fetch(`http://127.0.0.1:8000/cards?q=${auth.auth.memberID}`,
    { method: 'GET',
    headers:{
      'Content-Type' :'application/json'  
    },})
    .then(res => res.json())
    .then(resp=>setPMember(resp))
    
  }}, [auth.auth])


  useEffect(() => {
     
    if(auth.auth&&auth.auth.passSubscription){
      fetch(live+`passTemplate?q=${auth.auth.memberID}`,
       { method: 'GET',
       headers:{
         'Content-Type' :'application/json'  
       },})
       .then(res => res.json())
       .then(resp=>Template(resp))
    }
     }, [auth.auth])

     const Template=(res)=>{
       if(res.data){
setTemplate(res.data)
       }
       else{
         setTemplate('')
       }
     }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const Load=()=>{
    if(!load){
      setLoad(auth.auth.cards+1)

    }
    else{
      setLoad(load+1)

    }
  }
const Screen=(text)=>{
  setScreen(text)
  handleDrawerClose()

}
if(auth.auth)
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
            <Toolbar>
            <div style={{display:'flex',flexDirection:'row'}}>
              <div style={{marginBottom:'5px'}}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon  />
              </IconButton>
              </div>
              <div style={{marginTop:'10px'}}>
              <Typography variant="h6" noWrap>
                {screen}
              </Typography>
              </div>
              </div>
        </Toolbar>
      </AppBar>
      <Drawer

        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Grid container xs='12' >
            <Grid item xs='12'>
            <img src={Logo} style={{maxHeight:'90px',maxWidth:'90px',alignItems:'center', marginRight:'auto',}} />
              </Grid>
              <Grid item xs='8'>
              <h1 style={{marginTop:'10px',marginRight:'auto'}}>Hi {auth.auth.fname}</h1>
              </Grid>

            
          <Grid item xs='4'>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>

              </Grid>

            </Grid>
       
         
        </div>
        <Divider style={{marginTop:'16px',backgroundColor:'#61126b'}}/>
        <List >
          {(auth.auth&&auth.auth.admin?['Admin','Dashboard','Cards','Profile','Business','Halo Pass','Payments','Connections']:auth.auth&&!auth.auth.admin&&auth.auth.passSubscription?[ 'Dashboard','Cards','Profile','Business','Halo Pass','Payments','Connections']:[ 'Dashboard','Cards','Profile','Business','Payments','Connections']).map((text, index) => (
            <ListItem button key={text} onClick={()=>Screen(text)} >
              <ListItemText primary={text} />
            </ListItem> 
          ))}
        </List>
      
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {screen==='Cards' ?
       
       <Cards live={live} countryF={countryF} Load={Load} cards={cards}/>
        :
       screen==='Profile'?
            <MyProf points={Pmember[0]} template={template} live={live} business={business} cards={cards}/>
      //  :screen==='Connections'?
      //   <Typography paragraph>
      //     Connections - coming soon
      //   </Typography>
        :screen==='Payments'?
<Payments  live={live}/>
    :screen==='Connections'?
    <AddressBook  live={live}/>
    :screen==='Admin'?
    <Admin  live={live}/>
    :screen==='Business'?
    <Businee  live={live}/>
    :screen==='Halo Pass'?
    <Halo_Pass  live={live}/>
    :screen==='Dashboard'?
    <div>
      <Grid style={{textAlign: '-webkit-center',marginTop:'40px'}} container>
      <Grid item    sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Cards')} className='dash_sub'>
        Cards
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Profile')}  className='dash_sub'>
        Profile
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Payments')}  className='dash_sub'>
        Payments
        </Paper>
        </Grid>
        
        <Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Connections')}  className='dash_sub'>
        Connections
        </Paper>
        </Grid>
     {auth.auth&&auth.auth.admin?<Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Admin')}  className='dash_sub'>
        Admin
        </Paper>
        </Grid>:null}
        <Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Business')}  className='dash_sub'>
        Business
        </Paper>
        </Grid>
        {auth.auth&&auth.auth.passSubscription?<Grid item   sm={3} xs={6}>
    <Paper onClick={()=>setScreen('Halo Pass')}  className='dash_sub'>
        Halo Pass
        </Paper>
        </Grid>:null}
        </Grid>
    </div>
      :null}
   

      </main>
    </div>

  );
  else{
    window.location.href='/signin'

  }
}