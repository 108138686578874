import React ,{useState,useEffect}from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './D6_halo_pltpreviewForm.css';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import CreditCard from '@material-ui/icons/CreditCard';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TelegramIcon from '@material-ui/icons/Telegram';
import GoogleMeetIcon from '@material-ui/icons/Duo';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Image from '../../assets/Images/cover1.jpg'

import D6_halo_cardpltweb from './PltCard/D6_halo_cardpltweb';
import D6_halo_cardclsweb from './ClsCard/D6_halo_cardclsweb';
import D6_halo_cardsigweb from './SigCard/D6_halo_cardsigweb';
import BillInfo from './D6_halo_billinfo';
import {useCookies} from 'react-cookie'
import HaloDWNLD from '../Downloads/D6_halo_dwnld';
import { PhotoshopPicker  } from 'react-color';
import Switch from '@material-ui/core/Switch';

import Dialog from '@material-ui/core/Dialog';
import D6_FE_AccountBox from '../SignUPForm/D6_FE_AccountBox';
import Chip from '@material-ui/core/Chip';
import { sha256 } from 'js-sha256';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Site from '../customer_site/site'
import Site_Preview from '../customer_site/site_preview';
import StorefrontIcon from '@material-ui/icons/Storefront';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PreviewPltFormPlt(props) {

  const{live,countryF}=props
  const [cardT,setCardT]=useState(null)
  const [category,setCategory]=useState(props.identity?props.category1:props.category)

  const card=cardT?cardT:props.card

  const [open1, setOpen1] = React.useState(false);
  const [opens, setOpens] = React.useState(false);

  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [openT, setOpenT] = React.useState(false);

const [checkedW,setCheckedW]=useState(false)




  
    const handleClose = () => {
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
      setOpen4(false)
      setOpen5(false)
      setOpen6(false)
      setError1(null)
      setOpens(false)
      setOpenT(false)





    };

    const [open2, setOpen2] = React.useState(false);
    
    

  // const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [Facebook,setFacebook]=useState(props.facebook?props.facebook:'')
  const [Instagram,setInstagram]=useState(props.instagram?props.instagram:'')
  const [Whatsapp,setWhatsapp]=useState(props.whatsapp?props.whatsapp:'')
  const [LinkedIn,setLinkedIn]=useState(props.linkedIn?props.linkedIn:'')
  const [Twitter,setTwitter]=useState(props.twitter?props.twitter:'')
  const [GitHub,setGitHub]=useState(props.gitHub?props.gitHub:'')
  const [YouTube,setYouTube]=useState(props.youTube?props.youTube:'')
  const [Telegram,setTelegram]=useState(props.telegram?props.telegram:'')
  const [GoogleMeet,setGoogleMeet]=useState(props.googleMeet?props.googleMeet:'')
  const [snapChat,setsnapChat]=useState(props.snapChat?props.snapChat:'')

  const [pay1,setPay1]=useState(props.pay1?props.pay1:'')
  const [pay1Value,setPay1Value]=useState(props.pay1Value?props.pay1Value:'')

  const [pay2,setPay2]=useState(props.pay2?props.pay2:'')
  const [pay2Value,setPay2Value]=useState(props.pay2Value?props.pay1Value:'')
  const [pay3,setPay3]=useState(props.pay3?props.pay3:'')
  const [pay3Value,setPay3Value]=useState(props.pay3Value?props.pay3Value:'')
  const [note,setNote]=useState(props.note?props.note:'')
  const[username,setUsername]=useState(props.username?props.username:'')

const [active,setActive]=useState('Facebook')


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [name,setName]=useState(props.fname?props.fname:'')
  const [lname,setLName]=useState(props.lname?props.lname:'')

  const[email,setEmail]=useState(props.email?props.email:'')
  const[designation,setDesignation]=useState(props.designation?props.designation:'')
const[organization,setOrganization]=useState(props.organization?props.organization:'')
const[web,setWeb]=useState(props.web?props.web:'')
const[mobile,setMobile]=useState(props.mobile?props.mobile:'')
const[mobile1,setMobile1]=useState(props.mobile1?props.mobile1:'')

const[image,setImage]=useState(null)
const[image1,setImage1]=useState(null)
const [imageQr1,setImageQr1]=useState(null)
const [imageQr2,setImageQr2]=useState(null)
const [imageQr3,setImageQr3]=useState(null)



const[location,setLocation]=useState(props.location?props.location:'')
const[auth,setAuth]=useCookies(['auth'])

const[billing]=useState(null)
const [invoice,setInvoice]=useState(null)
const[cardRes,setCardRes]=useState('')
const[paymentRes,setPaymentRes]=useState('')
const [hour,setHour]=useState('Mon')
const [mon,setMon]=useState(props.mon?props.mon:'')
const [tue,setTue]=useState(props.tue?props.tue:'')
const [wed,setWed]=useState(props.wed?props.wed:'')
const [thur,setThur]=useState(props.thur?props.thur:'')
const [fri,setFri]=useState(props.fri?props.fri:'')
const [sat,setSat]=useState(props.sat?props.sat:'')
const [sun,setSun]=useState(props.sun?props.sun:'')
const [colorT,setColorT]=useState(props.colorT?props.colorT:'')
const [color1,setColor1]=useState(props.color1?props.color1:'')
const [colorS,setColorS]=useState(props.color2?props.color2:'')


const [headline,setHeadline]=useState(props.headline?props.headline:'')
const [processing,setProcessing]=useState(null)

const [label,setLabel]=useState('First Name')
const [payment,setPayment]=useState(false)
const [Note,setNotes]=useState(false)
const [template,setTemplate]=useState(false)
const [template1,setTemplate1]=useState(false)

const [error,setError]=useState(null)
const [error1,setError1]=useState(null)
const [shared,setShared]=useState(props.shared?props.shared:'')
const [master,setMaster]=useState(props.master?props.master:'')
const [card1,setCard1]=useState(null)

const [checkedB,setCheckedB]=useState(props.private=='1'?true:false)
var mediumRegex = new RegExp("^(((?=.*[a-z]))).{3,30}$");
const [companyData,setCompanyData]=useState('')
// console.log(imageQr2)

const CardT=(val)=>{
  if(card1){
    setCardT(val)
  }
  else{
    setCard1(card)
    setCardT(val)
  }


}


const checkUsername=()=>{
  setProcessing(true)
  const formData=new FormData()
  formData.append('username',username)
  formData.append('dcard_url',props.dcard_url?props.dcard_url:'')
  fetch(live+'username'
  // fetch('http://127.0.0.1:8000/create'
  ,
  {
  
    method:'POST',
  
    body:formData
    })
  
    .then(res=>res.json())
    .then((resp)=>UserRes(resp))
  
  
    .catch(error=>console.log(error))
}
const UserRes=(res)=>{
  if(res.detail==='Success'){
    // alert('Success')
    Billing()
    // setProcessing(false)

  }
  else{
    setError('Username already exists')
    setOpen6(true)
    setProcessing(false)
  }
}

const Billing=()=>{if(auth.auth){
  if(props.identity||auth.auth.planType==='infinity'||auth.auth.cards>0){

    if(props.identity||image){

      if((category==='platinum'&&card==='3'&&!image1&&!template&&!props.identity)||(category==='platinum'&&card==='4'&&!image1&&!template&&!props.identity)||(category==='platinum'&&card==='5'&&!image1&&!template&&!props.identity)||(category==='platinum'&&card==='6'&&!image1&&!template&&!props.identity))
      {
        setProcessing(null)
        setError1('Please select cover image ')
      }
      if((category==='platinum'&&card==='3'&&image1&&!template)||(category==='platinum'&&card==='4'&&image1&&!template)||(category==='platinum'&&card==='5'&&image1&&!template)||(category==='platinum'&&card==='6'&&image1&&!template)||(category==='signature')||(category==='classic')||(category==='platinum'&&card==='1')||(category==='platinum'&&card==='2'))
      {

        setProcessing(true)
        stripeResponse()
      }
      if(props.identity&&!template){
      {

        setProcessing(true)
        stripeResponse()
      }
    }
      if(template){

        if(cardT==='3'||cardT==='4'||cardT==='5'||cardT==='6'){

      if(card1==='1'||card1==='2'){

  if(!image1){
  setError1('Please select cover image')
  setProcessing(false)

  }
  else{

    setProcessing(true)
    stripeResponse()
  }
        }
        else{

          setProcessing(true)

          stripeResponse()
        }
      }
    else{

      setProcessing(true)

      stripeResponse()
    }}
    if(template1){

      setProcessing(true)

          stripeResponse()
    }
      
      }
    

        else{
          setError1('Please select logo/profile picture')
    
        }
  }
  else{
setOpen4(true)  }
 

}

else{
  setOpen3(true)
}
}
const Payment =(value)=>{
  setActive(false)
  setNotes(false)
  setPayment(value)

}
const Notes=()=>{
  setPayment(false)
  setActive(false)
  setNotes(true)
}
const Active=(value)=>{
  setActive(value)
  setPayment(false)
  setNotes(false)
}

const stripeResponse=(value)=>{
  if(checkedB&&!master&&!shared){
    setProcessing(null)
    setError('Both master & shared pin is required')
    setOpen6(true)
  }
else{
  const formData=new FormData()
  formData.append('firstName',name)
  formData.append('middleName',name)
  formData.append('lastName',lname)
  formData.append('organization',organization)
  formData.append('workPhone',mobile)
  formData.append('homePhone',mobile1)

  formData.append('title',designation)
  formData.append('email',email)
  formData.append('workEmail',email)
  formData.append('street',auth.auth.addLine1)
  formData.append('city',auth.auth.city)
  formData.append('postalCode','1234')
  formData.append('countryRegion',auth.auth.country)
  formData.append('image',image)

  formData.append('image1',image1?image1:props.image4?null:image)
  formData.append('web',web)


  formData.append('memberid',auth.auth.memberID)
  formData.append('card',card)
  formData.append('category',category)
  formData.append('plan',auth.auth.planType)
  formData.append('facebook',Facebook)
  formData.append('instagram',Instagram)
  formData.append('whatsapp',Whatsapp)
  formData.append('linkedIn',LinkedIn)
  formData.append('twitter',Twitter)
  formData.append('gitHub',GitHub)
  formData.append('youTube',YouTube)
  formData.append('telegram',Telegram)
  formData.append('googleMeet',GoogleMeet)
  formData.append('googleMeet',GoogleMeet)
  formData.append('snapChat',snapChat)

  formData.append('color1',color1)
  formData.append('color2',colorS)
  formData.append('colorT',colorT)

  formData.append('mon',mon)
  formData.append('tue',tue)
  formData.append('wed',wed)
  formData.append('thur',thur)
  formData.append('fri',fri)
  formData.append('sat',sat)
  formData.append('sun',sun)
  formData.append('location',location)
  formData.append('headline',headline)
  formData.append('dcardID',props.dcardID)
  formData.append('pay1',pay1)
  formData.append('pay1Value',imageQr1?imageQr1:'')
  formData.append('pay2',pay2)
  formData.append('pay2Value',imageQr2?imageQr2:'')
  formData.append('pay3',pay3)
  formData.append('pay3Value',imageQr3?imageQr3:'')
  formData.append('note',note)
  formData.append('static',image1||props.image4?'1':'0')
  formData.append('private',checkedB?'1':'0')
  formData.append('pass1',master)
  formData.append('pass2',shared)
  formData.append('username',username?username:'')


  






{!props.identity?
  
fetch(live+'create'
// fetch('http://127.0.0.1:8000/create'
,
{

  method:'POST',

  body:formData
  })

  .then(res=>res.json())
  .then((resp)=>CardRes(resp))


  .catch(error=>console.log(error)):

   fetch(live+'update'
  //  fetch('http://127.0.0.1:8000/update'
  ,
  {
  
    method:'PUT',
  
    body:formData
    })
  
    .then(res=>res.json())
    .then((resp)=>CardRes(resp))
  
  
    .catch(error=>console.log(error))}
  
  const  CardRes=(resp)=>{
    if(resp.data){
      setProcessing(null)
      setCardRes(resp.data.card)

      setInvoice(true)
      setAuth('auth',resp.data.member,{
        maxAge:3600 ,
        expires:0
     })
      setOpen5(true)

      if(value){
        setPaymentRes(value)
      }
    }
else{setProcessing(null)
alert('error in payment')}
  }}
}
const ImageProfile=(img)=>{
  if(img&&img.name)

  if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  
  {
    if(img.size/1048576<4){
setImage(img)   
  }
   else{
       setError('File should be <4MB')
       setOpen6(true)
     }
   } 
   else{
     setError('Upload jpg|jpeg|png format image')
     setOpen6(true)

   }
}
const ImageCover=(img)=>{
  if(img&&img.name)

    if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  {
    
    if(img.size/1048576 <4){
setImage1(img)   
  }
     else{
       setError('File should be <4MB')
       setOpen6(true)
     }
   } 
   else{
     setError('Upload jpg|jpeg|png format image')
     setOpen6(true)

   }
}
const ImageQR1=(img)=>{
  if(pay1){
  if(img&&img.name)

  if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  
  {
    if(img.size/1048576<4){
setImageQr1(img)   
  }
   else{
       setError('File should be <4MB')
       setOpen6(true)
     }
   } 
   else{
     setError('Upload jpg|jpeg|png format image')
     setOpen6(true)

   }}
   else{
    setError('Enter Pay Option for image')
    setOpen6(true)
   }
}
const ImageQR2=(img)=>{
 
 if(pay2){
  if(img&&img.name)

  if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  
  {
    if(img.size/1048576<4){
setImageQr2(img)   
  }
   else{
       setError('File should be <4MB')
       setOpen6(true)
     }
   } 
   else{
     setError('Upload jpg|jpeg|png format image')
     setOpen6(true)

   }}
   else{
    setError('Enter Pay Option for image')
    setOpen6(true)
   }
}
const ImageQR3=(img)=>{
  if(pay3){
    // console.log(pay3)
  if(img&&img.name){

  if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  
  {
    if(img.size/1048576<4){
setImageQr3(img)   
  }
   else{
       setError('File should be <4MB')
       setOpen6(true)
     }
   } 
   else{
     setError('Upload jpg|jpeg|png format image')
     setOpen6(true)
   }
   }
  }
   else{
    setError('Enter Pay Option for image')
    setOpen6(true)
   }
}





const  valueChange=(value)=>{
  if(label=='Mobile1'||label=='Mobile2'){
    if(value.length>18){
      Error1()
      
    }
    else{
      if(label==='Mobile1'){

        setMobile(value)
        setError(null)
        setOpen6(false)
      }
    else{
      setMobile1(value)
      setError(null)
      setOpen6(false)
    }
    }

  }
  if(label=='Username'){
    if(value){
    if(!mediumRegex.test(value)){
      setError('Username should be between 3 and 30 characters')
      setOpen6(true)
      setUsername(value)
    }
    else if((value.length>2&&value.length<31)&&mediumRegex.test(value)){
      setError(null)
      setOpen6(false) 
      setUsername(value)
    }
  }
    else{
      setError(null)
      setOpen6(false) 
      setUsername('')
    }
    // setError('Username should contain [a-z] [A-Z] [0-9] [6,12]')
    //   setOpen6(true)
    // setUsername(value)

  }
  else if(label!=='Mobile1'&&label!=='Mobile2'&&label!=='Username'){
  
  if (value.length<76){
  (label==='First Name'?setName(value):
  (label==='Last Name')?setLName(value)
  :(label==='E-mail'?setEmail(value)
  :(label==='Designation'?setDesignation(value)
  :(label==='Organization'?setOrganization(value)
  :(label==='Web'?setWeb(value)
  :(label==='Mobile1'?setMobile(value)
  :(label==='Mobile2'?setMobile1(value)
  :(label==='Headline'?setHeadline(value)

  :setLocation(value)
  ))))))))
  setError(null)
  setOpen6(false)

}
  else{
    setError('Input value cannot be more than 75 characters')
      setOpen6(true)

  }

  }
}
const Error1=()=>{
  setError('Invalid phone number')
      setOpen6(true)
}
const checkedT=()=>{
  setCheckedB(!checkedB)
}
const checkedWeb=()=>{
  setCheckedW(!checkedW)
}
const Shared=(val)=>{
  setShared(val)
}
const Master=(val)=>{
  setMaster(val)

}
const Template=(val)=>{
  setCategory(val)
  setTemplate(true)
  setTemplate1(false)


}
const Template1=(val)=>{
  setCategory(val)
  setTemplate1(true)
  setTemplate(false)

}
useEffect(() => {
if(props.dcard_url){
  const formData=new FormData()
  formData.append('siteID',props.dcard_url)
  

  fetch(live+'site'
,
{

  method:'PUT',

  body:formData
  })

  .then(res=>res.json())
  .then((resp)=>SiteRes(resp))


  .catch(error=>console.log(error))

}  
},[])
const SiteRes=(val)=>{
  if(val.data)
{
setCompanyData(val.data)
}

}

  return (
    <div className="D6_Card_PreviewPltFormCont_Grid">
       <AppBar position="static" className="D6_PreviewPltAppBar">
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Card Preview" {...a11yProps(0)} />
          {/* <Tab label="Web View" {...a11yProps(1)} /> */}
        </Tabs>
      </AppBar>
      <Snackbar open={open4}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Alert!!</AlertTitle>
        You have consumed your available cards, <strong>please recharge or upgrade your plan!</strong>
      </Alert>
      </Snackbar>
      <Snackbar open={error1}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Alert!!</AlertTitle>
         <strong>{error1}</strong>
      </Alert>
      </Snackbar>
      <Snackbar open={open5}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
      <AlertTitle>Success!!</AlertTitle>

  <strong>{props.identity?'Card updated':'Card created successfully'} </strong>
      </Alert>
      </Snackbar>
      <Snackbar open={open6}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        <AlertTitle>Error!!</AlertTitle>
        {error}
      </Alert>
      </Snackbar>
      
      <Dialog
      open={open1}
      disableScrollLock={ true }

      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker onAccept={()=>setOpen1(false)} onCancel={()=>setOpen1(false)&&setColor1(null)} color={color1} onChange={(color,event)=>setColor1(color.hex)} />     
    </Dialog>
    <Dialog
      open={openT}
      disableScrollLock={ true }

      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker onAccept={()=>setOpenT(false)} onCancel={()=>setOpenT(false)&&setColorT(null)} color={colorT} onChange={(color,event)=>setColorT(color.hex)} />     
    </Dialog>

    {/* <Dialog
      open={open2}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker color={color} onChange={(color,event)=>setColor(color.hex)} />     
    </Dialog> */}
    <Dialog
      open={open3}
      onClose={handleClose}
      disableScrollLock={ true }

      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox live={live} handleClose={handleClose} />     
    </Dialog>
      <Grid container spacing={0} sm={12} xs={12} >
      <Dialog
      open={opens}
      disableScrollLock={ true }

      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker onAccept={()=>setOpens(false)} onCancel={()=>setOpens(false)&&setColorS(null)} color={colorS} onChange={(color,event)=>setColorS(color.hex)} />     
    </Dialog>
            <Grid container spacing={0} className="D6_Card_PreviewPltCont_Grid" direction='row' item sm={4} xs={12} >
            <TabPanel value={value} index={0}>
            <Grid direction='row' item sm={12} xs={12}>
            {
                category==='classic'?
                <D6_halo_cardclsweb card={card} name={name||lname?name+' '+lname:null} email={email} designation={designation}
                organization={organization} web={web} mobile={mobile} mobile1={mobile1} image={image} facebook={Facebook} instagram={Instagram}
                whatsapp={Whatsapp} linkedIn={LinkedIn}twitter={Twitter}gitHub={GitHub}youTube={YouTube} image1={!image?props.image1:null} image4={props.image4}
                telegram={Telegram} googleMeet={GoogleMeet} username={username} />:
                auth.auth.planCategory==='signature'&&category==='signature'||auth.auth.planCategory==='platinum'&&category==='signature'?
                <D6_halo_cardsigweb card={card}name={name||lname?name+' '+lname:null} email={email} designation={designation} location={location}
                organization={organization} web={web} mobile={mobile} mobile1={mobile1} image={image}facebook={Facebook} instagram={Instagram}
                whatsapp={Whatsapp} color1={color1}  linkedIn={LinkedIn}twitter={Twitter}gitHub={GitHub}youTube={YouTube} image1={!image?props.image1:null} image4={props.image4}
                telegram={Telegram} googleMeet={GoogleMeet} headline={headline} sun={sun} mon={mon}tue={tue}wed={wed}thur={thur}fri={fri} sat={sat}  username={username}/>
                :!props.identity&&auth.auth.planCategory==='platinum'&&category==='platinum'?

                <D6_halo_cardpltweb  card={cardT?cardT:card} name={name||lname?name+' '+lname:null} email={email} designation={designation} 
                organization={organization} web={web} mobile={mobile} mobile1={mobile1} image={image} image3={image1}
                location={location}
                facebook={Facebook} instagram={Instagram} headline={headline} image1={!image?props.image1:null} image4={props.image4}
                whatsapp={Whatsapp} linkedIn={LinkedIn}twitter={Twitter}gitHub={GitHub}youTube={YouTube}
                telegram={Telegram} color1={color1} googleMeet={GoogleMeet} sun={sun} mon={mon}tue={tue}wed={wed}thur={thur}fri={fri} sat={sat}
                pay1={pay1} pay1Value={pay1Value} pay2={pay2}pay2Value={pay2Value} pay3={pay3}pay3Value={pay3Value} note={note} stat={props.stat}
                color2={colorS} colorT={colorT} snapChat={snapChat} username={username} pay1ValueN={props.pay1ValueN} pay2ValueN={props.pay2ValueN}  pay3ValueN={props.pay3ValueN}
                imageQr1={imageQr1} imageQr2={imageQr2} imageQr3={imageQr3} />
                :props.identity&&category==='platinum'?

                <D6_halo_cardpltweb  card={cardT?cardT:card} name={name||lname?name+' '+lname:null} email={email} designation={designation} 
                organization={organization} web={web} mobile={mobile} mobile1={mobile1} image={image} image3={image1}
                location={location} snapChat={snapChat} pay1ValueN={props.pay1ValueN} pay2ValueN={props.pay2ValueN}  pay3ValueN={props.pay3ValueN} imageQr1={imageQr1} imageQr2={imageQr2} imageQr3={imageQr3}
                facebook={Facebook} instagram={Instagram} headline={headline} image1={!image?props.image1:null} image4={props.image4}
                whatsapp={Whatsapp} linkedIn={LinkedIn}twitter={Twitter}gitHub={GitHub}youTube={YouTube}
                telegram={Telegram} color1={color1} googleMeet={GoogleMeet} sun={sun} mon={mon}tue={tue}wed={wed}thur={thur}fri={fri} sat={sat}
                pay1={pay1} pay1Value={pay1Value} pay2={pay2}pay2Value={pay2Value} pay3={pay3}pay3Value={pay3Value} note={note} stat={props.stat} color2={colorS} colorT={colorT} username={username}/>
               :<>
               </> }
              
                </Grid>
      </TabPanel>
      {/* <TabPanel value={value} index={1}> </TabPanel> */}
      <Grid container spacing={1} sm={12} xs={12} >
        {!props.identity?
          <Grid item xs={6}>
            <Button className="D6_PreviewPltButtonBack" onClick={()=>{props.Change()}} variant="contained">Change Template</Button> 
          </Grid>: <Grid item xs={6}>
            <Button className="D6_PreviewPltButtonBack" onClick={()=>{props.Back()}} variant="contained">Back</Button> 
          </Grid>}
          <Grid item xs={6}>
            <Button className="D6_PreviewPltButtonNext" variant="contained" onClick={()=>{!username?Billing(true):checkUsername()}}>{!processing&&!props.identity?'Proceed':processing?'Processing..':'Save'} </Button> 
          </Grid>
        </Grid>
            </Grid>
            <Grid container direction='row' item sm={8} xs={12}  >
              {!billing&&!invoice?<>
                <Grid direction='column' item sm={12} xs={12}>
                      <Paper className="D6_Card_PreviewPltForm_Grid">
                      <div className="D6_CARD_BillInfo_head">
                      Please fill in your details
                    </div>
                      <div className="D6_Card_Req_Form" >
                        <Grid container spacing={0} alignItems="center">
                         {/* <Grid container className="D6_Card_Opt_Fields"> */}
                         <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label="First Name"  onClick={()=>setLabel('First Name')}/>
                          </Grid>
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label="Last Name" onClick={()=>setLabel('Last Name')} />
                          </Grid>
                          {category!=='classic'&&card!==1?
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Headline" onClick={()=>setLabel('Headline')} />
                          </Grid> :null}
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Designation"  onClick={()=>setLabel('Designation')}/>
                          </Grid>
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Organization"   onClick={()=>setLabel('Organization')}/>
                          </Grid>
                          {/* </Grid> */}
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="E-mail" onClick={()=>setLabel('E-mail')} />
                          </Grid>
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Website" onClick={()=>setLabel('Web')} />
                          </Grid>
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Mobile Phone"  onClick={()=>setLabel('Mobile1')}/>
                          </Grid>
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Work Phone"  onClick={()=>setLabel('Mobile2')}/>
                          </Grid>

                            {category!=='classic'?
                            <>
                            {card!=='1'?
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Location" onClick={()=>setLabel('Location')} />
                          </Grid>:null}
                        </>
                          :null}
                         {category==='platinum'&&(card==='2'||card==='6')?
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                             <Chip  label="Card color 1" color="secondary"  onClick={()=>setOpen1(true)} />
                          </Grid>
                        :null}
                                               {category==='platinum'&&card==='6'?
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                             <Chip  label="Card color 2" color="secondary"  onClick={()=>setOpens(true)} />
                          </Grid>
                        :null}
                         {category==='platinum'&&card==='6'?
                          <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                             <Chip  label="Text color" color="secondary"  onClick={()=>setOpenT(true)} />
                          </Grid>
                        :null}
                        <Grid item sm={3} xs={4} className="D6_Card_Opt_Fields">
                             <Chip  label="Username" color="secondary"  onClick={()=>setLabel('Username')} />
                          </Grid>
 
                           <Grid item xs={12}>
                        <TextField className="D6_Card_Opt_FieldsText"  autoFocus size="small" 
                        value={(label==='First Name'?name
                        :
                        (label==='Last Name'?lname
                        :(label==='E-mail'?email
                        :(label==='Web'?web
                        :(label==='Mobile1'?mobile
                        :(label==='Mobile2'?mobile1
                        :(label==='Organization'?organization
                        :(label==='Designation'?designation
                        :(label==='Headline'?headline
                        :(label==='Username'?username
                        :location
                         ))) )))))))}  label={(label==='First Name'?'First Name'
                         :
                         (label==='Last Name'?'Last Name'
                         :(label==='E-mail'?'E-mail'
                         :(label==='Web'?'Web'
                         :(label==='Mobile1'?'Mobile1'
                         :(label==='Mobile2'?'Mobile2'
                         :(label==='Organization'?'Organization'
                         :(label==='Designation'?'Designation'
                         :(label==='Headline'?'Headline'
                         :(label==='Username'?'Username'
                         :'Location'
             
                          )) ))))))))} onChange={(e)=>{
                         valueChange(e.target.value)
                        
                        }} variant="outlined"></TextField>
                        </Grid>
                        <Grid container spacing={1} item xs={12} >
                          {category==='platinum'&& (card==='3'||card==='4'||card==='5'||card==='6')?
                          <Grid style={{marginTop:'5px'}} item sm={4} xs={12}  className="D6_Card_Opt_Fields">
                               <div style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#f51464',borderRadius:'30px',height:'40%',paddingTop:'5px'}}>
                          <label  for="files1" style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >Choose Cover Image</label>
                          </div>

                          <input type="file"  id="files1" style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageCover(e.target.files[0])}/><br />
                      
                          </Grid>
                          :null}
                          {/* <label for="files" class="btn">Select Image</label>
                          <input  type="file" onChange={(e)=>setImage1(e.target.files[0])}/><br /> */}
                          <Grid  style={{marginTop:'5px'}} item sm={4} xs={12}  className="D6_Card_Opt_Fields">
                            <div style={{ display:'flex',justifyContent:'space-around',backgroundColor: '#f51464',borderRadius:'30px',height:'40%',paddingTop:'5px'}}>
                          <label for="files" style={{fontSize:'small',fontWeight:'bold' ,color: 'white',cursor:'pointer'}} >Choose Pic/Logo</label>
                          </div>

                          <input type="file"  id="files" style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageProfile(e.target.files[0])}/><br />
                          </Grid>
                          
                       {category==='platinum'&& (card==='3'||card==='4'||card==='5'||card==='6')?
                       <Grid container item xs={12} className="D6_Card_PrevPlt_BH_Grid">
                         <Grid container item xs={10} >
                     
                        <Grid style={{cursor:'pointer'}}onClick={()=>setHour('Mon')} item xs={2}>
                          M
                        </Grid>
                        <Grid style={{cursor:'pointer'}} onClick={()=>setHour('Tue')}item xs={2}>
                          T
                        </Grid>
                        <Grid style={{cursor:'pointer'}} onClick={()=>setHour('Wed')} item xs={2}>
                          W
                        </Grid>
                        <Grid style={{cursor:'pointer'}} onClick={()=>setHour('Thur')}item xs={2}>
                          T
                        </Grid>
                        <Grid style={{cursor:'pointer'}} onClick={()=>setHour('Fri')} item xs={2}>
                          F
                        </Grid>
                        <Grid style={{cursor:'pointer'}} onClick={()=>setHour('Sat')}item xs={2}>
                          S
                        </Grid>
                        </Grid>
                       
                        <Grid style={{cursor:'pointer'}} onClick={()=>setHour('Sun')} item xs={2}>
                          S
                        </Grid>
                        <Grid item xs={12} style={{marginTop:'8px'}}>
                        <TextField className="D6_Card_Opt_FieldsText" id="OptFields" size="small" label="Business Hours"
                        value={(hour==='Sun'?sun:
                        (hour==='Mon')?mon
                        :(hour==='Tue'?tue
                        :(hour==='Wed'?wed
                        :(hour==='Thur'?thur
                        :(hour==='Fri'?fri
                        :sat
            
                        )))))}  label={hour+'  working hours'} onChange={(e)=>{
                          (hour==='Mon'?setMon(e.target.value):
                          (hour==='Tue')?setTue(e.target.value)
                          :(hour==='Wed'?setWed(e.target.value)
                          :(hour==='Thur'?setThur(e.target.value)
                          :(hour==='Fri'?setFri(e.target.value)
                          :(hour==='Sat'?setSat(e.target.value)
                
                          :setSun(e.target.value)
                          )))))
                        
                        }} variant="outlined"></TextField>
                        </Grid>
                </Grid>:null}
                        </Grid>
                          {/* <div className="D6_Card_Opt_Form"> */}
                      <Grid container style={{marginTop:'5px'}} direction='row' item xs={12}  >
                         <Grid   onClick={()=>Active('Facebook')} item xs={2}>
                            <FacebookIcon   className="D6_Card_Opt_Fields"/>                        
                          </Grid>
                          <Grid item  onClick={()=>Active('Whatsapp')} xs={2}>
                            <WhatsAppIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid onClick={()=>Active('LinkedIn')}item xs={2}>
                            <LinkedInIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid item xs={2}>
                            <InstagramIcon onClick={()=>Active('Instagram')} className="D6_Card_Opt_Fields"/>                       
                          </Grid>
                          <Grid  onClick={()=>Active('GitHub')}item xs={2}>
                             <StorefrontIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid item xs={2}>
                            <TwitterIcon  onClick={()=>Active('Twitter')}className="D6_Card_Opt_Fields"/>
                          </Grid>
                          {category!=='classic'?<>
                          <Grid  onClick={()=>Active('YouTube')} item xs={2}>
                            <YouTubeIcon  className="D6_Card_Opt_Fields"/>                        
                          </Grid>
                          <Grid item  onClick={()=>Active('Telegram')} xs={2}>
                            <TelegramIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid onClick={()=>Active('GoogleMeet')}item xs={2}>
                            <DateRangeIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          {category==='platinum'?
                          <Grid item onClick={()=>Payment(true)} xs={2}>
                          <AccountBalanceIcon className="D6_Card_Opt_Fields" />       
                                             </Grid>:null}
                                             {category==='platinum'?
                          <Grid item onClick={()=>Notes()} xs={2}>
                          <SpeakerNotesIcon className="D6_Card_Opt_Fields" />       
                                             </Grid>:null}
                                             <Grid onClick={()=>Active('snapChat')} className="D6_Bottom_SM" item xs={2}>
                          <i class="fab fa-snapchat-ghost snap_plt"></i>
 </Grid>
                          </>:null}
      
                          <Grid item xs={12} style={{marginTop:'7px'}}>
                          {active?
                          <TextField className="D6_Card_Opt_FieldsText" size="small" id="socialprofile" value={(active==='Facebook'?Facebook:
                          (active==='Instagram')?Instagram
                          :(active==='Whatsapp'?Whatsapp
                          :(active==='LinkedIn'?LinkedIn
                          :(active==='Twitter'?Twitter
                          :(active==='GitHub'?GitHub
                          :(active==='YouTube'?YouTube
                          :(active==='Telegram'?Telegram 
                          :(active==='snapChat'?snapChat                         
                             :GoogleMeet


                          
                           ) )))))))}  label={active==='snapChat'?('Snapchat Username'):active==='Whatsapp'?(active+' CountryCode<Number>'):active==='GitHub'?('Business Link'):active==='GoogleMeet'?('Meeting link'):(active+' Username')}  onChange={(e)=>{
                            (active==='Facebook'?setFacebook(e.target.value):
                            (active==='Instagram')?setInstagram(e.target.value)
                            :(active==='Whatsapp'?setWhatsapp(e.target.value)
                            :(active==='LinkedIn'?setLinkedIn(e.target.value)
                            :(active==='Twitter'?setTwitter(e.target.value)
                            :(active==='GitHub'?setGitHub(e.target.value)
                            :(active==='YouTube'?setYouTube(e.target.value)
                            :(active==='GoogleMeet'?setGoogleMeet(e.target.value)
                            :(active==='snapChat'?setsnapChat(e.target.value)
                            :setTelegram(e.target.value)
          


                             ) )))))))
                          
                          }}  variant="outlined">

                          </TextField>:null}
                          </Grid>
                        </Grid>
                      {/* </div> */}
                        </Grid>
                      </div>
                     {payment?
                      <>
                      <Grid  container   sm={12}>
                        <Grid item  sm={6} xs={6}>
                        

<TextField value={pay1} onChange={(e)=>setPay1(e.target.value)} style={{width:'80%'}} label='Pay option 1' />
                          </Grid>
                          <Grid style={{marginTop:'5px'}} item sm={4} xs={6}  className="D6_Card_Opt_Fields">
                               <div style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#f51464',borderRadius:'30px',height:'40%',paddingTop:'5px'}}>
                          <label  for="pay1" style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >{imageQr1||props.pay1ValueN?'Uploaded':'Upload QRcode Image'}</label>
                          </div>

                          <input type="file"  id="pay1" style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageQR1(e.target.files[0])}/><br />
                      
                          </Grid>
                        </Grid>
                        <Grid  container sm={12}>
                        <Grid item  sm={6} xs={6}>

<TextField value={pay2}onChange={(e)=>setPay2(e.target.value)} style={{width:'80%'}} label='Pay option 2' />
                          </Grid>
                          {/* <Grid item sm={4} xs={4}>
                          <Chip   color="secondary" label="Upload QRcode image"  />
<TextField value={pay2Value} onChange={(e)=>setPay2Value(e.target.value)}  style={{width:'90%'}} label='Payment URL' />
                          </Grid> */}
                          <Grid style={{marginTop:'5px'}} item sm={4} xs={6}  className="D6_Card_Opt_Fields">
                               <div style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#f51464',borderRadius:'30px',height:'40%',paddingTop:'5px'}}>
                          <label  for="pay2" style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >{imageQr2||props.pay2ValueN?'Uploaded':'Upload QRcode Image'}</label>
                          </div>

                          <input type="file"  id="pay2" style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageQR2(e.target.files[0])}/><br />
                      
                          </Grid>
                        </Grid> 
                        
                        <Grid style={{paddingBottom:'20px'}} container sm={12}>
                        <Grid item  sm={6} xs={6}>

<TextField value={pay3} onChange={(e)=>setPay3(e.target.value)} style={{width:'80%'}} label='Pay option 3' />
                          </Grid>
                          <Grid style={{marginTop:'5px'}} item sm={4} xs={6}  className="D6_Card_Opt_Fields">
                               <div style={{ display:'flex',justifyContent:'space-around' , backgroundColor: '#f51464',borderRadius:'30px',height:'40%',paddingTop:'5px'}}>
                          <label  for="pay3" style={{fontSize:'small',fontWeight:'bold' ,width:'100%',color: 'white',cursor:'pointer'}} >{imageQr3||props.pay3ValueN?'Uploaded':'Upload QRcode Image'}</label>
                          </div>

                          <input type="file"  id="pay3" style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>ImageQR3(e.target.files[0])}/><br />
                      
                          </Grid>
                        </Grid>
                        </>:null} 
                        {Note?
                        <Grid  spacing={0} container xs={12}>

                        <TextField 
                        value={note}
                        onChange={(e)=>setNote(e.target.value)}
                        style={{width:'100%', margin:'5px'}}
          label="Notes"
          placeholder="Write your notes here..."
          multiline
          rows={4}

          variant="outlined"
        />                      </Grid>:null}
        {props.identity&&!template&&auth&&auth.auth&&auth.auth.planCategory=='platinum'?
        <Grid container style={{justifyContent:'center'}} sm={12} xs={12}>
          <Button variant='contained' onClick={()=>Template('platinum')} style={{backgroundColor:'#61126b'}}>
          {auth&&auth.auth&&auth.auth.planCategory=='platinum'&&category=='platinum'?"Change Template":"Migrate to platinum"}
          </Button></Grid>:props.identity&&template&&category==='platinum'?
        <Grid container spacing={1} sm={12} xs={12}>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline'}} onClick={()=>CardT('1')} sm={2}item >Platinum1</Grid>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline'}} onClick={()=>CardT('2')}sm={2}item>Platinum2</Grid>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline'}} onClick={()=>CardT('3')}sm={2}item>Platinum3</Grid>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline'}} onClick={()=>CardT('4')}sm={2}item>Platinum4</Grid>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline'}} onClick={()=>CardT('5')}sm={2}item>Platinum5</Grid>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline'}} onClick={()=>CardT('6')}sm={2}item>Platinum6</Grid>

        </Grid>:null}
        {props.identity&&!template1?
                <Grid container style={{justifyContent:'center',marginTop:'20px'}} sm={12} xs={12}>

          <Button variant='contained' onClick={()=>Template1('classic')} style={{backgroundColor:'#61126b'}}>
{category=='classic'?"Change Template":"Migrate to classic"}
          </Button></Grid>:props.identity&&template1&&category==='classic'?
        <Grid container spacing={1} sm={12} xs={12} style={{justifyContent:'center',marginTop:'20px'}}>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline',textDecorationColor:'#e1163c'}} onClick={()=>CardT('1')} sm={2}item >Classic1</Grid>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline',textDecorationColor:'#e1163c'}} onClick={()=>CardT('2')}sm={2}item>Classic2</Grid>
          <Grid style={{cursor:'pointer',textDecorationLine:'underline',textDecorationColor:'#e1163c'}} onClick={()=>CardT('3')}sm={2}item>Classic3</Grid>
          {/* <Grid style={{cursor:'pointer'}} onClick={()=>CardT('4')}sm={2}item>Template4</Grid>
          <Grid style={{cursor:'pointer'}} onClick={()=>CardT('5')}sm={2}item>Template5</Grid> */}
        </Grid>:null}
        {props.identity?
        <Grid sm={12} xs={12} >
        <FormGroup style={{justifyContent:'center',marginTop:10}}  row>
      <FormControlLabel
        control={<Switch size='medium' edge="start" checked={checkedB} onChange={checkedT} />}
        label="Private"
      />
      </FormGroup>
          </Grid>:null}
    
          {props.identity?
        <Grid sm={12} xs={12} >
        <FormGroup style={{justifyContent:'center',marginTop:10}}  row>
      <FormControlLabel
        control={<Switch size='medium' edge="start" checked={checkedW} onChange={checkedWeb} />}
        label="Business Profile"
      />
      </FormGroup>
          </Grid>:null}
     { checkedB?
      <Grid>
            <Grid>
            <TextField value={master} onChange={(e)=>Master(e.target.value)} type="number" style={{width:'22%'}} label='Master pin' />

              </Grid>
              <Grid>
              <TextField value={shared} onChange={(e)=>Shared(e.target.value)} type="number" style={{width:'22%'}} label='Shared pin' />

              </Grid>
            </Grid>:null}
        </Paper>
                </Grid>
                </>:billing&&!invoice?<BillInfo stripeResponse={stripeResponse} category={category}/>
                :
                <HaloDWNLD  paymentRes={paymentRes} cardRes={cardRes} />}
        </Grid>
      </Grid>
      {checkedW?
      <div style={{marginTop:'20px'}}>
        <Site_Preview live={live} countryF={countryF} username={username} site={props.site}  dcard_url={props.dcard_url} companyData={companyData} />
      </div>:null}
    </div>
  );
}
