import React from 'react'
import HaloLogin from '../SignUPForm/D6_Halo_Login'

import {useCookies} from 'react-cookie'
import D6_SL_Footer from '../common/Footer/D6_SL_Footer'
import D6_SL_AF from '../common/AboveFooter/D6_SL_AF'

const D6_DASAC_Services = (props) => {
    const[auth]=useCookies(['auth'])

    // props.Navbar()
     const{live}=props
if(!auth.auth){
    return (
        <>
            {/* <SBGI/> */}
            {/* <TabS/> */}
            <HaloLogin live={live} />
            <D6_SL_AF />
            <D6_SL_Footer />
        </>
    )
}
else{
    window.location.href='/'
}}

export default D6_DASAC_Services
