import React, { useState ,useEffect} from 'react';
import {useCookies} from 'react-cookie'
import { makeStyles } from '@material-ui/core/styles';

import { Grid ,Button, Box, Chip, TextField,Typography,Dialog} from '@material-ui/core';
import {DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import './profile.css'
import { Accordion,AccordionSummary,AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import MenuItem from '@mui/material/MenuItem';
import D6_DASAC_Form from './customForm/D6_DASAC_Form';
import D6_DASAC_Form_planUpdate from './customForm/D6_DASAC_Form_planUpdate';
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
export default function Admin(props) {
  const{live}=props
    const[auth]=useCookies(['auth'])
    const[filter,setFilter]=useState([])
    const[filter1,setFilter1]=useState([])
    const[filterE,setFilterE]=useState([])
    const[templates,setTemplates]=useState([])
    const [value, setValue] = React.useState(0);

    const [processing,setProcessing]=useState(false)
    const [open,setOpen]=useState(false)
    const [status,setStatus]=useState(false)
const [active,setActive]=useState('Member ID')
const [keywods,setKeywords]=useState({
  memberID:'',
  firstName:'',
  email:'',
  planCategory:'',
  planType:'',
  proPlan:'',
  createdDate:'',
  expiryDate:'',
  phonenumber:''

})
const [anchorEl, setAnchorEl] = React.useState(false);
  const open1 = Boolean(anchorEl);
  const [open2,setOpen2]=useState(false)
  const [template,setTemplate]=useState(null)
  const [member,setMember]=useState('')
  const [all,setAll]=useState(false)


  const classes = useStyles();
    useEffect(() => {
        if(!auth.auth){
          window.location.href='/signin'
        }
         }, [auth.auth])
    useEffect(() => {
        if(auth.auth){
        //  fetch(live+`memEx`,
         fetch(live+`memList`,
        { method: 'GET',
        headers:{
          'Content-Type' :'application/json'  
        }})
        //.then(resp=>console.log(resp))

        .then(res => res.json())
        .then(resp=>Filter(resp))
     } }, [auth.auth])
   
     useEffect(() => {
      if(auth.auth){
      //  fetch(live+`memEx`,
       fetch(live+`templates`,
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      }})
      //.then(resp=>console.log(resp))

      .then(res => res.json())
      .then(resp=>setTemplates(resp))
   } }, [auth.auth])

     useEffect(() => {
      if(auth.auth){
       fetch(live+`memEx`,
      //  fetch(live+`memList`,
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      }})
      //.then(resp=>console.log(resp))

      .then(res => res.json())
      .then(resp=>setFilterE(resp))
   } }, [auth.auth])

     const Filter=(resp)=>{
       setFilter(resp)
       setStatus(true)
     }
     
const rows=filter&&filter[0]&&!filter1[0]?
(filter.map((item,k) => {
  return  {
    id:k,
    MemberID: item.memberID,
    FirstName: item.fname,
    email: item.email,
    PlanCategory: item.planCategory,
    PlanType: item.planType,
    Phonenumber:item.phonenumber,
    CreatedDate:item.createdDate,
    ExpiryDate:item.expiryDate,
    ProPlan:item.proPlan
  };
})):
filter&&filter[0]&&filter1[0]?
(filter1.map((item,k) => {
  return  {
    id:k,
    MemberID: item.memberID,
    FirstName: item.fname,
    email: item.email,
    PlanCategory: item.planCategory,
    PlanType: item.planType,
    Phonenumber:item.phonenumber,
    CreatedDate:item.createdDate,
    ExpiryDate:item.expiryDate,
    ProPlan:item.proPlan
  };
})):
''


const Active=(val)=>{
  setActive(val)
}
const renderDetailsButton = (params) => {
  return (
      <strong>
          <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: 16 }}
              onClick={() => {
                  MailSend(params.row)
                 
              }}
          >
              Send Mail
          </Button>
      </strong>
  )
}


const renderDetailsButton1 = (params) => {
  return (
      <strong>
          <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ marginRight: 16 }}
              onClick={() => {
                  MailSend(params.row)
                 
              }}
          >
              Update
          </Button>
      </strong>
  )
}


const MailSend=(val)=>{
  setMember(val.MemberID)
  setOpen2(true)


}


const Change=(val)=>{
  if(active==='Member ID'){
    setKeywords({
      ...keywods,memberID:val
    })
  }
else  if(active==='First Name'){
  setKeywords({
    ...keywods,firstName:val
  })
}
else  if(active==='Email'){
  setKeywords({
    ...keywods,email:val
  })
}
else  if(active==='Plan Category'){
  setKeywords({
    ...keywods,planCategory:val
  })
}
else  if(active==='Plan Type'){
  setKeywords({
    ...keywods,planType:val
  })
}
else  if(active==='Pro Plan'){
  setKeywords({
    ...keywods,proPlan:val
  })
}
else  if(active==='Created Date'){
  setKeywords({
    ...keywods,createdDate:val
  })
}
else  if(active==='Expiary Date'){
  setKeywords({
    ...keywods,expiryDate:val
  })
}
else{
  setKeywords({
    ...keywods,phonenumber:val
  })
}
Filter1(val)

  

}
const Filter1=(val)=>{
  if(val.length>2){
  if(keywods.memberID&&active==='Member ID'||keywods.firstName&&active==='First Name'||keywods.email&&active==='Email'||keywods.planCategory&&active==='Plan Category'||keywods.planType&&active==='Plan Type'||keywods.proPlan&&active==='Pro Plan'||keywods.createdDate&&active==='Created Date'||keywods.expiryDate&&active==='Expiary Date'||keywods.phonenumber&&active==='Phone Number')
  setFilter1(filter.filter((count)=>
  ((keywods.memberID&&active==='Member ID'?count.memberID.toString():keywods.firstName&&active==='First Name'?count.fname.toLowerCase():keywods.email&&active==='Email'?count.email.toLowerCase():keywods.planCategory&&count.planCategory&&active==='Plan Category'?count.planCategory.toLowerCase():keywods.planType&&count.planType&&active==='Plan Type'?count.planType.toLowerCase():keywods.proPlan&&active==='Pro Plan'?count.proPlan.toString().toLowerCase():keywods.createdDate&&active==='Created Date'?count.createdDate.toLowerCase():keywods.expiryDate&&active==='Expiary Date'?count.expiryDate.toString().toLowerCase():count.phonenumber.toLowerCase()).includes(active==='memberID'?val:val.toLowerCase())
 
  
  
  )))
  }
  else{
    setFilter1(filter)
  }
}


const columns = [
  { field: 'id', headerName: 'ID', width: 50 },
  {
    field: 'MemberID',
    headerName: 'MemberID',
    width: 90,
  },
  {
    field: 'FirstName',
    headerName: 'FirstName',
    width: 110,
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    width: 200,
  },{
    field: 'PlanCategory',
    headerName: 'PlanCategory',
    width: 140,
  },
  {
    field: 'PlanType',
    headerName: 'PlanType',
    width: 130,
  },
  {
    field: 'ProPlan',
    headerName: 'ProPlan',
    width: 100,
  },
  {
    field: 'CreatedDate',
    headerName: 'CreatedDate',
    width: 100,
  },
  {
    field: 'ExpiryDate',
    headerName: 'ExpiryDate',
    width: 100,
  },
  {
    field: 'Phonenumber',
    headerName: 'Phonenumber',
    type: 'number',
    width: 150,
  },
  {
    field: 'Send ALL',
    headerName:'Send ALL',
    width: 130,
    renderCell: renderDetailsButton,
   sortable:false
    

  },
  {
    field: 'Update',
    headerName: 'Update',
    width: 110,
    renderCell: renderDetailsButton1,
    sortable:false


    

  }
]
           

     const expiryMail=()=>{
         setProcessing(true)
         setOpen(true)
      


        fetch(live+'share'
    
        ,
          {
      
            method:'POST',
       
            })
            .then(res=> res.json())
            .then((jsonRes)=>emailRes(jsonRes))
    
            
          
            .catch(error=>console.log(error))
    
    
      

     }
     const emailRes=(val)=>{
         if(val.detail=='Mail Sent'){
         setProcessing(false)
        }
         else{
             setOpen(false)
            setProcessing(false)

             alert('Unable to send mail')
         }
     }
 
  
  const handleClose1=(val)=>{
    setTemplate(val)
    handleClose()
  }
  const handleClose=()=>{
    setOpen(false)
    setOpen2(false)
    setAnchorEl(null);
  }



const SendAll=(val)=>{
  if(val.field==='Send ALL'){
    if (template&&template.name!=='planUpdate.html'){
setOpen2(true)
setAll(true)
    }
    else{
      alert('please select appropriate template')
    }
  }

}






  if(auth.auth&&status&&filter[0])

  return (<>
      <div className={classes.root}>
        <div className='heading_filters'>
          Select keywords to apply filters
        </div>
        <Grid container style={{textAlign:'center'}} >

  
    <Dialog
        open={open2}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className='icon-close-btn'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
{template&&template.name?('Template choosen '+template.name):'Please Select Template'}            
          </Typography>
          {template&&template.name&&template.name=='planUpdate.html'?<D6_DASAC_Form_planUpdate  member={member}  live={live} template={template} />:<D6_DASAC_Form member={member} all={all} active={active} value={(active==='Member ID'?keywods.memberID:active==='First Name'?keywods.firstName:active==='Email'?keywods.email:active==='Plan Category'?keywods.planCategory:active==='Plan Type'?keywods.planType:active==='Pro Plan'?keywods.proPlan
          :active==='Created Date'?keywods.createdDate:active==='Expiary Date'?keywods.expiryDate:keywods.phonenumber)} live={live} template={template}  />  }
                  </DialogContent>
      </Dialog>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  onClick={()=>Active('Member ID')}  className='chip_filter' label='Member ID' />
        </Grid>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  className='chip_filter'onClick={()=>Active('First Name')} label='First Name' />
        </Grid>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  className='chip_filter'onClick={()=>Active('Email')} label='Email' />
        </Grid>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  className='chip_filter'onClick={()=>Active('Plan Category')} label='Plan Category' />
        </Grid>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  className='chip_filter'onClick={()=>Active('Plan Type')} label='Plan Type' />
        </Grid>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  className='chip_filter'onClick={()=>Active('Pro Plan')} label='Pro Plan' />
        </Grid>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  className='chip_filter'onClick={()=>Active('Created Date')} label='Created Date' />
        </Grid>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  className='chip_filter'onClick={()=>Active('Expiary Date')} label='Expiary Date' />
        </Grid>
        <Grid sm={2} xs={4} item>
          <Chip color='secondary'  className='chip_filter'onClick={()=>Active('Phone Number')} label='Phone Number' />
        </Grid>
{templates[0]?<>
  
        <Grid sm={2} style={{marginTop:'10px',cursor:'pointer'}} xs={4} item>
<div  className='template'>
  <div>
    {template&&template.name?template.name:'Select Template'}
  </div>
<ArrowDropDownIcon style={{fontSize:'25px',cursor:'pointer'}}  onClick={(e)=>setAnchorEl(true)}/>

</div>
          {/* <Chip color='secondary'  className='chip_filter'onClick={()=>setAnchorEl(true)} label='Select Template' /> */}
        </Grid>
     
    
      <Menu
        d="simple-menu"
        anchorEl={anchorEl}
        open={open1}
        className='menu_template'
        onClose={handleClose}
     
      >
        {templates.sort((a, b) => a.id > b.id ? 1 : -1).map((fil,idx)=>{
return(
        <MenuItem  key={idx} className='menu_temp_sub' onClick={()=>handleClose1(fil)}>{fil.name}</MenuItem>
)})}

      </Menu>
   </>:null}

        </Grid>
        <Grid  container>
          <TextField value={(active==='Member ID'?keywods.memberID:active==='First Name'?keywods.firstName:active==='Email'?keywods.email:active==='Plan Category'?keywods.planCategory:active==='Plan Type'?keywods.planType:active==='Pro Plan'?keywods.proPlan
          :active==='Created Date'?keywods.createdDate:active==='Expiary Date'?keywods.expiryDate:keywods.phonenumber)} label={active+' filter'} style={{width:'100%',marginTop:'15px',marginBottom:'10px'}}  onChange={(e)=>Change(e.target.value)} />

        </Grid>
        {keywods.memberID||keywods.firstName||keywods.email||keywods.planCategory||keywods.planType||keywods.proPlan||keywods.createdDate||keywods.expiryDate||keywods.phonenumber?<div className='heading_filters'>
          Filter applied on {keywods.memberID&&active==='Member ID'?('Member ID ='+keywods.memberID):null}{keywods.firstName&&active==='First Name'?('First Name ='+keywods.firstName):null}{keywods.email&&active==='Email'?('Email ='+keywods.email):null}{keywods.planCategory&&active==='Plan Category'?('Plan Category ='+keywods.planCategory):null}{keywods.planType&&active==='Plan Type'?('Plan Type ='+keywods.planType):null}{keywods.proPlan&&active==='Pro Plan'?('Pro Plan ='+keywods.proPlan):null}{keywods.createdDate&&active==='Created Date'?('Created Date ='+keywods.createdDate):null}{keywods.expiryDate&&active==='Expiary Date'?('Expiary Date ='+keywods.expiryDate):null}{keywods.phonenumber&&active==='Phone Number'?('Phonenumber ='+keywods.phonenumber):null}
        </div>:null}
        
      
 

{filter[0]?<div style={{ height: 600, width: '100%' ,paddingTop:'20px'}}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        onColumnHeaderClick={(val)=>SendAll(val)}
        // onRowClick={(val)=>console.log(val)}
        disableColumnMenu
      />
    </div>:null}

</div>
{filterE&&filterE[0]?<>
<Grid  item sm={12} xs={12}>
                                <AppBar position="static" className="D6_PreviewAppBarPlt">
                    <Tabs value={value} aria-label="tabs">
                    <Tab label="Expiring Plans" {...a11yProps(0)} />
                    {/* <Tab label="Business" {...a11yProps(1)} /> */}
                    </Tabs>
                </AppBar>
                        </Grid>

                        <Grid  item sm={12} xs={12}>
                        <TabPanel value={value} index={0}>
                        {filterE.sort((a, b) => a.expiryDate > b.expiryDate ? 1 : -1).map((fil,idx)=>{
          return(
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Member ID: {fil.memberID}</Typography>
            </AccordionSummary>
          
            <AccordionDetails>
            
              <Typography>
               Email: {fil.email}
              </Typography>
            
            </AccordionDetails>
            <AccordionDetails>
             
              <Typography>
               Expiry Date: {fil.expiryDate } 
              </Typography>
           
            </AccordionDetails>
            <AccordionDetails>
            <Typography>
           
              Purchased On: {fil.createdDate} 

             
              </Typography>
             
            </AccordionDetails>
            <AccordionDetails>

            <Typography>
           
           Expiry reminders: {fil.reminderCount} 

          
           </Typography>
           </AccordionDetails>

          </Accordion>
          )
      })} 
       
     {filter[0]?
      <Grid  item>
              <Button  variant="outlined" onClick={()=>expiryMail()}  style={{backgroundColor:'#61126b',height:'30px',fontSize:'12px',marginTop:10}}>
              {processing?"Sending..":'Send Email'} 
                  </Button>

              </Grid>:null}
              </TabPanel>
                        </Grid>
                        </>:null}
      </>
  )
  if(!status){
  
    return(   
        <Box sx={{ display: 'flex',justifyContent:'center' ,paddingTop:'20px'}}>
        <CircularProgress />
      </Box>)
  
  }
  if(status&&!filter[0]){
  
    return(   
      <div>          
      <h1 style={{fontWeight:'bold',textAlign:'center',marginTop:'25px'}}>No Plan Expiring in this period</h1>
      </div>)
  
  }
}