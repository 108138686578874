import React, { useState,useEffect } from 'react';
import { Data } from './D6_FE_Accordian_Data';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import '../../MainCSS.css'
import './D6_FE_Accordian_CSS.css'
import D6_FE_Heading from '../FAQ_HeadingSection/D6_FE_Heading';
import D6_SL_Footer from '../common/Footer/D6_SL_Footer';
import D6_SL_AF from '../common/AboveFooter/D6_SL_AF';
// import Aos from 'aos';
// import 'aos/dist/aos.css'; 


const AccordionSection = styled.div`
margin-top:30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
/*  position: relative;  */
  height: 170vh;
  height: 100%;
 /* background: #fff;  */
  background: white;  
  z-index: 4;
  /*
  @media (max-width: 1200px) {
        height: 180vh ;
  }
  @media (max-width: 750px) {
        height: 110vh ;
  }
  @media (max-width: 660px) {
        height: 120vh ;
  }
  @media (max-width: 469px) {
        height: 135vh ;
  }
  @media (max-width: 430px) {
        height: 146vh ;
  }
  @media (max-width: 400px) {
        height: 154vh ;
  }
  @media (max-width: 350px) {
        height: 167vh ;
  }  */
`;

const Container = styled.div`
/*  position: absolute; */
  width: 100%;
  top: 30%;
/*  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);*/
`;

const Wrap = styled.div`
  background: #272727;
  background: white;
  color: #fff;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: 1.3rem;
    text-align: left;
  }
  span {
    margin-right: 1.5rem;
  }
  @media (max-width: 750px) {
    h1 {
    font-size: 1.05rem;
    padding: 1rem;
    }
  }
  
`;

const Dropdown = styled.div`
  background: #1c1c1c;
  background: white;
  color: white;
  color: black;
  width: 100%;
  height: 130px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
 /* align-items: center; */
  border-bottom: 1px solid white;
  border-bottom: 1px solid var(--redcolor);
  border-top: 1px solid white;
  border-top: 1px solid var(--redcolor);
  p {
    padding: 2rem;
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
  }
  @media (max-width: 750px) {
    p {
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    }
  }
  /*
  @media (max-width: 1200px) {
        height: 160px ;
    p {
    padding: 0rem 1rem;
    font-size: 1rem;
    }
  }
  @media (max-width: 750px) {
        height: 190px ;
  }
  @media (max-width: 652px) {
        height: 210px ;
  }
  @media (max-width: 600px) {
        height: 240px ;
  }
  @media (max-width: 540px) {
        height: 270px ;
  }
  @media (max-width: 480px) {
        height: 300px ;
  }
  @media (max-width: 440px) {
        height: 350px ;
  }
  @media (max-width: 400px) {
        height: 400px ;
  }
  @media (max-width: 357px) {
        height: 470px ;
  }   */
`;

const Accordion = () => {
//   useEffect(() => {
//     Aos.init({ duration: 1000 });
// }, []); 
  const [clicked, setClicked] = useState(false);

  const toggle = index => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <>
      <D6_FE_Heading/>
      <IconContext.Provider className="D6_FE_A_IC" value={{ color: 'var(--bcolor)', size: '25px' }}>
    
      <AccordionSection /* data-aos="fade-left"
                    data-aos-delay="100"
                    data-aos-easing="ease-in-out"   
                    data-aos-once = "true" */ className="D6_FE_AS">
        <Container className="D6_FE_Cont">
          {Data.map((item, index) => {
            return (
              <>
                <Wrap className="D6_FE_Wrap" onClick={() => toggle(index)} key={index}>
                  <h1 style={{color:'#000',fontWeight:'normal'}}>{item.question}</h1>
                  <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown className="D6_FE_DD">
                    <p>{item.answer}</p>
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
    <D6_SL_AF />
    <D6_SL_Footer />

    </>
  );
};

export default Accordion;