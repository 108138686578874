import React from 'react'
import CardClsWeb1 from './D6_halo_cardclsweb1'
import CardClsWeb2 from './D6_halo_cardclsweb2'
import CardClsWeb3 from './D6_halo_cardclsweb3'

export default function D6_halo_cardclsweb(props) {
const{card}=props
const {name,email,designation,organization,web,mobile,mobile1,image,image1,key1,facebook,instagram,whatsapp,linkedIn,twitter,gitHub,youTube,username}=props
  return (
    <div>{card==='1'?
      <CardClsWeb1 name={name} email={email} designation={designation}  
      organization={organization} web={web}  mobile1={mobile1} facebook={facebook} instagram={instagram}whatsapp={whatsapp}linkedIn={linkedIn} mobile={mobile} gitHub={gitHub} youTube={youTube} twitter={twitter}key1={key1} image={image}  image1={image1} username={username} />:
      card==='2'?
      <CardClsWeb2  name={name} email={email} designation={designation} facebook={facebook} instagram={instagram}whatsapp={whatsapp}linkedIn={linkedIn} mobile={mobile} gitHub={gitHub} youTube={youTube} twitter={twitter}
      organization={organization} web={web}   mobile1={mobile1} mobile={mobile} username={username} key1={key1} image={image}image1={image1}/>:
      <CardClsWeb3 name={name} email={email} designation={designation} 
      organization={organization} web={web} facebook={facebook} username={username} instagram={instagram}whatsapp={whatsapp}linkedIn={linkedIn} mobile={mobile} gitHub={gitHub} youTube={youTube} twitter={twitter}  mobile1={mobile1} mobile={mobile}  key1={key1}image={image}image1={image1} />
    }
    </div>
  )
}

