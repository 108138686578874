import React from 'react'
import CardClsWeb1H from './D6_halo_cardclsweb1H'
import CardClsWeb2H from './D6_halo_cardclsweb2H'
import CardClsWeb3H from './D6_halo_cardclsweb3H'

export default function D6_halo_cardclswebH(props) {
const{card}=props
const {name,email,designation,organization,mobile,mobile1,key1,username}=props
  return (
    <div>{card=='1'?
      <CardClsWeb1H name={name} email={email} designation={designation}  
      organization={organization}   mobile1={mobile1} username={username}  mobile={mobile}key1={key1}  />:
      card=='2'?
      <CardClsWeb2H  name={name} email={email} designation={designation}  mobile={mobile} 
      organization={organization}    mobile1={mobile1} username={username} mobile={mobile}  key1={key1}/>:
      card=='3'?
      <CardClsWeb3H name={name} email={email} designation={designation} 
      organization={organization}   mobile={mobile}   mobile1={mobile1} username={username} key1={key1} mobile={mobile}   />
      :null
    }
    </div>
  )
}

