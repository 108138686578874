import React,{useEffect, useRef, useState} from 'react';
import './site.css';
import Card from '@mui/material/Card';
import Img1 from '../../assets/Images/BussCover.jpg'
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// import Typography from '@mui/material/Typography';
import { Dialog, DialogContent } from '@mui/material';

import { CardActionArea } from '@mui/material';
import {Paper,Container,Typography,Button,Box,Grid} from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import reactCSS from 'reactcss';
import { Array } from './array';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import D6_DASAC_Form from './SubscriptionForm/D6_DASAC_Form';
import D6_FE_AccountBox from '../SignUPForm/D6_FE_AccountBox';
import {useCookies} from 'react-cookie'
import axios from 'axios'
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


function Site(props) {
  const[auth,setAuth]=useCookies(['auth'])
  const {company,product1,product2,product3,product4,product5,product6,productC,data,live}=props
const [followed,setFollowed]=useState(false)
const  Load=()=>{
 setOpen(false)
 setFollowed(true)
}
  useEffect(()=>{
    if(auth&&auth.followAuth){
      let formData = new FormData();
      formData.append('email',auth.followAuth.email)
      formData.append('phonenumber',auth.followAuth.phone)
      formData.append('site',data.username?data.username:data.siteID)

      fetch(live+'follow'
      ,
      {
      
        method:'PUT',
      
        body:formData
        })
      
        .then(res=>res.json())
        .then((resp)=>FollowRes(resp))
      
      
        .catch(error=>console.log(error))
    }
 
  


           },[auth])
           const FollowRes=(resp)=>{
             if(resp.detail=='Success'){

setFollowed(true)         
   }


           }
  const myRef = useRef(null)
const[open,setOpen]=useState(false)
const[open1,setOpen1]=useState(false)
const[openS,setOpenS]=useState(false)
const [copy,setCopy]=useState(false)


// const [data1]=React.useState({
//   email:auth&&auth.auth&&auth.auth.email?auth&&auth.auth.email:'',
//   phone:auth&&auth.auth&&auth.auth.email?auth&&auth.auth.phonenumber:'',
//   site:data&&data.username?data.username:data&&data.siteID?data.site

// })

  const styles1 = reactCSS({
    'default': {
    'backgroundImg': {
      // background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../../assets/Images/HomeHair1.png'))`,
      background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)),url(${company&&company.imageC?company.imageC:company&&company.image?URL.createObjectURL(company.image):data?`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/site/${data.imageN}`:Img1})`,
  
    }}})
    const scrollView=()=>{
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  

    }
    const handleClose = () => {
      setOpen(false);
      setOpen1(false)
      setOpenS(false)
};
const Follow=()=>{
  if(!auth.auth&&!followed){
    setOpen(!open)
  }
  if(auth&&auth.auth&&!followed){
    handleSubmit()
  }
}
const handleSubmit=()=>{
  

    const formData=new FormData
    formData.append('email',auth.auth.email)
    formData.append('phonenumber',auth.auth.phonenumber)
    formData.append('site',data.username?data.username:data.site)

    fetch(live+'follow'
    ,
    {
    
      method:'POST',
    
      body:formData
      })
    
      .then(res=>res.json())
      .then((resp)=>MailRes(resp.detail))
    
    
      .catch(error=>console.log(error))
     }

    const MailRes=(val)=>{
      if(val=='Success'){
      Data()
      }
      else{
        alert(val)
      }
    }
    const Data=()=>{
      // console.log(auth&&auth.auth?auth.auth:'','auths')
  setAuth('followAuth',{'email':auth.auth.email,'phone':auth.auth.phonenumber,'site':data.username?data.username:data.siteID},{
     
    expires:0, 
  })
}

const copyToClipboard = (valu) => {
  setCopy(true)

 const el = document.createElement('textarea');
 el.value =valu;
 document.body.appendChild(el);
 el.select();
 document.execCommand('copy');
 document.body.removeChild(el);


};
    return(
        <div>

<Dialog
      open={openS}
      disableScrollLock={ true }
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <Share message={data&&data.username?data.username:data&&data.siteID?data.siteID:''}  live={live} name={auth&&auth.auth&&auth.auth.fname?auth.auth.fname:''} handleClose={handleClose}/>
    </Dialog>
<Dialog
        open={open}
        disableScrollLock={ true }
        // TransitionComponent={Transition}
        onClose={handleClose}
        className='Dialog_CButton'
        disableBackdropClick
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className='icon-close-btn'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Please Add Your Details              
          </Typography>
          <D6_DASAC_Form live={props.live} Load={Load}  site={company&&company.username?company.username:company&&company.siteID?company.siteID:data&&data.username?data.username:data&&data.siteID?data.siteID:''}/>
          <Typography onClick={()=>setOpen1(!open1)}
            component="h5"
            variant="h5"
            className='signIn_1'>
Sign In              
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
disableScrollLock={ true }
open={open1}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox live={props.live}  handleClose={handleClose} />     
    </Dialog>
    {copy?
            <Snackbar  open={copy} autoHideDuration={2000} anchorOrigin={{vertical:'top',horizontal:'center'}} onClose={()=>setCopy(false)}>
  <Alert   severity="info">
    Business URL copied
    </Alert>
</Snackbar>:null}

        <Grid container justify="center" alignItems="center" className="Site_Grid">
        <Grid item lg={12} sm={12} md={12} xs={12} className="Site_Grid1"  >
        <div className="Site_IMG_BG" style={styles1.backgroundImg}>
   { data?<Grid style={{paddingRight:'35px'}} container justifyContent='center' sm={12}xs={12}>
     <Grid item sm={10} style={{display:'inline-flex',justifyContent:'end'}} xs={8}>
     {/* <Grid item sm={6} xs={6}> */}

     <div class='icon_card_text'onClick={()=>Follow()} > {followed?'Following':'Follow'}</div>

<i onClick={()=>window.open(`https://halodigital.app/${data&&data.username?data.username:data.siteID}`)}  class="fas fa-portrait icon_card1"></i>
{/* </Grid> */}
{/* <Grid item sm={6} xs={6}> */}

{/* </Grid> */}
<i onClick={()=>setOpenS(true)} class="fas fa-envelope icon_card1"></i>
<i onClick={()=>copyToClipboard(`https://halodigital.app/business/${data&&data.username?data.username:data.siteID}`)} class="fas fa-copy icon_card1"></i>
</Grid>

{auth&&auth.auth?<Grid item sm={2} style={{textalign:'end',display:'inline-flex'}} xs={2}>

<i onClick={()=>window.open(`https://halodigital.app/dash`)}  class="fas fa-user icon_card1"></i>
<i onClick={()=>window.open(`https://halodigital.app/dash`)}  class="fas fa-arrow-right icon_card1"></i>

</Grid >:null}

</Grid>:null}
        <div className='Site_MainH' >
{company&&company.name?company.name:data&&data.siteName?data.siteName:'Welcome to HaloDigital'}
</div>
{data&&!data.subHeading?null:
<div className='Site_Sub' >
  {company&&company.name&&company.subHead?company.subHead:data&&data.subHeading?data.subHeading:'We are a team of digital thinkers & innovators'}
</div>}
{/* <Grid onClick={()=>window.open(`https://halodigital.app/${data&&data.username?data.username:data.siteID}`)} container justifyContent='center' sm={12}xs={12}>
<i class="fas fa-portrait icon_card"></i>

</Grid> */}
<Grid container justifyContent='center' sm={12}xs={12}>
<i  onClick={()=>scrollView()} className="fas fa-chevron-down Icon_down"></i>

</Grid>


        </div>
        </Grid>
        </Grid>
         <div ref={myRef}>
     
        { data&&!data.moreInfo?null:<Accordion className='Site_ACC'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="Site_Head1">More about us</Typography>
            </AccordionSummary>
          
          
            <AccordionDetails>
             
              <Typography>
              {company&&company.name&&company.moreInfo?company.moreInfo:data&&data.moreInfo?data.moreInfo:
              'Here comes the information about your company or your acheivements, in this section you can describe about your company'}
              </Typography>
           
            </AccordionDetails>
            <AccordionDetails>
           
            </AccordionDetails>
          </Accordion>  }  
         
             <Container className='Site_contM'>
             {productC&&productC[0]?<Grid container justify="center" alignItems="center" fixed className="Site_GridS"   >

{productC.map((product,idx)=>{
return(
 product.live===1?<Grid item lg={4} sm={12} md={4} xs={12} key={idx}  className="Site_Grid1"  >
  <Container className="Site_Cont_P">
  <Card sx={{ maxWidth: 345,borderRadius:'10px !important' }}>
<CardActionArea style={{display:'block'}}>
<CardMedia
component="img"
//  height="240"
className='Site_IMG'
image={product&&product.image?`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/products/${product.imageN}`:Img1}

alt="green iguana"
/>
<CardContent className='card_color'>
<Typography gutterBottom variant="h5" className='Product_n' component="div">
{product&&product.productName?product.productName:'Product Name'}
</Typography>
{product&&product.productType?<Typography className='card_text'  variant="body2">
{product&&product.productType?product.productType:'Product Type'}
</Typography>:null}

{product&&product.productInfo?<Accordion className='Site_ACC2'>
<AccordionSummary
expandIcon={<ExpandMoreIcon style={{marginBottom:'20px'}} />}
aria-controls="panel1a-content"
id="panel1a-header"
className='Site_Summ'
>
<Typography className="Site_Head2">Product Info</Typography>
</AccordionSummary>

<AccordionDetails className='Site_Detail'>

<Typography   className='Site_Detail1' >
{product&&product.productInfo?product.productInfo:'This section includes additional information about the category/product'}
</Typography>

</AccordionDetails>

</Accordion>:null }
<Grid container sm={12}  xs={12} className='grid_chip' >

{ product&&product.category1?<Chip   className='Site_chip' color="secondary" label={product&&product.category1?product.category1:"Category1" } />
:null} {product&&product.category2?<Chip   className='Site_chip'  color="secondary" label={product&&product.category2?product.category2:"Category2" } />:null}
 
</Grid>
<Grid container sm={12}  xs={12} className='grid_chip' >

{product&&product.orderLink?<Chip   className='Site_chip'  color="secondary" label="Order" onClick={()=>window.open(product.orderLink,'_blank')} />
:null}

{product&&product.contactType==='Whatsapp'?<>
<i  onClick={()=>window.open(`https://wa.me/${product.contact}`)}className="fab fa-whatsapp Icon_Right1_S1"></i></>
:product&&product.contactType==='Contact'?<>
<i  onClick={()=>window.location.href=`tel:${product.contact}`}className="fas fa-mobile-alt Icon_Right1_S"></i>
</>:null}
{product&&product.email?
<i  onClick={()=>window.open(`mailto:${product.email}`)}className="fas fa-envelope Icon_Right1_S"></i>:null}


</Grid>

</CardContent>
</CardActionArea>
</Card>
  </Container>
</Grid>:null
)
})
                     
}
                       </Grid>
            : !data?<Grid container justifyContent="center" alignItems="center" fixed className="Site_GridS"   >
              {Array.map((product,idx)=>{
                return(
                <Grid item lg={4} sm={12} md={4} xs={12} key={idx} className="Site_Grid1"  >
                             <Container className="Site_Cont">
                             <Card sx={{ maxWidth: 345,borderRadius:'10px !important' }}>
           <CardActionArea style={{display:'block'}}>
             <CardMedia
               component="img"
              //  height="240"
               className='Site_IMG'
               image={product1&&product1.image?URL.createObjectURL(product1.image):Img1}
              
               alt="green iguana"
             />
             <CardContent className='card_color'>
               <Typography gutterBottom variant="h5" className='Product_n' component="div">
               {product1&&product1.name?product1.name:`Product${idx+1} Name`}
               </Typography>
               <Typography className='card_text'  variant="body2" >
          {product1&&product1.type?product1.type:'Product Type'}
               </Typography>
              
               <Accordion className='Site_ACC2'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{marginBottom:'20px'}} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className='Site_Summ'
            >
              <Typography className="Site_Head2">Product Info</Typography>
            </AccordionSummary>
          
            <AccordionDetails className='Site_Detail'>
            
              <Typography   className='Site_Detail1' >
             {product1&&product1.moreInfo?product1.moreInfo:'This section includes additional information about the category/product'}
              </Typography>
            
            </AccordionDetails>

          </Accordion> 
          <Grid container sm={12} spacing={1} xs={12} className='grid_chip' >

                            <Chip   className='Site_chip' color="secondary" label={product1&&product1.category1?product1.category1:"Category1" } />
                            <Chip   className='Site_chip'  color="secondary" label={product1&&product1.category2?product1.category2:"Category2" } />
    
                          </Grid>
                          <Grid container sm={12} spacing={1} xs={12} className='grid_chip' >
                          
                          <Chip   className='Site_chip'  color="secondary" label="Order"/>

                          {product1&&product1.contactIcon==='Whatsapp'?<>
                            <i  onClick={()=>window.open(`https://wa.me/${product1.contactLink}`)}className="fab fa-whatsapp Icon_Right1_S1"></i></>
                            :product1&&product1.contactIcon==='Contact'?<>
                            <i  onClick={()=>window.open(`tel:${product1.contactLink}`)}className="fas fa-mobile-alt Icon_Right1_S"></i>
                            </>:null}
                            <i  onClick={()=>window.open(`tel:${product1.contactLink}`)}className="fas fa-mobile-alt Icon_Right1_S"></i>
                            <i  className="fas fa-envelope Icon_Right1_S"></i>
                            </Grid> 
              
             </CardContent>
           </CardActionArea>
         </Card>
                             </Container>
                         </Grid>
              )})
                    
                         }
                      
              
                         </Grid>:null}
             </Container>
             </div>
            

             </div>
    );
}

export default Site;

function Share(props){
  const{live}=props
  const [email,setEmail]=useState('')
  const [send,setSending]=useState(false)

const handleSubmit=()=>{
  const msg=`https://halodigital.app/business/${props.message}`
  setSending(true)
  const formData=new FormData()
  formData.append('email',email)
  formData.append('msg',msg)
  formData.append('name',props.name)


  {


    fetch(live+'share'
    ,
      {
  
        method:'POST',
   
        body:formData
        })
        .then(res=> res.json())
        .then((jsonRes)=>SendRes(jsonRes))

        
      
        .catch(error=>console.log(error))
      
      }
      const SendRes=(resp)=>{
        if(resp.detail==='Mail Sent')
        {
          props.handleClose()
        }
      }

}
return(
  <div >
            <Grid container spacing={0} xs={12} >
            <ValidatorForm
              //  useRef="form"
                onSubmit={handleSubmit}
               
            >
              <Grid  style={{margin:'20px'}}item xs={12}>
          
              <TextValidator
                      label="Email"

                      onChange={(e)=>setEmail(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='email'

                      value={email}     
                      validators={['required','isEmail']}
                      errorMessages={['Email  is required']}
                  />

         

                </Grid>
                <Grid  style={{margin:'20px'}}item xs={12}>
          
                  <Button type='submit' className="D6_Info_Button">
                  { !send?' Send':'sending'}
                  </Button>
                  
  </Grid>
                  

                  </ValidatorForm>
              </Grid>

    </div>
)


}