import React ,{useState,useEffect,useRef}from 'react';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';

import { PhotoshopPicker  } from 'react-color';

import Dialog from '@material-ui/core/Dialog';
import {Chip ,Typography,Button}from '@material-ui/core';

import Halo_Pass_Template from './Halo_Pass';
import './Halo_Pass.css'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {useCookies} from 'react-cookie'
import {Box} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { makeStyles } from '@material-ui/core/styles';

import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(30),
    },
  },
  'search-box-wrapper': {
    backgroundColor: '#ffffff',
    height: '48px',
    borderRadius: '4px',
    // minWidth: '130px',
    width: '100%',
    border: '1px solid rgb(97, 18, 107)',
    display: 'flex',
    paddingLeft: '10px',
    justifyContent: 'space-between',
    margin: '0px auto 10px ', 
    // position: 'absolute',
    // top:'20%',
    // left: '50%',
    // marginLeft: '-350px',
  },
  'search-field': {
    height: '46px',
    width: 'calc(100% - 150px)',
    display: 'flex',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function Halo_Pass_Preview(props){
  const {live}=props
  const [template,setTemplate]=useState('')
const [label,setLabel]=useState('Pass Title')
const [colorB1,setColorB1]=useState(template&&template.colorB1?template.colorB1:'black')
const [openB1,setOpenB1]=useState(false)
const [colorB2,setColorB2]=useState(template&&template.colorB2?template.colorB2:'black')
const [openB2,setOpenB2]=useState(false)
const [colorH,setColorH]=useState(template&&template.colorH?template.colorH:'#daa520')
const [openH,setOpenH]=useState(false)
const [colorV,setColorV]=useState(template&&template.colorV?template.colorV:'#ffff')
const [openV,setOpenV]=useState(false)
const [colorQR,setColorQR]=useState(template&&template.colorQR?template.colorQR:'black')
const [openQR,setOpenQR]=useState(false)
const [openE,setOpenE]=useState(false)
const [openErr,setOpenErr]=useState(false)

const [openA,setOpenA]=useState(false)

const [value, setValue] = useState(template&&template.expiryDate?template&&template.expiryDate:'');
const [openC,setOpenC]=useState(false)
const [edit,setEdit]=useState(false)
const [edit1,setEdit1]=useState(false)
const [saved,setSaved]=useState(false)
const [saved1,setSaved1]=useState(false)

const[auth]=useCookies(['auth'])
const [status,setStatus]=useState(false)
const [passes,setPasses]=useState([])
const [filter1,setFilter1]=useState([])

const [passType,setPassType]=useState('Scan')

const [value1, setValue1] = React.useState(0);

const [valueC, setValueC] = useState(template&&template.createdDate?template&&template.createdDate:'');
const [data,setData]=useState({
  title:'',
  name:'',
  phonenumber:'',
  email:''
})
const [selected, setSelected] = useState(false);
const [processing, setProcessing] = useState(false);
const [new1,setNew] = useState(false);
const [reload,setReload] = useState(false);


const date=new Date()
const cardRef=useRef()
const [active,setActive]=useState('Active')
const [code,setCode]=useState('')
const [msg,setMsg]=useState('')
const [openM,setOpenM]=useState('')




const handleClose=()=>{
    setOpenB1(false)
    setOpenB2(false)
    setOpenH(false)
    setOpenV(false)
    setOpenQR(false)
    setOpenC(false)
    setOpenE(false)
    setOpenErr(false)
setOpenM(false)
}
const classes = useStyles();
useEffect(() => {

  if(auth.auth){
    const formdata=new FormData()
    formdata.append('memberID',auth.auth.memberID)

    fetch(`${live}passTemplate`,
    {
    method:'put',
    body:formdata

  }  )
  .then((res)=>res.json())
  .then((resp)=>memberTemplate(resp))
  
  
  }
}, [auth.auth,saved1])


useEffect(() => {

  if(auth.auth){
    
    fetch(`${live}passes-member?q=${auth.auth.memberID}`,
    {
    method:'get',

  }  )
  .then((res)=>res.json())
  .then((resp)=>setPasses(resp))
  
  
  }
}, [auth.auth,edit,edit1,reload])

const memberTemplate=(resp)=>{
  if(resp.data){
  setStatus(true)
    setTemplate(resp.data)
    setColorB1(resp.data.colorB1)
    setColorB2(resp.data.colorB2)

    setColorH(resp.data.colorH)

    setColorV(resp.data.colorV)

    setColorQR(resp.data.colorQR)

  }
  if(!resp.data){

    setTemplate('')
    setStatus(true)

  }
}


const Value=(val)=>{
  setValue(
    val.getFullYear()+'-'+((val.getUTCMonth()+1)<10?('0'+(val.getUTCMonth()+1)):val.getUTCMonth()+1)+'-'+((val.getDate())<10?('0'+val.getDate()):val.getDate()))
  setOpenE(false)
}
const ValueC=(val)=>{
  setValueC(
    val.getFullYear()+'-'+((val.getUTCMonth()+1)<10?('0'+(val.getUTCMonth()+1)):val.getUTCMonth())+'-'+((val.getDate())<10?('0'+val.getDate()):val.getDate()))
  setOpenC(false)
}
const Change=(val)=>{
  if(label==='Pass Title')
  setData({
    ...data,title:val
  })
  if(label==='Member Email')
  setData({
    ...data,email:val
  })
  if(label==='Phone Number')
  setData({
    ...data,phonenumber:val
  })

  if(label==='Member Name'){
    setData({
      ...data,name:val
    })
  }
}

const Generate1=()=>{
  if(edit1&&!template.passNumber){
    setOpenA(true)
  }
  else{
    Generate()
  }
}
const Generate=()=>{
  if(edit1){
   setOpenA(false)
    if(data&&data.title&&data.name&&data.phonenumber&&data.email&&code){
      setProcessing(true)
    const formdata=new FormData()
    formdata.append('haloPass',template&&template.haloPass?template.haloPass:'')

    formdata.append('haloTemplate',template&&template.id?template.id:'')

    formdata.append('memberID',auth.auth.memberID)


    formdata.append('passTitle',data.title)
    formdata.append('createdDate',valueC)
    formdata.append('expiryDate',value)
    formdata.append('passMemberName',data.name)
    formdata.append('passMemberEmail',data.email)
    formdata.append('passMemberPhone',data.phonenumber)
    formdata.append('code',code)


    formdata.append('colorB1',colorB1)
    formdata.append('colorB2',colorB2)
    formdata.append('colorH',colorH)
    formdata.append('colorV',colorV)
    formdata.append('colorQR',colorQR)
    formdata.append('passType',passType)
    formdata.append('active',active==='Active'?true:'')




    fetch(`${live}passes`,
    {
    method:'post',
    body:formdata

  }  )
  .then((res)=>res.json())
  .then((resp)=>passMemberCRes(resp))
}

else{
  setOpenErr(true)
}    
  }


  if(edit){
    setProcessing(true)
    const formdata=new FormData()
    formdata.append('haloTemplate',template&&template.id?template.id:'')

    formdata.append('memberID',auth.auth.memberID)
    formdata.append('colorB1',colorB1)
    formdata.append('colorB2',colorB2)
    formdata.append('colorH',colorH)
    formdata.append('colorV',colorV)
    formdata.append('colorQR',colorQR)
    fetch(`${live}passTemplate`,
    {
    method:'post',
    body:formdata

  }  )
  .then((res)=>res.json())
  .then((resp)=>passTemplateRes(resp))
  
  
  
  }
}

const passMemberCRes=(res)=>{
  if(res.data){
    setTemplate({...template,passNumber:res.data.passNumber,
    
    passTitle:res.data.passTitle,passMemberName:res.data.passMemberName
    })
    setActive(res.data.active?'Active':'Inactive')
    setSaved(true)
    setEdit(false)
  setEdit1(false)
  setProcessing(false)
  setFilter1([])

    
  }
}

const passTemplateRes=(val)=>{
if(val.status){
  setSaved1(true)
  setEdit(false)
  setEdit1(false)
  setProcessing(false)
}

}

const Edit=()=>{
  setSaved1(true)
  setEdit(true)
  setEdit1(false)
}
const Edit1=()=>{

  setEdit1(true)
  setEdit(false)
}


const rows=passes&&passes[0]&&!filter1[0]?
(passes.map((item,k) => {
  return  {
    id:k+1,
    haloPass:item.id,
    haloTemplate:item.haloTemplate,
    // memberID: item.memberID,
    passTitle: item.passTitle,
    passMemberName: item.passMemberName,
    expiryDate: item.expiryDate,
    passNumber: item.passNumber,
    createdDate:item.createdDate,
    phonenumber:item.phonenumber,
    codeP:item.codePhone,
    email:item.email,
    colorB1:item.colorB1,
    colorB2:item.colorB2,
    colorH:item.colorH,
    colorV:item.colorV,
    colorQR:item.colorQR,
    passType1:item.scan?'Scan & Go':'Subscription',
    active:item.active?'Active':'Inactive',
    lastAccessed:item.lastAccessed?item.lastAccessed:'NA'


  };
})):passes&&passes[0]&&filter1[0]?
(filter1.map((item,k) => {
  return  {
    id:k+1,
    haloPass:item.id,
    haloTemplate:item.haloTemplate,
    // memberID: item.memberID,
    passTitle: item.passTitle,
    passMemberName: item.passMemberName,
    phonenumber:item.phonenumber,
    codeP:item.codePhone,

    email:item.email,
    expiryDate: item.expiryDate,
    passNumber: item.passNumber,
    createdDate:item.createdDate,
    colorB1:item.colorB1,
    colorB2:item.colorB2,
    colorH:item.colorH,
    colorV:item.colorV,
    colorQR:item.colorQR,
    passType1:item.scan?'Scan & Go':'Subscription',
    active:item.active?'Active':'Inactive',
    lastAccessed:item.lastAccessed?item.lastAccessed:'NA'

  };
})):''


const renderDetailsButton = (params) => {
  return (
      <strong>
          <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: 16 }}
              onClick={() => {
                  EditClicked(params.row)
                 
              }}
          >
              EDIT
          </Button>
      </strong>
  )
}
const renderDetailsButton1 = (params) => {
  return (
      <strong>
          <div
          
              style={{ marginRight: 16 ,color:params.row.active==='Active'?'green':'red'}}
           
          >
              {params.row.active}
          </div>
      </strong>
  )
}
const renderDetailsButton2 = (params) => {
  return (
      <strong>
          <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: 16 }}
              onClick={() => {
                  Share(params.row)
                 
              }}
          >
              SHARE
          </Button>
      </strong>
  )
}
const renderDetailsButton3 = (params) => {
  return (
      <strong>
          <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: 16 }}
              onClick={() => {
                  Redeem(params.row)
                 
              }}
          >
              REDEEM
          </Button>
      </strong>
  )
}
const Redeem=(val)=>{
  

    fetch(`${live}sharePass?q=${val.passNumber}`,
    {
    method:'get',

  }  )
  .then((res)=>res.json())
  .then((resp)=>redeemRes(resp))
}

const redeemRes=(res)=>{
  if(res.status){
    setEdit(false)
    setEdit1(false)
    setReload(true)
    
    setMsg('Smart pass redeemed successfully')
    setOpenM(true)

  }

}
const Share=(val)=>{
  const formdata=new FormData()
    formdata.append('memberName',val.passMemberName)

    formdata.append('email',val.email)
    formdata.append('phonenumber',val.phonenumber)
    formdata.append('passTitle',val.passTitle)
    formdata.append('passNumber',val.passNumber)

    fetch(`${live}sharePass`,
    {
    method:'post',
    body:formdata

  }  )
  .then((res)=>res.json())
  .then((resp)=>shareRes(resp))
}

const shareRes=(resp)=>{
  if(resp.status){

    setMsg('Pass shared successfully')
    setOpenM(true)

  }
}
const EditClicked=(val)=>{
  setNew(true)
  setSelected(true)
  setTemplate({...template,
    haloPass:val.haloPass,
    // passTitle:val.passTitle,
    // passMemberName:val.passMemberName,
    expiryDate:val.expiryDate,
    passNumber:val.passNumber,
    createdDate:val.createdDate,
    colorB1:val.colorB1,
    colorB2:val.colorB2,
    colorH:val.colorH,
    colorV:val.colorV,
    colorQR:val.colorQR,
  })
  setColorB1(val.colorB1)
    setColorB2(val.colorB2)

    setColorH(val.colorH)

    setColorV(val.colorV)

    setColorQR(val.colorQR)
  setValue(val.expiryDate)
  setValueC(val.createdDate)
  setData({
    ...data,
    title:val.passTitle,
    name:val.passMemberName,
    phonenumber:val.phonenumber,
    email:val.email
    
  })
  setCode(val.codeP)
  setActive(val.active==='Active'?'Active':'Inactive')
}
const columns = [
  { field: 'id', headerName: 'S.No.', width: 80 },
  
  {
    field: 'passTitle',
    headerName: 'Pass Title',
    width: 140,
  },
  {
    field: 'passMemberName',
    headerName: 'Issued To',
    type: 'string',
    width: 200,
  },
  {
    field: 'passNumber',
    headerName: 'Pass Number',
    width: 130,
  },
  {
    field: 'codeP',
    headerName: 'Code',
    width: 70,
  },
  {
    field: 'phonenumber',
    headerName: 'Phonenumber',
    width: 130,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 130,
  },
  {
    field: 'createdDate',
    headerName: 'Issued On',
    width: 100,
  },
  {
    field: 'expiryDate',
    headerName: 'Expiring On',
    width: 100,
  },
  {
    field: 'passType1',
    headerName: 'Pass Type',
    width: 130,
  },
  
  {
    field: 'status',
    headerName:'Status',
    width: 80,
    renderCell: renderDetailsButton1,
   sortable:false
    

  }
  ,
  
  {
    field: 'lastAccessed',
    headerName:'Last Accessed',
    width: 130,
    // renderCell: renderDetailsButton1,
  //  sortable:false
    

  }
  ,
  {
    field: 'Edit',
    headerName:'Edit',
    width: 100,
    renderCell: renderDetailsButton,
   sortable:false
    

  },
  {
    field: 'share',
    headerName:'Share',
    width: 100,
    renderCell: renderDetailsButton2,
   sortable:false
    

  }
  ,
  {
    field: 'redeem',
    headerName:'Redeem',
    width: 100,
    renderCell: renderDetailsButton3,
   sortable:false
    

  }
]





const setSearchBar=(val)=>{if(val){
  setFilter1(passes.sort((a, b) => a.id < b.id ? 1 : -1).filter((count)=>
  (count.passTitle.toLowerCase().includes(val.toLowerCase())||
  (count.expiryDate.toString().toLowerCase().includes(val)
  ||(count.passNumber.toString().toLowerCase().includes(val)
  ||(count.phonenumber.toString().toLowerCase().includes(val)
  ||(count.codePhone.toString().toLowerCase().includes(val)
  ||(count.email.toString().toLowerCase().includes(val)

  ||(count.createdDate.toString().includes(val))))
  ))))))
}
else{
  setFilter1(passes)
}}
const newPass=()=>{
  setTemplate({
    ...template,
    haloPass:'',
    passNumber:'',

  })
  setSelected(false)
  setData({
    ...data,
    title:'',
    name:'',
  })
  setSaved1(true)

Edit1()
}


const Code=(val)=>{
  if(val.length<4){
    setCode(val)
  }



}
if(status)
    return(
        <div>

<Grid container>
<Dialog
      open={openB1}
      disableScrollLock={ true }

      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker onAccept={()=>setOpenB1(false)} onCancel={()=>setOpenB1(false)&&setColorB1(null)} color={colorB1} onChange={(color,event)=>setColorB1(color.hex)} />     
    </Dialog>
    <Dialog
      open={openB2}
      disableScrollLock={ true }

      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker onAccept={()=>setOpenB2(false)} onCancel={()=>setOpenB2(false)&&setColorB2(null)} color={colorB2} onChange={(color,event)=>setColorB2(color.hex)} />     
    </Dialog>

    <Snackbar open={openErr}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong> All fields are necessary</strong>
      </Alert>
      </Snackbar>
      <Snackbar open={openM}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>{msg}</strong>
      </Alert>
      </Snackbar>

    <Dialog
      open={openE}
      disableEnforceFocus
      onClose={handleClose}
      disableScrollLock={ true }
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
          label="Add Expiry Date"
          value={value}
          minDate={new Date()}
          onChange={(newValue) => {
            Value(newValue);
          }}
          renderInput={(params) => <TextField style={{ marginLeft:'10px',borderBottomStyle:'none',cursor:'pointer'}} {...params} />}
        />
     </LocalizationProvider>
    </Dialog>

          
    <Dialog
      open={openC}
      disableEnforceFocus
      disableScrollLock={ true }
      onClose={handleClose}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
          label="Add Created Date"
          value={valueC}
          mask="__/__/____"
          onChange={(newValue) => {
            ValueC(newValue);
          }}
          renderInput={(params) => <TextField style={{ marginLeft:'10px',borderBottomStyle:'none',cursor:'pointer'}} {...params} />}
        />
     </LocalizationProvider>
    </Dialog>
                         {/* <Grid container className="D6_Card_Opt_Fields"> */}
                   
                         <Dialog
      open={openH}
      disableScrollLock={ true }

      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker onAccept={()=>setOpenH(false)} onCancel={()=>setOpenH(false)&&setColorH('')} color={colorH} onChange={(color,event)=>setColorH(color.hex)} />     
    </Dialog>
    <Dialog
      open={openV}
      disableScrollLock={ true }

      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker onAccept={()=>setOpenV(false)} onCancel={()=>setOpenV(false)&&setColorV('')} color={colorV} onChange={(color,event)=>setColorV(color.hex)} />     
    </Dialog>




    <Dialog
      open={openQR}
      disableScrollLock={ true }

      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<PhotoshopPicker onAccept={()=>setOpenQR(false)} onCancel={()=>setOpenQR(false)&&setColorQR('')} color={colorQR} onChange={(color,event)=>setColorQR(color.hex)} />     
    </Dialog>



    <Snackbar open={openA}  anchorOrigin={{vertical:'top', horizontal:'center'}} >
     
     <Alert severity="info"
      action={
<>
         <Button className='btn_A' onClick={()=>Generate()} color='success'   size="small">
           Create
         </Button>
         <Button  className='btn_A' onClick={()=>setOpenA(false)} color="success" size="small">
         Back
       </Button>
       </>
       }
     >
Please check the details, you can only change issue and expiry date after creation
     </Alert>
     </Snackbar>
<Grid   item sm={4} xs={12}  >
<Halo_Pass_Template ref={cardRef}  template={template} saved={saved} value={value} data={data} valueC={valueC} colorB1={colorB1}    colorB2={colorB2} colorH={colorH} colorV={colorV} colorQR={colorQR} selected={selected}/>
<Grid container>
<Grid item sm={6} xs={6} style={{textAlign:'center'}} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label={!template?"Create Pass Template":"Edit Halo Pass Template"}  onClick={()=>Edit()}/>
                          </Grid>
                         {template?<Grid item sm={3} xs={3}  style={{textAlign:'center'}} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label={selected?"Edit Pass":"New Pass" } onClick={()=>Edit1()}/>
                          </Grid>
                          :null}
  {                        new1?<Grid item sm={3} xs={3}  style={{textAlign:'center'}} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label="New Pass"  onClick={()=>newPass()}/>
                          </Grid>:null}
                          </Grid>
</Grid>


                 <Grid container  item sm={8} xs={12}  >
          
             {edit||edit1?  <Grid item sm={12} xs={12}>
                      <div className="D6_CARD_BillInfo_head">
                      Please fill in your details
                    </div>
                      <div className="D6_Card_Req_Form" >
                        <Grid container justifyContent='center' alignItems="center">
              
 
                         {edit1?<>
                          {!edit&&!template.passNumber?<><Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label="Pass Title"  onClick={()=>setLabel('Pass Title')}/>
                            </Grid>
                            <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Member Name"  onClick={()=>setLabel('Member Name')}/>
                          </Grid>
                          <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label="Member Email" onClick={()=>setLabel('Member Email')} />
                          </Grid>
                          <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label="Phone Number" onClick={()=>setLabel('Phone Number')} />
                          </Grid></>:null}
                          <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Created On"   onClick={()=>setOpenC(true)}/>
                          </Grid>
                          <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label="Expiry Date" onClick={()=>setOpenE(true)} />
                          </Grid>
                       
                          </>:null}
                         
                          
                         
                          {/* </Grid> */}
                          {edit?<><Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Pass color1" onClick={()=>setOpenB1(true)} />
                          </Grid>
                          <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Pass color2" onClick={()=>setOpenB2(true)}  />
                          </Grid>
                          <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Heading Color"  onClick={()=>setOpenH(true)} />
                          </Grid>
                          <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                            <Chip   color="secondary" label="Values Color"  onClick={()=>setOpenV(true)} />
                          </Grid>

                           
                          <Grid item sm={3} style={{textAlign:'center'}} xs={4} className="D6_Card_Opt_Fields">
                             <Chip  label="QR code Color" color="secondary" onClick={()=>setOpenQR(true)}  />
                          </Grid></>:null}
                          <Grid container>

                          {label==='Phone Number'&&!edit&&!template.passNumber?<Grid item  style={{paddingRight:'5px'}} sm={3} xs={3}>
                          <TextField className="D6_Card_Opt_FieldsText"  value={code} autoFocus size="small"  onChange={(e)=>Code(e.target.value)}   variant="outlined" label={'Code'}  />

                          </Grid>:null}
                          {!edit&&!template.passNumber?<Grid item sm={label==='Phone Number'?9:12} xs={label==='Phone Number'?9:12} >
                          <TextField className="D6_Card_Opt_FieldsText"  autoFocus size="small"  onChange={(e)=>Change(e.target.value)}   variant="outlined" label={label} value={label==='Pass Title'?data.title:label==='Member Email'?data.email:label==='Phone Number'?data.phonenumber:data.name} />
                          </Grid>:null}
                          </Grid>

                      {!edit&&!template.passNumber?    <Grid item style={{textAlign:'center'}} xs={12} >
                          <h1 className='heading_radio'>Please select pass type</h1>
                          <FormControl component="fieldset1">
                  
                  <RadioGroup row aria-label="Select Type" value={passType}   onChange={(e)=>setPassType(e.target.value)} name="row-radio-buttons-group1">
                  <Grid item style={{textAlign:'center'}} sm={6} xs={6}>
                 <FormControlLabel  value="Scan" control={<Radio />} label="Scan & Go" />
                 </Grid>
                 <Grid item style={{textAlign:'center'}} sm={6} xs={6}>
                 <FormControlLabel  value="Subscription" control={<Radio />} label="Subscription" />
                 </Grid>
                  </RadioGroup>
                </FormControl>
                </Grid>:null}

               {!edit? <Grid item style={{textAlign:'center'}} xs={12} >
                          {/* <h1 className='heading_radio'>Please select pass type</h1> */}
                          <FormControl component="fieldset1">
                  
                  <RadioGroup row aria-label="Select Type1" value={active}   onChange={(e)=>setActive(e.target.value)} name="row-radio-buttons-group1">
                  <Grid item style={{textAlign:'center'}} sm={6} xs={6}>
                 <FormControlLabel  value="Active" control={<Radio />} label="Active" />
                 </Grid>
                 <Grid item style={{textAlign:'center'}} sm={6} xs={6}>
                 <FormControlLabel  value="Inactive" control={<Radio />} label="Inactive" />
                 </Grid>
                  </RadioGroup>
                </FormControl>
                </Grid>:null}
                        </Grid>
                      </div>
                    
                       
                      {edit1||edit?<Grid item sm={12} xs={12} style={{textAlign:'center'}} className="D6_Card_Opt_Fields">
                            < Chip   color="secondary" label={processing?'Processing':edit&&!processing?"Save Template":edit1&&selected&&!processing?'Save Pass':'Generate Pass'}  onClick={()=>Generate1()}/>
                          </Grid>:null}
       
       
       
    
          
  
                </Grid>:null}
               
        </Grid>
        </Grid>


       {passes[0]? <Grid  style={{marginTop:'20px'}} item sm={12} xs={12}>
                                <AppBar position="static" className="D6_PreviewAppBarPlt">
                    <Tabs value={value1} aria-label="tabs">
                    <Tab label="Passes Generated" {...a11yProps(0)} />
                    </Tabs>
                </AppBar>
                <Grid item xs={12} className={classes['search-box-wrapper']}>
                        <input type="text" placeholder='Search your Halo Passes' onChange={(e)=>setSearchBar(e.target.value)} className={classes['search-field']} />

                    </Grid>

                {passes[0]?<div style={{ height: 600, width: '100%' ,paddingTop:'20px'}}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection={false}
        
        
        disableColumnMenu
      />
    </div>:null}

                        </Grid>:null}
        </div>
    )


else{
  return(   
    <Box sx={{ display: 'flex',justifyContent:'center' ,paddingTop:'20px'}}>
    <CircularProgress />
  </Box>)
}    
}