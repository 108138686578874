import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Plat1 from '../../../assets/Images/plt1.png';
import Plat2 from '../../../assets/Images/plt2.png';
import Plat3 from '../../../assets/Images/plt3.png';
import Plat4 from '../../../assets/Images/plt4.png';
import Plat5 from '../../../assets/Images/plt5.png';
import Plat7 from '../../../assets/Images/Plt7.png';

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import './D6_SL_SC_CSS.css'
import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import {useCookies} from 'react-cookie'
import D6_FE_AccountBox from "../../SignUPForm/D6_FE_AccountBox";


const useStyles = makeStyles((theme) => ({
  'D6_SL_Img1_div' : {
    //  backgroundImage:'url(`+ Skin + `)',
    //   backgroundSize: '100%',
  },
  'Dialog_CButton': {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        margin: '32px auto',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '25px 30px 0px',
      width: '328px',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        width: '500px',
        
      },
    },
    '& .MuiPaper-rounded': {
      borderRadius: '22px',
    },
    '& .MuiBackdrop-root': {
      background: 'rgba(0,0,0,0.44)',
    },
  },
  'modal-title': {
    color: theme.palette.common.black,
    marginBottom: '0',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '22px',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
      lineHeight: '44px',
      marginBottom: '10px',
    },
  },
  'icon-close-btn': {
    width: '33px',
    height: '33px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '0',
    backgroundColor: 'transparent',
    '& .MuiIconButton-label': {
      display: 'block',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function D6_SL_SCarouselPlt(props) {
  const {live}=props
  const[auth]=useCookies(['auth'])

  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />
  };
  const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
  };
  const Card1=(value)=>{
    if (auth.auth){
      if(auth.auth.planCategory=='platinum')
      {
        props.card1(value)
      }
      else{
    
        alert('Upgrade your plan to access Platinum Cards')
      }
    }
  else{setOpen(true)

  }
   
  }
  const classes = useStyles();
  return (
      <div className="D6_SL_SC_Slider_Div">
         <Dialog
disableScrollLock={ true }
open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox live={live} handleClose={handleClose} />     
    </Dialog>
    <Slider {...settings}>
   
      <Button onClick={()=>Card1('1')}className="D6_SL_Img1_div1">

          <img  src={Plat1} alt="Plat1"/>    

        </Button>
        <Button onClick={()=>Card1('2')}         className="D6_SL_Img1_div1" >
        <img  src={Plat2} alt="Plat2"/>    

        
        </Button>
        <Button onClick={()=>Card1('3')} className="D6_SL_Img1_div1">
        <img  src={Plat3} alt="Plat3"/>    

        </Button>
        <Button onClick={()=>Card1('4')} className="D6_SL_Img1_div1">
        <img  src={Plat4} alt="Plat3"/>    

        </Button>
        <Button onClick={()=>Card1('5')} className="D6_SL_Img1_div1">
        <img  src={Plat5} alt="Plat3"/>    

        </Button>
        <Button onClick={()=>Card1('6')} className="D6_SL_Img1_div1">
        <img  src={Plat7} alt="Plat3"/>    

        </Button>
    </Slider>
    </div>
  );
}