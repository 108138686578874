import React from 'react'
import Button from '@mui/material/Button';
import './D6_DASAC_Form_CSS.css'

import {Container,Paper, SvgIcon} from '@mui/material'

import axios from 'axios'
import ReactPhoneInput from "react-phone-input-2";
 import 'react-phone-input-2/lib/style.css' 
 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

 import {useCookies} from 'react-cookie'


 

const  D6_DASAC_Form =(props)=> {
  const ref=React.useRef()
  const {live}=props
  const[auth,setAuth]=useCookies(['auth'])


  const [data,setData]=React.useState({
    email:'',
    phone:'',
    site:props.site?props.site:''

})
const [processing,setProcessing]=React.useState(false)
const [submitted,setSubmitted]=React.useState(false)
const handleSubmit=()=>{
  setProcessing(true)

    const formData=new FormData
    formData.append('email',auth&&auth.auth&&auth.auth.email?auth&&auth.auth.email:data.email)
    formData.append('phonenumber',auth&&auth.auth&&auth.auth.email?auth.auth.phonenumber:data.phone)
    formData.append('site',data.site)
    fetch(live+'follow'
    ,
    {
    
      method:'POST',
    
      body:formData
      })
    
      .then(res=>res.json())
      .then((resp)=>MailRes(resp.detail))
    
    
      .catch(error=>console.log(error))
     }

    const MailRes=(val)=>{
      if(val=='Success'){
      Data()
      setProcessing(false)
setSubmitted(true)
setData({
...data,email:'',phone:'',
})
    }
    else{
      alert(val)
    }
  }
    const Data=()=>{
  setAuth('followAuth',data,{
     
    expires:0
  })
  props.Load()
}
    
    return (
    <Container className="A_Form_Container">
    <Paper elevation={0} className="A_Form_Cont_Paper">
        <div className="A_Form_Div">

            <div className="Floating_Form">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit}
            >
      
            <div className="TF_div">
              <label className="TF_Label">Email*</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        email:e.target.value
                    })}  name="email"
                      value={data.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['Email required', 'Invalid email']}
                  />
            </div>
            <div className="PNDiv_SA">
              <label className="PN_Label2">Phone Number*</label>
              <ReactPhoneInput className="PN_Input2"
                
                inputProps={{
                  name: 'phonenumber',
                  required: true
            /*      autoFocus: true     */
                }}
                country={'in'}
                value={data.phonenumber}  
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: '+value+', '+country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  }
                  else if (value.match(/1234/)) {
                    return 'Phone number is invalid';
                  }
                   else {
                    return true;
                  }
                }}
                onChange={phonenumber =>setData({...data,phone: phonenumber })}  
              />
            </div>



                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted}
                >
                    {
                    submitted&&!processing?'Submitted':submitted&&processing?'Submitting':'submit'
                    }
                </Button>       
                <div className="Button_UL"></div>

                <div style={{textAlign:"center"}}> OR</div> 

                <div className="Button_UL"></div>

        </ValidatorForm>
        </div>
    </div>
    </Paper>
    </Container>
  
    )
    }


export default D6_DASAC_Form
