import React, { useState ,useEffect,useRef} from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {useCookies} from 'react-cookie'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {  Button, Grid, Typography ,Box} from '@material-ui/core';

import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import Site_Preview from '../customer_site/site_preview';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(30),
    },
  },
  'search-box-wrapper': {
    backgroundColor: '#ffffff',
    height: '48px',
    borderRadius: '4px',
    // minWidth: '130px',
    width: '100%',
    border: '1px solid rgb(97, 18, 107)',
    display: 'flex',
    paddingLeft: '10px',
    justifyContent: 'space-between',
    margin: '0px auto 10px ', 
    // position: 'absolute',
    // top:'20%',
    // left: '50%',
    // marginLeft: '-350px',
  },
  'search-field': {
    height: '46px',
    width: 'calc(100% - 150px)',
    display: 'flex',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
}));
function Alert1(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Businee(props) {
    const {live}=props
  const [copy,setCopy]=useState(false)
    const myRef = useRef()
    const[auth,setAuth]=useCookies(['auth'])
    const [business,setBusiness]=useState([])
    const [status,setStatus]=useState(false)
const [edit,setEdit]=useState('')
  
    useEffect(() => {
      if(!auth.auth){
        window.location.href='/signin'
      }
      else{
        fetch(live+`businessList?q=${auth.auth.memberID}`,
         { method: 'GET',
         headers:{
           'Content-Type' :'application/json'  
         },})
         .then(res => res.json())
         .then(resp=>Business(resp))
      }
       }, [auth.auth])

   const Business=(val)=>{
       setBusiness(val)
       setStatus(true)
   }
    // console.log(vcard)
  
   
    const copyToClipboard = (valu) => {
        setCopy(true)
 
       const el = document.createElement('textarea');
       el.value =valu;
       document.body.appendChild(el);
       el.select();
       document.execCommand('copy');
       document.body.removeChild(el);
 
 
     };
      

  const classes = useStyles();

  if(business[0]&&auth.auth&&!edit)

  return (
    <div style={{height:'100%'}}>

  {/* <div >
 
  <Snackbar open={delet}  anchorOrigin={{vertical:'top', horizontal:'center'}} >
      <Alert  severity="info">
        <AlertTitle>Deleting..</AlertTitle>
      </Alert>
      </Snackbar>

               
                </div> */}
      <Grid container item xs={12}>
          <Grid item xs={12} sm={6} >
          {copy?<>
            <Snackbar  open={copy} autoHideDuration={2000} anchorOrigin={{vertical:'top',horizontal:'center'}} onClose={()=>setCopy(false)}>
  <Alert1   severity="info">
    Business URL copied
    </Alert1>
</Snackbar>
          </>:null
}
                  <div className={classes.root}>
   
                  
                      {business.sort((a, b) => a.ID < b.ID ? 1 : -1).map((bus,idx)=>{
                          return(
                              <Paper className="D6_DashCardsGrid" key={idx} elevation={3} >
                              <Grid container item xs={12}>
                              <Grid item xs={10}>
                              <Typography style={{margin:'10px',fontSize:'14px'}}>
                              Business Name: {bus.siteName}
                              </Typography>
                              </Grid>
                              <Grid item xs={2}>
                              <FileCopyIcon onClick={()=>copyToClipboard(`https://halodigital.app/business/${bus.username?bus.username:bus.key}`)}  style={{margin:'5px',color:'e1126b'}} />
                              </Grid>
                              </Grid>
                              {bus.username?<Typography style={{margin:'10px',fontSize:'14px'}}>
                            Username: {bus.username}
                              </Typography>:<Typography style={{margin:'10px',fontSize:'14px'}}>
                              Business ID: {bus.siteID}
                              </Typography>}
                            
                             
                              {bus.live==1?
                              <Typography style={{margin:'10px',fontSize:'14px'}}>

                      Business  : Active
                          </Typography>: <Typography style={{margin:'10px',fontSize:'14px'}}>

                          Business  : Inactive
    </Typography>}
    <Typography style={{margin:'10px',fontSize:'14px'}}>

Created on: {bus.createdDate}
</Typography>
<Typography style={{margin:'10px',fontSize:'14px'}}>

Expiring on: {bus.expiryDate}
</Typography>
<Typography style={{margin:'10px',fontSize:'14px'}}>

Views : {bus.views}
</Typography>
                              
                              <Grid container xs={12}  >
                                <Grid  style={{textAlign:'center'}}item xs={6}>
                  <Button   onClick={()=>setEdit(bus)} variant="outlined"  style={{backgroundColor:'#61126b',margin:'10px',height:'25px',width:'10px',fontSize:'12px',alignItems:'center'}}>
                  Edit
                  </Button>
                  </Grid>
                  <Grid  style={{textAlign:'center'}}item xs={6}>
                  <Button  variant="outlined"  style={{backgroundColor:'#e1163c',margin:'10px',height:'25px',width:'10px',fontSize:'12px',alignItems:'center'}}>
                  Delete
                  </Button>
                    </Grid>

                  </Grid>
                              </Paper>
                          )
                      })}
                       
                 
                  </div>
          </Grid>
         
      </Grid>
    </div>


  );
if(business[0]&&edit){
    return(
        <>
        <Grid  style={{textAlign:'center'}}item xs={12} sm={12}>
        <Button  variant="outlined" onClick={()=>setEdit('')}  style={{backgroundColor:'#e1163c',margin:'10px',height:'25px',fontSize:'12px',alignItems:'center'}}>
        Back to businesses
        </Button>
          </Grid>
    <Site_Preview live={live}  username={edit.username} site={true}  dcard_url={edit.siteID} companyData={edit} />
    
    </>)}
  if(!status){
  
    return(   
        <Box sx={{ display: 'flex',justifyContent:'center' ,paddingTop:'20px'}}>
        <CircularProgress />
      </Box>)
  
  }
  if(status&&!business[0]){
  
    return(   
      <div>          
      <h1 style={{fontWeight:'bold',textAlign:'center',marginTop:'25px'}}>No business created</h1>
      </div>)
  
  }
  
}
