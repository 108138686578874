import React from 'react'
import D6_SL_SCarousel from './D6_SL_SCarousel'
import D6_SL_SCarouselPlt from './D6_SL_SCarouselPlt'
import D6_SL_SCarouselSig from './D6_SL_SCarouselSig'



export default function D6_SLCrouselMain(props) {
    const {category,live}=props
    const card1=value=>{
        props.card1(value)
    }
    return (
        <div>
            {category=='classic'?
            <D6_SL_SCarousel  live={live} card1={card1}/>:
            category=='signature'?<D6_SL_SCarouselSig live={live} card1={card1} />:
            <D6_SL_SCarouselPlt live={live} card1={card1} />
}
        </div>
    )
}
