import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {useCookies} from 'react-cookie'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './D6_Sl_Drawer.css'
export default function TemporaryDrawer(props) {
    const[auth,setAuth,removeCookie]=useCookies(['auth'])

  const [open,setOpen]=React.useState(true)


  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };
  const logout=()=>{
    removeCookie('auth')
    window.location.href='/'
  }
  const Open=()=>{
    setOpen(!open)
  }
const Toggle=()=>{
  props.Toggle()
}
  const list = (anchor) => (
    <Box
      // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '50vw' }}
      className='box_W'
      role="presentation"
      onClick={Open}
      onKeyDown={Open}
    >
      <List>
        <ListItem onClick={()=>{Toggle()}}>
        <ListItemIcon style={{cursor:'pointer',paddingBottom:'2px'}}>
      <ArrowForwardIcon  />
      </ListItemIcon>
      </ListItem>


    


        {(auth.auth?['Home', 'Pricing','FAQ']:['Home', 'Pricing','FAQ','Sign In']).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='Home'?'/':text=='Sign In'?'/signin':text=='Pricing'?'/pricing':'faq'}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
    
     { auth&&auth.auth?<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography className='typo'  >{auth&&auth.auth&&auth.auth.fname?auth.auth.fname:'Name'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography   className='typo1'      
 onClick={()=>window.location.pathname='/dash'} >My Account</Typography>
   <Typography          
 onClick={()=>logout()} className='typo1'>
          Logout 
          </Typography>
        


    

        
         
        </AccordionDetails>
      </Accordion>:null}
     
      {/* {['Gallery', 'Blogs','Contact us'].map((text, index) => ( */}
    
      </List>

    </Box>
  );

  return (
    // <div>
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor={'right'}
            open={props.open}
            onClose={Open}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </>
    // </div>
  );
}