import React from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './D6_halo_perspplt.css'
import Header from '../common/Header/D6_SL_Header'
import Footer from '../common/Footer/D6_SL_Footer'
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import {useCookies} from 'react-cookie'
import Dialog from '@material-ui/core/Dialog';
import D6_FE_AccountBox from '../SignUPForm/D6_FE_AccountBox';

import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';


export default function TopUPPricing(props) {
    const{filter,live,cards}=props
    const[auth,setAuth]=useCookies(['auth'])
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        setOpen1(false)
        setOpen2(false)

      };
    const UpdatePlan=(value)=>{
        if(auth.auth){
            // if(auth.auth.proPlan){
               
                    
                        
                                    props.updatePlan(value)
              
            // }
            // else{
            //     props.updatePlan(value)
            // }
        }
        else{
            setOpen(true)
        }
    }
    return (
        <>
            {/* <div className="Dec6_Pricing_BgImage_Block"></div> */}
            <div className="D6_PricingCont_GridPersPlt">
                {/* <Grid container > */}
                    <Grid container item sm={12} xs={12} className="D6_PersCont_GridPersPlt">
                        <Grid  item sm={4} xs={12}>
                        <Dialog
     disableScrollLock={ true }
     open={open}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox live={live}handleClose={handleClose} />     
    </Dialog>
                            <Paper className="D6_Pers_GridPersP1">
                              
                            <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Purchase cannot be done, Individual plan can hold only 1 card</strong>
       <br /> <strong>More than 1 card is created, please delete your cards to downgrade your plan</strong>

      </Alert>
      </Snackbar>
      <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Purchase cannot be done, Combo plan can hold upto 2 cards</strong>
       <br /> <strong>More than 2 cards are created, please delete your cards to downgrade your plan</strong>

      </Alert>
      </Snackbar>
                               <div className="D6_PricingPers_HeadPersPlt">LITE ({filter[0]?filter[0].currencySymbol+filter[0].amount:null}*)</div> 
                                <ul>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Great value (33% off), save money & time                                 </li >
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Additional {filter[0]?filter[0].cards:null} new passes                                </li >
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Choose among Scan & Go OR Smart Pass                                </li>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Customize the digital smart pass anytime (template, expiry etc.)                                </li>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Track & analyze the usage from dashboard                                </li>
                                {/* <div className="D6_PricingPers_TextDescPers">
                                    *Billed annually 
                                    </div> */}
                              
                                <div className="D6_PricingPers_TextDescPersPlt">
                                    &nbsp;
                                </div>
                                </ul>
                                <div className="D6_PricingPers_SelectPersPlt">
                                <Button  onClick={()=>UpdatePlan('lite')}className="D6_PricingButtonPersPlt" variant="contained">Select</Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid  item sm={4} xs={12}>
                            <Paper className="D6_Pers_GridPersP2">
                                <div className="D6_PricingPers_HeadPersPlt">SUPER ({filter[0]?filter[1].currencySymbol+filter[1].amount:null}*)</div> 
                                <ul>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Suitable for wider businesses/events                                 </li >
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Additional {filter[0]?filter[1].cards:null} new passes                                </li >
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Choose among Scan & Go OR Smart Pass                                </li>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Customize the digital smart pass anytime (template, expiry etc.)                                </li>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Track & analyze the usage from dashboard                                </li>
                                {/* <div className="D6_PricingPers_TextDescPers">
                                    *Billed annually 
                                    </div> */}
                              
                                <div className="D6_PricingPers_TextDescPersPlt">
                                    &nbsp;
                                </div>
                                </ul>
                                <div className="D6_PricingPers_SelectPersPlt">
                                <Button onClick={()=>UpdatePlan('super')} className="D6_PricingButtonPersPlt" variant="contained">Select</Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid  item sm={4} xs={12}>
                            <Paper className="D6_Pers_GridPersP3">
                                <div className="D6_PricingPers_HeadPersPlt">MAX ({filter[0]?filter[2].currencySymbol+filter[2].amount:null}*)</div> 
                                <ul>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Suitable for wider businesses/events                                 </li >
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Additional {filter[0]?filter[2].cards:null} new passes                                </li >
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Choose among Scan & Go OR Smart Pass                                </li>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Customize the digital smart pass anytime (template, expiry etc.)                                </li>
                                <li className="D6_PricingPers_TextDescPersPlt">
                                Track & analyze the usage from dashboard                                </li>
                                {/* <div className="D6_PricingPers_TextDescPers">
                                    *Billed annually 
                                    </div> */}
                              
                                <div className="D6_PricingPers_TextDescPersPlt">
                                    &nbsp;
                                </div>
                                </ul>
                                <div className="D6_PricingPers_SelectPersPlt">
                                <Button  onClick={()=>UpdatePlan('max')}className="D6_PricingButtonPersPlt" variant="contained">Select</Button>
                                </div>
                            </Paper>
                        </Grid>
                        {/* <Grid  item sm={12} xs={12}>
                                <Paper className="D6_Pers_GridPersP">
                                    Create your digital card
                                </Paper>
                        </Grid> */}
                    </Grid>
                  
                {/* </Grid> */}
            </div>

        </>
    )
}
