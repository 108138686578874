import React from 'react'
import CardSigweb1copy from './D6_halo_cardsigweb1copy'
import CardSigWeb2copy from './D6_halo_cardsigweb2copy'
import CardSigWeb3copy from './D6_halo_cardsigweb3copy'

export default function D6_halo_cardsigweb(props) {
  const{card}=props
const {name,email,designation,organization,web,mobile,mobile1,image,image1,facebook,location,instagram,whatsapp,linkedIn,twitter,gitHub,youTube,telegram
  ,googleMeet,sun,mon,tue,wed,thur,fri,sat,color1,headline,key1}=props

  return (
    <div>{card==='1'?
      <CardSigweb1copy  name={name} email={email} designation={designation} 
      organization={organization} web={web} mobile={mobile} key1={key1} mobile1={mobile1}  image={image} image1={image1}
       facebook={facebook} instagram={instagram} headline={headline}
                whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube}
                telegram={telegram} googleMeet={googleMeet}/>:
      card==='2'?
      <CardSigWeb2copy name={name}location={location} email={email} designation={designation}  key1={key1}
      organization={organization} web={web} mobile={mobile}  mobile1={mobile1}  image={image} image1={image1}
      facebook={facebook} instagram={instagram} headline={headline}
      whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube}
      telegram={telegram}  color1={color1}googleMeet={googleMeet}/>:
      card==='3'?
      <CardSigWeb3copy name={name} location={location}email={email} designation={designation} key1={key1}
      organization={organization} web={web}  mobile1={mobile1}  mobile={mobile} image={image} image1={image1}
      facebook={facebook} instagram={instagram} headline={headline}
      whatsapp={whatsapp} linkedIn={linkedIn}twitter={twitter}gitHub={gitHub}youTube={youTube}
      telegram={telegram} googleMeet={googleMeet} sun={sun} mon={mon}tue={tue}wed={wed}thur={thur}fri={fri} sat={sat}
      />:null
    }
    </div>
  )
}
