import React from 'react';
import Grid from '@material-ui/core/Grid';
import './D6_halo_subcat.css';
import D6_SLCrouselMain from '../HomePage/Home_SCarousel/D6_SLCrouselMain';


export default function SubCardSelect(props) {
  // const classes = useStyles();
  const{live}=props
  const category=props.category
  const card1=value=>{
    props.card2(value)
  }
  return (
    <div className="D6_Card_SubCatContainer_Grid">
      <Grid container> 
            <Grid item sm={12} xs={12} className="D6_Card_PreviewSubCatOpts_Grid">
            
                <D6_SLCrouselMain live={live} category={category} card1={card1}/>
             
            </Grid>
      </Grid>
    </div>
  );
}
