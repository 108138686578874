import React,{useState} from 'react';

import Grid from '@material-ui/core/Grid';
import Image from '../../../assets/Images/HomeHair.png';
import './D6_halo_cardsigweb2copy.css';
import Avatar from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import HomePh from '@material-ui/icons/PhoneIphoneOutlined';
import WorkPh from '@material-ui/icons/PhoneInTalkOutlined';
import WorkEmail from '@material-ui/icons/EmailOutlined';
import Website from '@material-ui/icons/LanguageOutlined';
import Loc from '@material-ui/icons/LocationOnOutlined';
import reactCSS from 'reactcss'
import { QRCode } from 'react-qrcode-logo';
import YouTube from '@material-ui/icons/YouTube';
import Telegram from '@material-ui/icons/Telegram';
import GoogleMeetIcon from '@material-ui/icons/Duo';
import CreditCard from '@material-ui/icons/CreditCard';
export default function CardSigWeb2copy(props) {
  const {name,email,designation,organization,web,mobile,mobile1,image,facebook,instagram,whatsapp,linkedIn,twitter,gitHub,youTube,telegram
    ,googleMeet,color1,headline,location,phonePay,googlePay,paytm}=props
    const [active,setActive]=useState('m1')
    const [color,setColor]=useState('#FF7F50')

    const image2=`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/profile/${props.image1?props.image1:null}`
    const val=`https://halodigital.app/${props.username?props.username:props.key1}`

      const styles = reactCSS({
        'default': {
          total: {
            width: '100%',
        height:'150px',
        backgroundColor:`${color1?color1:color}`,
        backgroundColor:'linear-gradient(180deg,var(--vcolor) 20%,var(--redcolor) 100%)',
        opacity: '2px',
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'flex-start',
        /* padding: 0 1.8em; */
        paddingBottom: '0.8em',
        top:'0',
        position: 'absolute',
        /* background-image: url('../../assets/Images/HomeHair.png');
        background-repeat: round; */
        /* background-image: url('../../../assets/Images/HomeHair.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; */
      borderRadius: '19px',
      alignItems: 'center',
      backgroundSize:'cover',
          }}})
  return (
    <div className="SigDivW2">
    <div className="SigBoxContainerWeb2">
    {/* <div style={styles.total}>
       </div> */}
       <div className="SigTopContainerWeb2"></div>
       {/* <div className="SigBackDropLeftW2">
       </div>
       <div className="SigBackDropRightW2">
       </div> */}
       <div className="SigAvatarContW2">
       <Avatar alt="Remy Sharp" src={props.image1?image2:image?URL.createObjectURL(image):Image} className="SigAvatarPicW2"/>
       </div>
       <div className="D6_CARD_SigW2_Grid_Name">
       {name?name:!name?'Your Name':null} 
            </div>
            <Grid items xs={12} className="D6_CARD_SigW2_Grid_headline">
                {headline?headline:!name?'Headline':null}
                </Grid>
            <Grid container >
            <Grid container item xs={8} className="D6_Card_SigW2_BH_Grid">
                <Grid items xs={12} className="D6_CARD_SigW2_Grid_desig">
                {designation?designation:!name?'Your Designation':null} 
                </Grid>
                </Grid>
            <Grid container item xs={4} className="D6_Card_SigW2_Loc_Grid">
                        <Grid item xs={4}>
                        {
                                  !name||location?
                          <Loc onClick={()=>window.open(`https://www.google.co.in/maps/place/${location}`,'_blank')}/>:null}
                        </Grid>
            </Grid>
            
            </Grid>
            <div className="D6_CARD_SigW2_Grid_comp">
                    {organization?organization:!name?'Your Organization':null} 
                    </div>
            <Grid container spacing={0} direction='row' item xs={12} >
                <Grid  item xs={8} >
                    <div className="D6_Card_SigW2_ContactData_Grid">
                    {/* <div className="D6_CARD_SigW2_Grid_Name">
                    {name?name:!name?'Your Name':null} 
                    </div>
                    <div className="D6_CARD_SigW2_Grid_desig">
                    {designation?designation:!name?'Your Designation':null} 
                    </div>
                    <div className="D6_CARD_SigW2_Grid_comp">
                    {organization?organization:!name?'Your Organization':null} 
                    </div> */}
                    {/* <div>
                        <Grid container direction="row" alignItems="center" item xs={12}>
                                <Grid item xs={6}>
                                  <Loc/>                        
                                </Grid>
                                <Grid item xs={6}>
                                  Business Hours
                                </Grid>
                        </Grid>
                    </div> */}
               
                    <div className="D6_CARD_SigW2_Grid_email">
                    <Grid container onClick={()=>window.open(`mailto:${email}`)} direction="row" alignItems="center" item xs={12}>
                    <Grid item xs={2}>{
                                  !name||email?<WorkEmail /> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                  {email?email:!name?'Your Email':null}
                                </Grid>
                        </Grid>
                    </div>
                    <div className="D6_CARD_SigW2_Grid_web">
                          <Grid   onClick={()=>window.open(`https://${web}`,'_blank')}container direction="row" alignItems="center" item xs={12}>
                          <Grid item xs={2}>
                                  {!name||web?
                                  <Website/> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                {web?web:!name?'Your Website':null}         
                                </Grid>
                          </Grid>
                    </div>
                    <div className="D6_CARD_SigW2_Grid_tel">
                         <Grid container direction="row" alignItems="center" item xs={12}>
                         <Grid item xs={2}>
                                  {!name||mobile?
                                  <HomePh onClick={()=>setActive('m1')}/>:null}                        
                                </Grid>
                                
                                <Grid item xs={2}>
                                {!name||mobile1?
                                  <WorkPh onClick={()=>setActive('m2')}/>:null}
                                </Grid>
                                <Grid onClick={()=>window.location.href=`tel:${mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:null}`} item xs={8}>
                                {mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:!name?'Your Mobile Number':null} 
                                </Grid>
                        </Grid>
                          </div>
                    </div>
                  </Grid>
                <Grid container direction='row' item xs={4} className="D6_Card_SigWeb2_QRCont_Grid">
                      {/* <Grid item xs={12} className="D6_Card_SigW2_QR_Grid">
                      {
                                  !name||location?
                          <Loc onClick={()=>window.open(`https://www.google.co.in/maps/place/${location}`,'_blank')}/>:null}
                        </Grid> */}
                      <Grid item xs={12} className="D6_Card_SigWeb2_QR_Grid">
                      <QRCode
          size={90}
          value={props.image1?val:'https://halodigital.app'}
        //   logoImage={logo}
        ecLevel='H'
          logoOpacity='1'
          qrStyle='dots'
          // logoWidth='60'
          quietZone='5'
          fgColor='#61126b'
        
        /> 
                      </Grid>
                </Grid>
            </Grid>
    </div>
    <Grid container  item xs={12}  alignItems="center" className="SigBackDropBottomW2">
               {/* <Grid container item xs={12}></Grid> */}
                <Grid container spacing={1}  item xs={12} alignItems="center"  >
                <Grid container item xs={12} alignItems="center" >
                  <div className="D6_Bottom_SMCont"></div>
                </Grid>
                {facebook?

<Grid className="D6_Bottom_SM" item xs={2}>
<FacebookIcon onClick={()=>{facebook.includes('://')?window.open(facebook,'_blank'):window.open(`https://www.facebook.com/${facebook}`,'_blank')}} />                        
 </Grid>:null}
 {whatsapp?
 <Grid  className="D6_Bottom_SM" item xs={2}>
                  <WhatsAppIcon onClick={()=>{whatsapp.includes('://')?window.open(whatsapp,'_blank'):window.open(`https://wa.me/${whatsapp}`,'_blank')}} />
 </Grid>:null}
 {linkedIn?
 <Grid className="D6_Bottom_SM" item xs={2}>
                  <LinkedInIcon   onClick={()=>{linkedIn.includes('://')?window.open(linkedIn,'_blank'):window.open(`https://www.linkedin.com/in/${linkedIn}`,'_blank')}}/>
 </Grid>:null}
 {instagram?
 <Grid className="D6_Bottom_SM" item xs={2}>
                  <InstagramIcon onClick={()=>instagram.includes('://')?window.open(instagram,'_blank'): window.open(`https://www.instagram.com/${instagram}`,'_blank')} />                       
 </Grid>:null}
 {gitHub?
 <Grid  className="D6_Bottom_SM" item xs={2}>
                    <GitHubIcon onClick={()=>{gitHub.includes('://')?window.open(gitHub,'_blank'):window.open(`https://github.com/${gitHub}`,'_blank')}}  />
 </Grid>:null}
 {twitter?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <TwitterIcon  onClick={()=>{twitter.includes('://')?window.open(twitter,'_blank'): window.open(`https://twitter.com/${twitter}`,'_blank')}} />
 </Grid>:null}

 
 
 {youTube?

<Grid className="D6_Bottom_SM" item xs={2}>
<YouTube onClick={()=>{youTube.includes('://')?window.open(youTube,'_blank'):window.open(`https://www.youtube.com/watch?v=${youTube}`,'_blank')}} />                        
 </Grid>:null}
 {telegram?
 <Grid  className="D6_Bottom_SM" item xs={2}>
   <Telegram onClick={()=>{telegram.includes('://')?window.open(telegram,'_blank'):window.open(`https://t.me/${telegram}`,'_blank')}} />
 </Grid>:null}
 {googleMeet?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <GoogleMeetIcon   onClick={()=>{googleMeet.includes('://')?window.open(googleMeet,'_blank'):window.open(`https://meet.google.com/${googleMeet}`,'_blank')}}/>
 </Grid>:null}
 {googlePay?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <CreditCard onClick={()=>window.open(`https://www.instagram.com/${instagram}`,'_blank')} />                       
 </Grid>:null}
 {phonePay?
 <Grid  className="D6_Bottom_SM" item xs={2}>
    <CreditCard onClick={()=>window.open(`https://github.com/${gitHub}`,'_blank')}  />
 </Grid>:null}
 {paytm?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <CreditCard  onClick={()=>window.open(`https://github.com/${twitter}`,'_blank')} />
 </Grid>:null}

                </Grid>
            </Grid>
    </div>
  );
}
