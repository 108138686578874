import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import './D6_halo_billinfo.css';

import Button from '@material-ui/core/Button';
import {useCookies} from 'react-cookie'
import StripePay from '../Payments/stripePay';
import { useState } from 'react';

export default function BillInfo(props) {
  const {category}=props
  // const {name,email,designation,organization,web,mobile,image}=props
  const[auth,setAuth]=useCookies(['auth'])
const[payment,setPayment]=useState(null)
const classicPay=22
const signaturePay=33
const platinumPay=44

const[add1,setAddress1]=useState(null)
const[add2,setAddress2]=useState(null)
const[city,setCity]=useState(null)
const[stte,setState]=useState(null)
const Payment=(value)=>{
  if(!auth.auth.city){
  if(!add1&&!add2&&!city){
  alert('please fill the Above details')
  }
else{
  
  fetch(' http://127.0.0.1:8000/memInfo',
  {
  method: 'PUT',
  headers:{
    'Content-Type' :'application/json'  
  },
  body: JSON.stringify({memberID:auth.auth.memberID,planCategory:auth.auth.planCategory,planType:auth.auth.planType,fname:auth.auth.fname,lname:auth.auth.lname,email:auth.auth.email,phone:auth.auth.phonenumber,country:auth.auth.country,stte:stte,city:city,addLine1:add1,addLine2:add2})
})
.then(res => res.json())
.then((resp)=>updateResponse(resp))

}

const updateResponse=(resp)=>{
  if (resp.data){
    setAuth('auth',resp.data,{
      maxAge: 3600 ,
      expires:0
   })

   setPayment(value)
  }
  else{
    // console.log('error',resp.detail)
  }
}}
else{
  setPayment(value)

}
}
const stripeResponse=(value)=>{
props.stripeResponse(value)
}


  return (
    <div >
                <Grid container direction='row' item sm={12} xs={12} className="D6_Card_BillInfo_Grid" >
                <Grid direction='column' item sm={12} xs={12}>
                  <Paper className="D6_Card_BillInfo1_Grid">
                      <div className="D6_BillInfo_Form" >
                        <Grid container spacing={1} alignItems="center">
                         <Grid item xs={6}>
                         {/* <div className="D6_Card_Classic_ContactData_Grid"> */}
                            <div className="D6_CARD_BillInfo_head">
                              Billing Information
                            </div>
                          { auth.auth? <><div className="D6_CARD_BillInfo_name">
                              Name: {auth.auth.fname+' '+auth.auth.lname} 
                            </div>
                            <div className="D6_CARD_BillInfo_add2">
                              
                              Add1: {!auth.auth.addLine1?<input  placeholder='Enter address Line1'  className="D6_CARD_BillInfo_country" variant="outlined"onChange={(e)=>setAddress1(e.target.value)} />:auth.auth.addLine1}

                            </div>
                            <div className="D6_CARD_BillInfo_add2">
                              Add2:{!auth.auth.addLine2?<input  placeholder='Enter address Line2'  className="D6_CARD_BillInfo_country" variant="outlined"onChange={(e)=>setAddress2(e.target.value)} />:auth.auth.addLine2}
                            </div>
                            <div className="D6_CARD_BillInfo_city">
                              City: {!auth.auth.city?<input  placeholder='Enter City'  className="D6_CARD_BillInfo_country" variant="outlined"onChange={(e)=>setCity(e.target.value)} />:auth.auth.city}
                            </div>
                            <div className="D6_CARD_BillInfo_state">
                              State:{!auth.auth.stat?<input  placeholder='Enter State'  className="D6_CARD_BillInfo_country" variant="outlined"onChange={(e)=>setState(e.target.value)} />:auth.auth.stat}      
                            </div>
                            <div className="D6_CARD_BillInfo_country">
                              Country: {auth.auth.country}
                            </div>
                            </>:null}
                         {/* </div> */}
                          </Grid>
                        <Grid item xs={6}>
                            <div className="D6_CARD_BillInfo_subt">
                              Sub-Total: ${category==='classic'?'20':category==='signature'?'30':'40'}
                            </div>
                            <div className="D6_CARD_BillInfo_tax">
                              Tax: ${category==='classic'?'2':category==='signature'?'3':'4'} (10%)
                            </div>
                            <div className="D6_CARD_BillInfo_total">
                              Total: ${category==='classic'?classicPay:category==='signature'?signaturePay:platinumPay} 
                            </div>
                        </Grid>
                      </Grid>
                      </div>
                      </Paper>
                </Grid>
                <Grid  container direction="column" spacing={1} item xs={12}>
                <Paper className="D6_Card_BillInfo1_Grid" >
                      <div className="D6_BillInfo_Form" >
                      {!payment?<>
                        <Grid container spacing={2} alignItems="center" item xs={12} >
                        
                        <Grid item xs={12}>
                         {/* <div className="D6_Card_Classic_ContactData_Grid"> */}
                            <div className="D6_CARD_BillInfo_head">
                              Choose your payment mode
                            </div>
                            </Grid>
                         <Grid item xs={12} sm={4} className="D6_Bill_Opts_Grid">
                         {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                            <Button onClick={()=>Payment(true)} className="D6_PreviewButtonNext" variant="contained">Credit/Debit Card</Button> 
                         </Grid>
                         <Grid item xs={12} sm={4} className="D6_Bill_Opts_Grid">
                         {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                            <Button className="D6_PreviewButtonNext" variant="contained">Apple Pay</Button> 
                         </Grid>
                         <Grid item xs={12} sm={4} className="D6_Bill_Opts_Grid">
                         {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                            <Button className="D6_PreviewButtonNext" variant="contained">Wallet</Button> 
                         </Grid>
                      </Grid></>:<StripePay stripeResponse={stripeResponse} pay={category==='classic'?classicPay:category==='signature'?signaturePay:platinumPay}  />}
                      </div>
                      </Paper>
                </Grid>
            {/* </Grid> */}
            {/* <Grid container spacing={1} sm={12} xs={12} >
          <Grid item xs={12}>
            <Button className="D6_PreviewButton" variant="contained">Looks Good, proceed</Button> 
          </Grid>
        </Grid> */}
        </Grid>
    </div>
  );
}
