import React,{useEffect} from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './D6_halo_pricingcls.css'
import {useCookies} from 'react-cookie'
import Dialog from '@material-ui/core/Dialog';
import D6_FE_AccountBox from '../SignUPForm/D6_FE_AccountBox';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

import Button from '@material-ui/core/Button';
import { useRef } from 'react';

export default function Pricingcls(props) {
    const{live,scroll1,cards}=props
    const [value, setValue] = React.useState(0);
    const{country,countryF}=props
    const[auth,setAuth]=useCookies(['auth'])
    const[filter,setFilter]=React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const myRef=useRef(null)
    const handleClose = () => {
        setOpen(false);
        setOpen1(false);

      };

    async function CountryFilter(){
        if(auth.auth){
        let filter1=await country.filter((count)=>
      
        (count.planCategory.toLowerCase().includes('classic'))
        // && (count.countryName.toLowerCase().includes(auth.auth.country.toLowerCase()))
      
      
      
      )
      if(filter1[0]){
      setFilter(filter1)}
      else{
      setFilter(country.filter((count)=>
      (count.planCategory.toLowerCase().includes('classic'))
    //   &&(count.countryName.toLowerCase().includes('usa'))
    ))
      }
        }
        else{
          setFilter(country.filter((count)=>
      (count.planCategory.toLowerCase().includes('classic')
      )
    //   &&(count.countryName.toLowerCase().includes('usa'))
      ))
        }
      }
      
      useEffect(() => {
if(myRef&&scroll1){
  myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  

}      
     
      }, [])
      
      
      useEffect(()=>{ 
        if(country[0]){
      
      
      if(countryF){
      setFilter(country.filter((count)=>
      (count.planCategory.toLowerCase().includes('classic'))&&
      
      (count.countryName.toLowerCase().includes(countryF))
      
      
      
      ))
      
      
      
      }
      if(!countryF&&country[0]){
      CountryFilter()
      
      // if(!filter[0]){
      
      
      
      
      
      // }
      
      }
      
        }
      
      else{
        setFilter([])
      }
            
        },[country,countryF,auth.auth])

        const handleChange = (event, newValue) => {
            setValue(newValue);
          };
          const updatePlan=(value)=>{
            if(auth.auth){ 
              if(auth.auth.planCategory&&cards.length>0&&auth.auth.planCategory!=='classic')  {
setOpen1(true)
              }
              else{ 
            props.category('classic')
            props.updatePlan(value)
          }
        }
        else{
            setOpen(true)
        }
        }
    return (
        <>
            
            <div ref={myRef} className="D6_PricingCont_GridCls">
            {/* <div className="D6_Pricing_HEAD">
                Pricing Plans
            </div>
            <div className="D6_Pricing_SubHead">
                Choose the plan that's right for you and your business
            </div>
            <div className="D6_Pricing_SubHead">All individual plans free for 30 days</div>  */}

                    <Grid container item sm={12} xs={12} className="D6_PersCont_GridCls">
                        <Grid  item sm={12} xs={12}>
                                <div className="D6_Pers_GridCls">
                                    HALO CLASSIC
                                </div>
                        </Grid>
                        <Grid  item sm={4} xs={12}>
                        <Dialog
      disableScrollLock={ true }
      open={open}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox live={live}handleClose={handleClose} />     
    </Dialog>
                            <Paper className="D6_Pers_GridCls1">
                            <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Purchase cannot be done </strong>
       <br /> <strong>Please delete your cards to downgrade plan to classic </strong>

      </Alert>
      </Snackbar>
                               <div className="D6_PricingPers_HeadCls">Individual ({filter[0]?filter[0].currencySymbol+filter[0].amount:null}*)</div> 
                               {/* <div className="D6_PricingPers_SubHeadCls">Free for 90 days with sign-up</div>  */}
                                <ul>
                                    <li className="D6_PricingPers_TextDescCls">
                                        Personalise your own digital card
                                    </li>
                                    <li className="D6_PricingPers_TextDescCls">
                                        Share with Email, Text &amp; QR Code
                                    </li>
                                    <li className="D6_PricingPers_TextDescCls">
                                        Enriched with social media enabled icons
                                    </li>
                                    <li className="D6_PricingPers_TextDescCls">
                                        Unique web url
                                    </li>
                                    <li className="D6_PricingPers_TextDescCls">
                                        Save digital card details to your contacts
                                    </li>
                                    <div className="D6_PricingPers_TextDescPers">
                                    *Billed annually 
                                    </div>
                                    <div className="D6_PricingPers_TextDescPersPlt">
                                    &nbsp;
                                </div>
                                </ul>
                                <div className="D6_PricingPers_SelectPersPlt">
                                <Button  className="D6_PricingButtonPersCls" variant="contained" onClick={()=>updatePlan('individual')}>Select</Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
            </div>
        </>
    )
}
