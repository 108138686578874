import styled from 'styled-components';
import './MainCSS.css';

export const BoxContainer = styled.div`
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    font-family: var(--fontfamily);
`;

export const FormContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const MutedLink = styled.a`
    font-size: 1rem;
    // color: rgba(200,200,200,0.8);
    color: #61126b;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 5px;
`;

export const BoldLink = styled.a`
    font-size: 1rem;
    color: var(--vcolor);
    font-weight: 500;
    text-decoration: none;
`;

export const Input = styled.input`
    width: 100%;
    height: 30px;
    outline: none;
    border: 1px solid rgba(200,200,200,0.3);
    padding: 0px 8px;
/*    border-bottom: 1.4px solid transparent;  */

&::placeholder {
    // color: rgba(200,200,200,1);
    color: gray;
}

&:not(:last-of-type) {
    border-bottom: 1.5px solid rgba(200,200,200,0.4);
}
&:focus {
    outline: none;
    border-bottom: 2px solid var(--redcolor);
}
`;

export const SubmitButton = styled.button `
    width: 100%;
    padding: 11px ;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 100px 100px 100px 100px;
    cursor: pointer;
    transition: all,240ms ease-in-out;

    background: linear-gradient(
        58deg,
        var(--vcolor) 20%,
        var(--redcolor) 100%
    );

    &:hover {
        filter: brightness(1.2);
    }
`;


