import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Sig1 from '../../../assets/Images/Sig1.png';
import Sig2 from '../../../assets/Images/Sig2.png';
import Sig3 from '../../../assets/Images/Sig3.png';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import './D6_SL_SC_CSS.css'
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';

import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core/styles';

import {useCookies} from 'react-cookie'
import CardSigweb1copy from "../../ContactPage/SigCard/D6_halo_cardsigweb1copy";
import CardSigWeb2copy from "../../ContactPage/SigCard/D6_halo_cardsigweb2copy";
import CardSigWeb3copy from "../../ContactPage/SigCard/D6_halo_cardsigweb3copy";
import D6_FE_AccountBox from "../../SignUPForm/D6_FE_AccountBox";

const useStyles = makeStyles((theme) => ({
  'D6_SL_Img1_div' : {
 /*     backgroundImage:'url(`+ Skin + `)',
      backgroundSize: '100%',
      backgroundRepeat: */
  },
  'Dialog_CButton': {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        margin: '32px auto',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '25px 30px 0px',
      width: '328px',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        width: '500px',
        
      },
    },
    '& .MuiPaper-rounded': {
      borderRadius: '22px',
    },
    '& .MuiBackdrop-root': {
      background: 'rgba(0,0,0,0.44)',
    },
  },
  'modal-title': {
    color: theme.palette.common.black,
    marginBottom: '0',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '22px',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
      lineHeight: '44px',
      marginBottom: '10px',
    },
  },
  'icon-close-btn': {
    width: '33px',
    height: '33px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '0',
    backgroundColor: 'transparent',
    '& .MuiIconButton-label': {
      display: 'block',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function D6_SL_SCarouselSig(props) {
  const{live}=props
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />
  };
  const[auth]=useCookies(['auth'])
  const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
  };
  const classes = useStyles();
  const Card1=(value)=>{
    if(auth.auth){
      if(auth.auth.planCategory=='classic')
      {
      alert('Upgrade your plan to access Signature Cards')
      }
      else{
        props.card1(value)
    
      }
    }
    else{
      setOpen(true)
    }
 
  }
  
  return (
      <div className="D6_SL_SC_Slider_Div">
           <Dialog
disableScrollLock={ true }
open={open}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
<D6_FE_AccountBox live={live} handleClose={handleClose} />     
    </Dialog>
    <Slider {...settings}>
 
      <Button onClick={()=>Card1('1')}className="D6_SL_Img1_div1">
          {/* <img  src={Skin} alt="Image1"/>     */}
          {/* <CardSigweb1copy/> */}
          <img  src={Sig1} alt="Sig1"/>    
          
        </Button>
        <Button onClick={()=>Card1('2')}         className="D6_SL_Img1_div1" >
        {/* <CardSigWeb2copy /> */}
        <img  src={Sig2} alt="Sig2"/>    
        </Button>
        <Button onClick={()=>Card1('3')} className="D6_SL_Img1_div1">
        {/* <CardSigWeb3copy/> */}
        <img  src={Sig3} alt="Sig3"/>    
        </Button>
    </Slider>
    </div>
  );
}