import react from 'react'
import LOGO from '../../../src/assets/Images/Halo-logo.svg'
import { makeStyles } from "@material-ui/core/styles";
import D6_FE_AccountBox from './D6_FE_AccountBox';
import Grid from '@material-ui/core/Grid';
import './D6_Halo_Login.css'

// const useStyles = makeStyles((theme) => ({
//     "login-splash": {
//         // display:'flex',
//         // backgroundImage: `url(${IMAGE})`,

//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         backgroundRepeat: 'no-repeat',
//         width: '100vw',
//         height: '100vh'
//     },
//     "login-logo": {
//         position: "absolute",
//         backgroundImage: `url(${LOGO})`,
//         // margin:"50px 0px 10px 10px",
//         // marginTop:20,

//         backgroundPosition:'center',
//         backgroundRepeat: 'no-repeat',
//         width: '60%',
//         height: '60%'
//     }

// }))
 export  default function HaloLogin(props){
     const {live}=props
    // const classes = useStyles();

     return(
        //  <div className={classes["login-splash"]}>
        //       <div className={classes["login-logo"]}> 

        //         </div>
        //      <D6_FE_AccountBox />
            
        //  </div>
            <Grid container items xs={12} className="D6_Halo_Login_Cont">
                <Grid item sm={6} xs={12} className="D6_Halo_Login_Logo">
                    {/* <D6_FE_AccountBox /> */}
                </Grid>
                <Grid item sm={6} xs={12} className="D6_Halo_Login_SS">
                    <D6_FE_AccountBox live={live} />
                </Grid>
            </Grid>
     )
 }