import React from 'react'
import './home_main.css';

import Grid from '@material-ui/core/Grid';


  export default function D6_Home_Main() {
    // setInterval(function () {
    //     const show = document.querySelector('span[data-show]')
    //     const next = show.nextElementSibling || document.querySelector('span:first-child')
    //     const up = document.querySelector('span[data-up]')
    //     if (up) {
    //       up.removeAttribute('data-up')
    //     }
    //     show.removeAttribute('data-show')
    //     show.setAttribute('data-up', '')
    //     next.setAttribute('data-show', '')
    //   }, 2000)




        // const [open, setOpen] = React.useState(false);

        // const handleClickOpen = () => {
        //   setOpen(true);
        // };
      
        // const handleClose = () => {
        //   setOpen(false);
        // };
  /*  state = {
        isOpen: false
    } 
    render() {  */
    return (
        <>
        <div className="D6_FE_Home_IWTP_Block">
            {/* <div
                className="D6_FE_Home_IWTP_Block_H_Div">
                Stay connected
            </div> */}
            <Grid container spacing={0} item xs={12} >
                <Grid item xs={4}>
    
                </Grid>
                <Grid item xs={8}>
             
             <div className='heading_top'  >
  create <br />
  {/* <div class="mask">
    <span data-show>your digital card</span>
    <span>your business profile</span>
    


  
  </div> */}
   <span id="spin" style={{marginBottom:'15px'}}></span>


</div>
<div class="animated-title">
  
                {/* <div class="text-top">
                    <div>
                    <span>create</span>

                    <span>your digital
 </span>
  
        
                    
                    </div>
                </div> */}
               

       
                <div class="text-bottom">
                    <div>stay connected</div>
                </div>
             </div>
             
             </Grid>
             </Grid>
        </div>
        {/* <div>
            <div className="color-overlay"></div>
            </div> */}
        </>
    )
    
}

// export default D6_Home_Main
