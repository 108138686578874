import Header from './modules/common/Header/D6_SL_Header';

import ScrollNPT from './modules/common/Scroll_NewPageTop/D6_DASAC_SNPT';
import {Link} from '@material-ui/core'

import {Route,BrowserRouter, Switch} from "react-router-dom";
import Home from './modules/HomePage/D6_SL_Home';
import Contact from './modules/ContactPage/D6_DASAC_Contact';
import DServices from './modules/ServicesPage/D6_DASAC_Services';
import Pricing from './modules/Pricing/D6_halo_pricing';
import Info from './modules/InfoPage/info';

import { useState ,useEffect} from 'react';
import PersistentDrawerLeft from './modules/Dashboard/dashboard';
import CookieConsent from "react-cookie-consent";

import Accordion from './modules/FAQ_Accordian/D6_FE_Accordian';
import Site from './modules/customer_site/site';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import HaloPassPreview from './modules/passPreview/passPreview';

function App(props) {
  const [showData,setShowData]=useState('')
  const [data,setData]=useState('')

  const [productC,setProductC]=useState('')
  const [processing,setProcessing]=useState(false)
  const [business,setBusiness]=useState('')
  const [template,setTemplate]=useState('')

  const [openP,setOpenP]=useState(false)
  var live="https://d6-halolive.herokuapp.com/"
  // var live="https://d6-halo.herokuapp.com/"
    // var live='http://127.0.0.1:8000/'
    // var live='http://192.168.1.7:8000/'

  const [countryF,setCountryF]=useState(null)


  const Country=(value)=>{

setCountryF(value)  }
var val=window.location.pathname
  var did=val.replace('/','')
  var site=val.replace('/business/','')
  var passV=val.replace('/pass/','')


  
useEffect(()=>{
setProcessing(true)
  if(val.includes('/info')||val==='/'||val==='/pricing'||val==='/signin'||val==='/contact'||val==='/dash'||val==='/faq'||val.includes('/business/')||val.includes('/pass/')){
if(val.includes('business')){
  const formData=new FormData()
  formData.append('siteID',site)
 
          
 
           fetch(live+'business'
          ,
            {
        
              method:'POST',
         
              body:formData
              })
              .then(resp=> resp.json())
              .then((jsonRes)=>handleSiteRes(jsonRes))
 
              
            
              .catch(error=>console.log(error))
}
if(val.includes('pass')){
  const formData=new FormData()
  formData.append('pass',passV)
 
          
 
           fetch(live+'pass'
          ,
            {
        
              method:'POST',
         
              body:formData
              })
              .then(resp=> resp.json())
              .then((jsonRes)=>handlePassRes(jsonRes))
 
              
            
              .catch(error=>console.log(error))
}

else{
  setProcessing(false)
}
    
  }
 
  else{
 const formData=new FormData()
 formData.append('did',did)

         

          fetch(live+'create'
         ,
           {
       
             method:'PUT',
        
             body:formData
             })
             .then(resp=> resp.json())
             .then((jsonRes)=>handleRes(jsonRes))

             
           
             .catch(error=>console.log(error))



           }
           },[])

           const handlePassRes=(res)=>{
          if(res.data){
            setTemplate(res.data)
            setProcessing(false)
          }
          else{
            setProcessing(false)
            window.location.href='/'
          }

                       }

           const handleSiteRes=(res)=>{
if(res.data){
  setData(res.data)
  fetch(live+`products?q=${res.data.siteID}`,
  {
  method: 'GET',
  headers:{
    'Content-Type' :'application/json'  
  },
})
.then(res => res.json())
.then(resp=>ProductC(resp))
}
else{
  window.location.href='/'
}
           }
           const ProductC=(resp)=>{
             setProductC(resp)
             setProcessing(false)
           }





           const handleRes=(jsonRes)=>{
              if(jsonRes.detail==='Private DigiCard'){
                setShowData(jsonRes.data.card)
                setOpenP(true)

                setBusiness(jsonRes.data.business)
                setProcessing(false)


              }
              if(jsonRes.detail==='DigiCard'){
                setShowData(jsonRes.data)
                setBusiness(jsonRes.business)
                setProcessing(false)

              }
             if(!jsonRes.data){
              window.location.href='/'

             }

           }
if(showData&&business===0||showData&&business===1){
  return(
    <Info card={showData} live={live} business={business} openP={openP} />
  )
}
if(productC&&data){
  return(
<Site live={live} productC={productC} data={data}  />)

}
if(template){
  return(
<HaloPassPreview template={template}  />)

}
if(processing){
  return(
    <Box sx={{ display: 'flex',justifyContent:'center' ,paddingTop:'20px'}}>
    <CircularProgress />
  </Box>
  )
}
else{
  return (
    <>
    {window.location.pathname=='/info'?null:
 <CookieConsent
      data-aos="fade-down"
                    data-aos-delay="150"
                    data-aos-easing="ease-in-out"   
                    data-aos-once = "true"
       className="Cookie_CS"
        buttonText="Accept"
        cookieName="Consent"
        style={{ background: "rgb(0,0,0,0.85)", fontSize: "1.1rem",fontWeight:'lighter', width:"100%",
          alignItems: "center", justifyContent:"center" , textAlign: "center",
          position: "absolute", 
          margin: "15px auto !important",
          margin: "0 auto",
          zIndex: "15",  
          position: "fixed",
          display: "relative !important",
          // left: "50%",
          bottom: '0',
          paddingTop: "10px",
          // transform: "translate(-50%,0%)",
          fontFamily: "'Questrial', sans-serif"
        }}
        buttonStyle={{ backgroundColor: "#e1163c", fontSize: "1.4rem", color: "white", 
        fontWeight: "500", marginBottom: "20px", borderRadius: "5px", textAlign: "center",width: '150px', height: '50px'

         }}
        expires={150}
      >
       This website uses cookies, in addition to the cookies that are strictly necessary to operate this website we use the following types of cookies to improve your experience and our services: Functional cookies to enhance your experience (e.g. remember settings), Performance cookies to measure the website's performance and improve your experience.


       For more information, please read our &nbsp;
       <Link style={{color:'#e1163c'}} href='https://www.dec6.in/PrivacyPolicy' target='_blank'>
        privacy policy       </Link><br/>{" "}
      </CookieConsent>}
     <BrowserRouter> 
    <ScrollNPT/> 
    {val==='/'||val==='/pricing'||val==='/signin'||val==='/contact'||val==='/dash'||val==='/faq'?<Header Country={Country} live={live}/>:null}
  <Switch>
             <Route exact path="/"  component={() => <Home  live={live} countryF={countryF} />}  /> 
            <Route exact path="/pricing" component={() => <Pricing  live={live} countryF={countryF}/>} /> 
            <Route exact path="/signin" component={() => <DServices  live={live} />} />   
            <Route exact path="/contact" component={() => <Contact  live={live} />} /> 
            <Route exact path="/dash" component={() => <PersistentDrawerLeft live={live} countryF={countryF} />}  />   
            <Route  exact path="/info"  component={() => <Info   live={live} />}  />  
            {/* <Route  exact path="/pay"  component={() =><RazorPay  />}  />   */}
            <Route  exact path="/faq"  component={() =><Accordion  />}  />  
            {/* <Route  exact path="/business"  component={() =><Site   />}  />   */}



            {/* <Redirect to='/'  /> */}
            </Switch>

      {/* {window.location.pathname==='/dash'?<></>:window.location.pathname!=='/info'?<Footer />:<></>} */}
      {/* {window.location.pathname==='/dash'?<></>:window.location.pathname==='/info'?<></>:<Footer />} */}
     </BrowserRouter>   
    </> 
  );}
}

export default App;
