import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Cls1 from '../../../assets/Images/Cls1.png';
import Cls2 from '../../../assets/Images/Cls2.png';
import Cls3 from '../../../assets/Images/Cls3.png';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import './D6_SL_SC_CSS.css'

import Slide from '@material-ui/core/Slide';
// import CardPltWeb1 from '../../ContactPage/PltCard/D6_halo_cardpltweb1'
// import CardPltWeb2 from '../../ContactPage/PltCard/D6_halo_cardpltweb2'
// import CardPltWeb3 from '../../ContactPage/PltCard/D6_halo_cardpltweb3'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  'D6_SL_Img1_div' : {
 /*     backgroundImage:'url(`+ Skin + `)',
      backgroundSize: '100%',
      backgroundRepeat: */
  },
  'Dialog_CButton': {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        margin: '32px auto',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '25px 30px 0px',
      width: '328px',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        width: '500px',
        
      },
    },
    '& .MuiPaper-rounded': {
      borderRadius: '22px',
    },
    '& .MuiBackdrop-root': {
      background: 'rgba(0,0,0,0.44)',
    },
  },
  'modal-title': {
    color: theme.palette.common.black,
    marginBottom: '0',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '22px',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
      lineHeight: '44px',
      marginBottom: '10px',
    },
  },
  'icon-close-btn': {
    width: '33px',
    height: '33px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '0',
    backgroundColor: 'transparent',
    '& .MuiIconButton-label': {
      display: 'block',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function D6_SL_SCarousel(props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />
  };
  const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
  };
  const classes = useStyles();

  return (

      <div className="D6_SL_SC_Slider_Div">
    <Slider {...settings}>
      <Button onClick={()=>props.card1('1')}className="D6_SL_Img1_div1">
          {/* <img  src={Skin} alt="Image1"/>    */}
          <img  src={Cls1} alt="Cls1"/>    
 
          {/* <CardPltWeb1/> */}
          {/* <div className="D6_SC_SliderDiv" >
              <h3 className="D6_SC_SliderDivH1" >
              Specialized Skin Care
              </h3> */}
              {/* <h3 className="D6_SC_SliderDivH2" >
              Consectetuer adipiscing elit
              </h3>
              <h3 className="D6_SC_SliderDivH2" >
              Consectetuer adipiscing elit
              </h3> */}
                {/* <button className="D6_SL_SL_Button"
                     onClick={handleClickOpen}>
                      Schedule Appointment
                </button> */}
                {/* <Dialog open={open} 
                TransitionComponent={Transition}
                onClose={handleClose} className="Dialog_CButton"
                aria-labelledby="form-dialog-title">
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <AForm/>
                </Dialog> */}
          {/* </div> */}
        </Button>
        <Button onClick={()=>props.card1('2')}         className="D6_SL_Img1_div1" >
        {/* <CardPltWeb2 /> */}
        <img  src={Cls2} alt="Cls2"/>    

          {/* <img src={Laser} alt="Image1"/>
          <div className="D6_SC_SliderDiv" >
              <h3 className="D6_SC_SliderDivH1">
              Laser Treatment
              </h3> */}
              {/* <h3 className="D6_SC_SliderDivH2" >
              Tincidunt ut laoreet dolore
              </h3>
              <h3 className="D6_SC_SliderDivH2" >
              Tincidunt ut laoreet dolore
              </h3> */}
              {/* <button className="D6_SL_SL_Button"
                     onClick={handleClickOpen}>
                      Schedule Appointment
                </button>
                <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        className={classes['Dialog_CButton']}
        disableBackdropClick
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes['icon-close-btn']}>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className={classes['modal-title']}>
            Schedule Appointment
          </Typography>
          <AForm/>
        </DialogContent>
      </Dialog> */}
                {/* <Dialog open={open} 
                TransitionComponent={Transition}
                onClose={handleClose} className="Dialog_CButton"
                aria-labelledby="form-dialog-title">
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <AForm/>
                </Dialog> */}
          {/* </div> */}
        </Button>
        <Button onClick={()=>props.card1('3')} className="D6_SL_Img1_div1">
        {/* <CardPltWeb3/> */}
        <img  src={Cls3} alt="Cls3"/>    

          {/* <img src={Hair} alt="Image1"/>
          <div className="D6_SC_SliderDiv">
              <h3 className="D6_SC_SliderDivH1" >
              Hair Treatment
              </h3> */}
              {/* <h3 className="D6_SC_SliderDivH2" >
              Tincidunt ut laoreet dolore
              </h3>
              <h3 className="D6_SC_SliderDivH2" >
              Tincidunt ut laoreet dolore
              </h3> */}
              {/* <button className="D6_SL_SL_Button"
                     onClick={handleClickOpen}>
                      Schedule Appointment
                </button> */}
                {/* <Dialog open={open} 
                TransitionComponent={Transition}
                onClose={handleClose} className="Dialog_CButton"
                aria-labelledby="form-dialog-title">
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <AForm/>
                </Dialog> */}
          {/* </div> */}
        </Button>
    </Slider>
    </div>
  );
}