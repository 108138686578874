import React from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import {Grid,Container, Typography,Button} from '@material-ui/core';
// import HIcon from '../../../assets/Images/heading-icon.svg';
// import StepIcon from '@material-ui/core/StepIcon';
import Paper from '@material-ui/core/Paper';
import './D6_Halo_WS.css'
import '../../../MainCSS.css'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import Button from '@material-ui/core/Button';
// import Check from '@material-ui/icons/Check';
import '../../ContactPage/animation.css'

import SettingsIcon from '@material-ui/icons/ViewCarousel';
import GroupAddIcon from '@material-ui/icons/RecentActors';
import Contactless from '@material-ui/icons/Contactless';
import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';


const useStyles = makeStyles((theme) => ({

 'D6_SL_WS_Cont' : {
    marginTop: "30px",
},
 'D6_SL_WS_Grid1_Div' : {
    textAlign: "center",
    alignItems: "center",
    marginBottom: "20px",
    color: "#36444e",
},
 'D6_SL_WS_Grid2_DivHI' : {
    marginTop: '0px',
},
 'D6_SL_WS_Grid1_Div_H1' : {
 fontSize: '25px',
 fontWeight: '500',
 color: '#61126b',
 lineHeight: '48px',
 display: 'block',
 margin:'0 0 5px',
 textAlign: 'center',
 '& span': {
     fontSize: '30px',
 },
},
'D6_SL_WS_Grid2_container': {
    width: '100%',
    display: 'flex',
    margin: '40px 0',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
},
 'D6_SL_WS_Grid2_Div' : {
    width: "96%",
    margin: "0px auto",
    [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
      },
},
// 'D6_SL_WS_Grid1' : {
//     width: "100%",
//     textAlign : 'center',
// },
'D6_SL_WS_Grid2_HDiv' : {
    width: "100%",
    borderBottom: "2px solid #e1163c",
    marginBottom: "10px",
    display: 'flex',
},
'D6_SL_WS_HIcon': {
    display: 'inline-flex',
    width: '40px',
    height: '40px',
},
'D6_SL_WS_Grid2_Div_H1' : {
    fontSize: "30px",
    fontWeight: '300',
    color: "#36444e",
    lineHeight: '40px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    display: 'inline-flex',
    textAlign: 'left',
},
'D6_SL_WS_Grid2_Div_P' : {
    fontSize: "18px",
},root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

}));
const D6_SL_WS = (props) => {


  const counterAnim = (qSelector, start = 0, end, duration = 1000) => {
    const target = document.querySelector(qSelector);
    let startTimestamp = null;
    const step = (timestamp) => {
     if (!startTimestamp) startTimestamp = timestamp;
     const progress = Math.min((timestamp - startTimestamp) / duration, 1);
     target.innerText = Math.floor(progress * (end - start) + start);
     if (progress < 1) {
      window.requestAnimationFrame(step);
     }
    };
    window.requestAnimationFrame(step);
   };
   //#endregion - end of - number counter animation
   
   document.addEventListener("DOMContentLoaded", () => {
    counterAnim("#count1", 10, 2000, 2500);
    counterAnim("#count2", 100, 1500, 3000);
    counterAnim("#count3", 1000,5000, 2500);
    counterAnim("#count4", 1, 150, 2500);
   });
    const classes = useStyles();
    const [pricing,setPricing]=React.useState('1')
const Product=(val)=>{
  if(val=='profile'){
    setPricing('2')
    props.Product(val)
  }
  else if(val=='passes'){
    setPricing('3')
    props.Product(val)
  }
  else{
    setPricing('1')
    props.Product(val)
  }
  
}
    return (
        <Container fixed className={classes['D6_SL_WS_Cont']}>
            <Grid container justify="center" alignItems="center" fixed className={classes['D6_SL_WS_Grid']}>
                <Grid item xs={12} className="D6_SL_WS_Grid1">
                    {/* <Typography component="h3" className={classes['D6_SL_WS_Grid1_Div_H1']}> */}
                        {/* <span>Halo</span><br /> */}
                        {/* Connect digitally */}
                        <h1 class="halo-head">#ConnectDigitally</h1>
                        {/* <Typography component="h3" className={classes['D6_SL_WS_Grid1_Div_H1']}>
                        <div> Select your plan &amp; start now</div>
                        <div className={classes['D6_SL_WS_Grid2_HDiv']}></div>
                    </Typography> */}
                     
                     <Grid container  className="count_container"  >
      <Grid sm={3} spacing={1} className='count_display' xs={6}>
        <Grid container>
          <Grid item sm={12} xs={12}>
<Typography style={{color:'white',fontWeight:'600',fontSize: '1.4rem'}}>
  HALO CARDS
  </Typography>

  </Grid>
  <Grid item className="count_name" sm={12} xs={12}>

  <span id="count1" class="display-4"></span>
  <span > +</span>
  </Grid>

      </Grid>
      </Grid>
      <Grid sm={3} spacing={1} className='count_display' xs={6}>
        <Grid container>
          <Grid item sm={12} xs={12}>
<Typography style={{color:'white',fontWeight:'600',fontSize: '1.4rem'}}>
  HALO MEMBERS
  </Typography>

  </Grid>
  <Grid className="count_name" item sm={12} xs={12}>

  <span  id="count2" class="display-4">
  
  </span>
  <span > +</span>
  </Grid>

      </Grid>
      </Grid>
      <Grid sm={3} spacing={1} className='count_display' xs={6}>
        <Grid container>
          <Grid item sm={12} xs={12}>
<Typography style={{color:'white',fontWeight:'600',fontSize: '1.4rem'}}>
  HALO PRODUCTS LISTING
  </Typography>

  </Grid>
  <Grid className="count_name" style={{paddingTop:'19px'}}  item sm={12} xs={12}>

  <span  id="count3" class="display-4"></span>
  <span > +</span>
  </Grid>

      </Grid>
      </Grid>
      <Grid sm={3} spacing={1} className='count_display' xs={6}>
        <Grid container>
          <Grid item sm={12} xs={12}>
<Typography style={{color:'white',fontWeight:'600',fontSize: '1.4rem'}}>
  HALO BUSINESS PROFILES
  </Typography>

  </Grid>
  <Grid className="count_name" style={{paddingTop:'19px'}} item sm={12} xs={12}>

  <span id="count4"  class="display-4"></span>
  <span > +</span>
  </Grid>

      </Grid>
      </Grid>

    </Grid>
                     




                     
                     
                     
                     
                     <div className="D6_Halo_WS_MainCont">
                        {/* <div className="D6_Halo_WS_PFHead">HOW IT WORKS !</div> */}
                        <div className="D6_Halo_WS_PFHead">Step into digital world</div>
                        <HorizontalLabelPositionBelowStepper />


     

                       
                    </div>
                   

                       
                    {/* </div> */}
                    
                </Grid>
                <Container className='cont_pricingW'>
            <Grid container>
            <Grid item  sm={4} xs={12}>
                <Paper  onClick={()=>Product('card')}  className={pricing=='1'?'cont_activeW':'cont_pricingW1'} elevation={6}>
<div>
<Typography  className={pricing=='1'?'text_head_active':'text_head'}>
   HALO CARDS
</Typography>
<ul className='ul_text'>
    <li className='li_text'>Personalise your own digital card</li>
    <li className='li_text' >All individual plans free for 30 days
</li>
    <li className='li_text' >Share with Email, Text & QR Code</li>
    <li className='li_text'>Enriched with social media enabled icons
</li>
    
    <li className='li_text'>Save digital card details to your contacts
</li>



 
    
</ul>
<div className='div_btn' style={{paddingTop:'45px'}}>
<Button variant="contained"   className={pricing=='1'?'btn_select_active':'btn_select'}>Get started</Button>
</div>
</div>


                </Paper>
            </Grid>
            <Grid item  sm={4} xs={12}>
            <Paper onClick={()=>Product('profile')} className={pricing=='2'?'cont_activeW':'cont_pricingW1'} elevation={6}>
            <div>
<Typography  className={pricing=='2'?'text_head_active':'text_head'}>
   HALO BUSINEE
</Typography>
<ul className='ul_text'>
    <li className='li_text'>List your products/services/categories</li>
    <li className='li_text' >Share with your network &amp; increase your followers</li>
    <li className='li_text'>Add your contact button (call or whatsapp)
</li>
    <li className='li_text' >Share with Email, Text &amp; QR Code
</li>
    <li className='li_text'>Update anytime instantly 
</li>
 
 
    
</ul>
<div className='div_btn'>
<Button variant="contained"   className={pricing=='2'?'btn_select_active':'btn_select'}>Get started</Button>
</div>
</div>

            </Paper>
            </Grid>
            <Grid item  sm={4} xs={12}>
            <Paper onClick={()=>Product('passes')} className={pricing=='3'?'cont_activeW':'cont_pricingW1'} elevation={6}>
            <div>
<Typography  className={pricing=='3'?'text_head_active':'text_head'}>
   HALO PASS
</Typography>
<ul className='ul_text'>
    <li className='li_text'>Create your own smart pass</li>
    <li className='li_text' >Used as subscriptions, memberships, offers & coupons</li>
    <li className='li_text'>Fully customisable digital passes
</li>
    <li className='li_text' >Generate single or multiple use passes
</li>
    <li className='li_text'>Manage your events seamlessly 
</li>
 
 
    
</ul>
<div className='div_btn'>
<Button variant="contained"   className={pricing=='3'?'btn_select_active':'btn_select'}>Get started</Button>
</div>
</div>

            </Paper>
            </Grid>
          
            </Grid>
        </Container>
       
            </Grid>
        </Container>
        
    );
};

export default D6_SL_WS;
const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 29,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,#e1163c 0%,#61126b 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);
const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 55,
      height: 55,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
      'linear-gradient( 95deg,#e1163c 0%,#61126b 50%,#e1163c 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
      'linear-gradient( 95deg,#e1163c 0%,#61126b 50%,#e1163c 100%)',
    },
  });
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    const icons = {
      1: <SettingsIcon   />,
      2: <GroupAddIcon   />,
      3: <Contactless  />,
    };
  
    return (
      <div  className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
        
      >
        {icons[String(props.icon)]}
      </div>
    );
  }


function getSteps() {
  return ['Select digital product', 'Create your product', 'Connect with your network digitally'];
}



 function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep] = React.useState(3);
  const steps = getSteps();



  return (
    <div >
      <Stepper  activeStep={activeStep}  connector={<ColorlibConnector />}alternativeLabel>
        {steps.map((label) => (
          <Step  key={label}>
            <StepLabel  StepIconComponent={ColorlibStepIcon} style={{color:'white'}}  >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    
    </div> 
  );
}