// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://www.stripe.com/docs/payments/integration-builder

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import "./styles1.css";
import {useCookies} from 'react-cookie'
import { Button, Grid, Paper } from "@material-ui/core";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@material-ui/core/TextField';

import Axios from "axios";
   
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';


// import Image from '../../assets/Images/HomeHair.png';
import Image1 from '../../assets/Images/cover1.jpg'
import { set } from "date-fns";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#fff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "15px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883"
      },
      "::placeholder": {
        color: "#fff"
      }
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee"
    }
  }
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#61126b"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

const CheckoutForm = (props) => {
  const[auth,setAuth]=useCookies(['auth'])

    const{pay,filter,plan,cat,live,payOption,discount,code,points,pointO,down,template,current}=props
  
  
    const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [error1, setError1] = useState(null);

  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const[success,setSuccess]=useState(null)
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
    address:'',
    city:'',
    stat:'',
    country:'',
    gst:''

  });

 

  const handleSuccess = (res) => {
    // separate key and values from the res object which is nothing but param_dict
    let keyArr = Object.keys(res);
    let valArr = Object.values(res);

    // when we start the payment verification we will hide our Product form
    document.getElementById("paymentFrm").style.display = "none";

    // Lets create a form by DOM manipulation
    // display messages as soon as payment starts
    let heading1 = document.createElement("h2");
    heading1.innerText = "Redirecting you to the paytm....";
    let heading2 = document.createElement("h2");
    heading2.innerText = "Please do not refresh your page....";

    //create a form that will send necessary details to the paytm
    let frm = document.createElement("form");
    // frm.action = "https://securegw-stage.paytm.in/order/process/";
    
    frm.action = "https://securegw.paytm.in/order/process/";
    
    frm.method = "post";
    frm.name = "paytmForm";

    // we have to pass all the credentials that we've got from param_dict
    keyArr.map((k, i) => {
      // create an input element
      let inp = document.createElement("input");
      inp.key = i;
      inp.type = "hidden";
      // input tag's name should be a key of param_dict
      inp.name = k;
      // input tag's value should be a value associated with the key that we are passing in inp.name
      inp.value = valArr[i];
      // append those all input tags in the form tag
      frm.appendChild(inp);
    });

    // append all the above tags into the body tag
    document.body.appendChild(heading1);
    document.body.appendChild(heading2);
    document.body.appendChild(frm);
    // finally submit that form
    frm.submit();
    setProcessing(false)

    // if you remember, the param_dict also has "'CALLBACK_URL': 'http://127.0.0.1:8000/api/handlepayment/'"
    // so as soon as Paytm gets the payment it will hit that callback URL with some response and
    // on the basis of that response we are displaying the "payment successful" or "failed" message
  };
// console.log(String(auth.auth.memberID))

  const startPayment = async (event) => {
    setProcessing(true)
    event.preventDefault();

    let bodyData = new FormData();

    bodyData.append("amount",discount?(
      (cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-(discount*0.01)*template.amount):filter[0].amount-(discount*0.01)*filter[0].amount)):pointO?(cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-code).toFixed(2):(filter[0].amount-code).toFixed(2)):cat==='halo pass'&&current&&!down&&template&&template.amount?template.amount:filter[0].amount);
      bodyData.append('tax',filter[0].taxRate)
      bodyData.append('email',billingDetails.email)
    bodyData.append('name',auth.auth.fname+auth.auth.lname)
    bodyData.append('cards',filter[0].cards)
    bodyData.append('memberID',String(auth.auth.memberID))

    bodyData.append('plan',plan)
    bodyData.append('cat',cat)
    bodyData.append('discount',discount?discount:'')
    bodyData.append('referal',code?code:'')
    bodyData.append('pointsUsed',pointO?'1':'')
    bodyData.append('addLine1',auth.auth.addLine1?auth.auth.addLine1:billingDetails.address)
    bodyData.append('city',auth.auth.city?auth.auth.city:billingDetails.city)
    bodyData.append('state',auth.auth.stat?auth.auth.stat:billingDetails.stat)
    bodyData.append('down',down?down:'')
    bodyData.append('current',current?current:'')


    await Axios({
      url: `${live}paytm`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: bodyData,
    }).then((res) => {
      // we will retrieve the param_dict that we are sending from the backend with
      // all the necessary credentials, and we will pass it to the handleSuccess() func 
     //  for the further process
      if (res) {
        handleSuccess(res.data.param_dict);
      }
    });
  };

// var amount= parseInt(filter[0].amount, 10)
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });


    if (payload.error) {
      setError(payload.error);
    } else {
        try {
            const {id} = payload.paymentMethod
            const formData=new FormData()
     formData.append('id',id)
    formData.append('email',billingDetails.email)
    // formData.append('amount',(discount?(filter[0].amount-(discount*0.01)*filter[0].amount):pointO?(filter[0].amount-code).toFixed(2):filter[0].amount)*100)
    // formData.append("amount",(discount?((cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-(discount*0.01)*template.amount):(filter[0].amount-(discount*0.01)*filter[0].amount))):pointO?(cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-code).toFixed(2):(filter[0].amount-code).toFixed(2)):cat==='halo pass'&&current&&!down&&template&&template.amount?template.amount:filter[0].amount))*100;
    formData.append("amount",((discount?(
      (cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-(discount*0.01)*template.amount):filter[0].amount-(discount*0.01)*filter[0].amount)):pointO?(cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-code).toFixed(2):(filter[0].amount-code).toFixed(2)):cat==='halo pass'&&current&&!down&&template&&template.amount?template.amount:filter[0].amount))*100);
    
    formData.append('currency',filter[0].countryCurrency)
    formData.append('name',auth.auth.fname+auth.auth.lname)
    formData.append('addLine1',auth.auth.addLine1?auth.auth.addLine1:billingDetails.address)
    formData.append('city',auth.auth.city?auth.auth.city:billingDetails.city)
    formData.append('state',auth.auth.stat?auth.auth.stat:billingDetails.stat)
  //  formData.append('addLine1',auth.auth.addLine1)
  //  formData.append('city',auth.auth.city)
  //  formData.append('state',auth.auth.stat)
   formData.append('country',auth.auth.country)
   formData.append('plan',plan)
   formData.append('cat',cat)




         fetch(live+"stripe", {
          // fetch("http://127.0.0.1:8000/stripe", {

                

                    method:'POST',
               
                    body:formData
                    })
                    .then(res=>res.json())
                    .then((resp)=>responseApi(resp,event))
                    .catch(error=>console.log(error))
                    const responseApi=(resp,event)=>{
                      if(resp.detail=='Success') {
                        handleRes(id,billingDetails)
                      }
                      if(resp.requiresAction){
                        handleSubmit1(resp.clientSecret,id,event)

                    
                }           
                if(resp.detail==='error'){
                  setError1(true)
                }
              
          
          }
                  } catch (error) {
                    
                      setError(payload.error);
          
                  }
          
              }
              const handleSubmit1 =async(clnt,id,event) => {
                event.preventDefault();
                setProcessing(true);
                // const payload1 = await stripe.confirmCardPayment(clnt);
                const payload1 = await stripe.confirmCardPayment(clnt, {

                  payment_method: {
                    card: elements.getElement(CardElement),
                  
                  } 
                });

                if (payload1.error) {
                  setProcessing(false);

                  setError1(true);
                } else {
                  if(payload1.paymentIntent.status==='succeeded')
                  {
                    setError(null);
                    setProcessing(false);
                    handleRes(id)
          
                  }
                  else{
                  setError1(true);
                  setProcessing(false); 
                  }
                
                }
              };
  
  const handleRes=(id,billingDetails)=>{

    const formData=new FormData()
    formData.append('stripeID',id)
    formData.append('memberID',auth.auth.memberID)

   formData.append('email',auth.auth.email)
   formData.append('name',auth.auth.fname+' '+auth.auth.lname)
   formData.append('phonenumber',auth.auth.phonenumber)
  //  formData.append('amount',discount?(filter[0].amount-(discount*0.01)*filter[0].amount):pointO?(filter[0].amount-code).toFixed(2):filter[0].amount)
  formData.append("amount",discount?(
    (cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-(discount*0.01)*template.amount):filter[0].amount-(discount*0.01)*filter[0].amount)):pointO?(cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-code).toFixed(2):(filter[0].amount-code).toFixed(2)):cat==='halo pass'&&current&&!down&&template&&template.amount?template.amount:filter[0].amount);
    formData.append('tax',filter[0].taxRate)

  formData.append('currency',filter[0].currencySymbol)
   formData.append('category',cat)
   formData.append('plan',plan)
   formData.append('state',auth.auth.stat?auth.auth.stat:billingDetails.stat)

   formData.append('discount',discount?discount:'')
   formData.append('referal',code?code:'')
   formData.append('pointsUsed',pointO?'1':'')














    fetch(live+"payment", {
      // fetch("http://127.0.0.1:8000/payment", {


      method:'POST',
 
      body:formData
      })
      .then(res=>res.json())
      .then((resp)=>responsePay(resp))
      .catch(error=>console.log(error))
 
  }};
  const responsePay=(resp)=>{
    props.stripeResponse(resp.data,billingDetails)
    setProcessing(true);

    setSuccess(true);
  }

  const reset = () => {
    setError(null);
    setError1(null);

    setProcessing(false);
    setSuccess(null);
    props.Reset1()
    
    setBillingDetails({
      email: "",
      phone: "",
      name: "",
   
    });
  };

  if(success){

  return (
      
    <div className="Result">
      <div className="ResultTitle" role="alert">
        Payment successful !
      </div>
       <div className="ResultMessage">
        Thank you for the payment, please create your Halo Digital card.
      </div> 
      <ResetButton onClick={reset} />
    </div>)
   } 
   
  if(error1){

    return (
        
      <div className="Result">
        <div className="ResultTitle" role="alert">
          Payment Failed !
        </div>
         <div className="ResultMessage">
          If amount is deducted from your account contact our support team
        </div> 
        <ResetButton onClick={reset} />
      </div>)
     } 
   else{
       return(
        <form id="paymentFrm" className="Form" onSubmit={payOption!='Paytm'?handleSubmit:startPayment}>
        <fieldset className="FormGroup">
          <Field
            label="Name"
            id="name"
            type="text"
            placeholder="Your name"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value });
            }}
          />
          
          <Field
          label="Email"
          id="email"
          type="email"
          placeholder="Your email"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value });
          }}
        />
      {filter[0].amount>50000&&!auth.auth.gstNumber?   <Field
          label="GST"
          id="gst"
          placeholder="Gst Number"

          type="text"
          
          required={filter[0].amount>50000?true:false}
          value={billingDetails.gst}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, gst: e.target.value });
          }}
        />:null}
        {!auth.auth.addLine1?<>
          <Field
          label="Address"
          id="address"
          type="text"
          // placeholder="Street No."
          required
          value={billingDetails.address}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, address: e.target.value });
          }}
        />
        <Field
          label="City"
          id="city"
          // placeholder="City"

          type="text"
          
          required
          value={billingDetails.city}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, city: e.target.value });
          }}
        />
        <Field
          label="State"
          id="state"
          type="text"
          // placeholder="State"
          required
          value={billingDetails.stat}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, stat: e.target.value });
          }}
        />
        <Field
          label="Country"
          id="country"
          type="text"
          // placeholder="Country"
          required
          value={billingDetails.country}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, country: e.target.value });
          }}
        /></>:null}
        </fieldset>
        
{payOption!='Paytm'?
        <fieldset className="FormGroup">
          <CardField
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </fieldset>:null}
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <SubmitButton processing={processing} error={error} disabled={!stripe ||processing}>
          Pay {filter[0].currencySymbol} {discount?(
    (cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-(discount*0.01)*template.amount):filter[0].amount-(discount*0.01)*filter[0].amount)):pointO?(cat==='halo pass'&&current&&!down&&template&&template.amount?(template.amount-code).toFixed(2):(filter[0].amount-code).toFixed(2)):cat==='halo pass'&&current&&!down&&template&&template.amount?template.amount:filter[0].amount}
         
        </SubmitButton>
        {/* {discount?(filter[0].amount-(discount*0.01)*filter[0].amount):pointO?(filter[0].amount-code).toFixed(2):filter[0].amount} */}
      </form>
    );
       
   }
   
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
    }
  ]
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//  const stripePromise = loadStripe("pk_test_51IuugOSDZi3rJzUveuAIXUfe50KzIQFC0tNIG4n5Tx8CXaBAM0gnqzqn6wnTK4jZrEu5MJLBegUJkr7pX6BU5PQB00zvykT7KB");
// const stripePromise = loadStripe("pk_test_51J8KKySIt0lqpguCZhfjd7bpmDtQ2cu4cji4Eauuw5zQc0bgcqhSaoPEmTbM9ZkHBnaskd8yph8ZrTqkeYsCslnv00IYdFYkqJ");
const stripePromise = loadStripe("pk_live_51J8KKySIt0lqpguCCs3GskWlwvB4bioO5dZqjsesRkhCloZTRCFWWBqdyzALUoeNOz6REwkgrR4WiKCTpClKf6dT00kGP03JqA");
const StripePay = (props) => {

    const{pay,filter,cat,plan,live,cardID,points,down,template,current}=props
    const [payOption, setOption] = useState('Card');
    const [web,setWeb]=useState('default')
    const[auth,setAuth]=useCookies(['auth'])
    const [success,setSuccess]=useState(false)
    const [processing,setProcessing]=useState(false)
    const [code,setCode]=useState('')
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [open1, setOpen1] = React.useState(false);
    const[appling,setAppling]=useState(false)
    const [discount,setDiscount]=useState('')
    const [discountOption,setDiscountOption]=useState('Referal Code')
    const [pointO,setPointO]=useState(false)

// console.log(filter[0].countryCurrency)
        const oneDay1 = 24 * 60 * 60 * 1000; 
const firstDate1= new Date(auth.createdDate);
const secondDate1 = new Date();

const diffDays1 = Math.round(Math.abs((secondDate1-firstDate1) / oneDay1));

    const stripeResponse=(value,billingDetails)=>{
      props.stripeResponse(value,billingDetails)
    }
    
   const freeTier=()=>{
     setProcessing(true)
         // fetch(' https://d6-halo.herokuapp.com/memInfo',
         fetch(live+'memInfo',

         {
         method: 'PUT',
         headers:{
         'Content-Type' :'application/json'  
         },
         body: JSON.stringify({memberID:auth.auth.memberID,planCategory:cat,planType:plan,cards:1,tier:'free'})
     })
     .then(res => res.json())
     .then((resp)=>updateRes(resp))
            
   } 
   const updateRes=(resp)=>{
     if(resp.data){
  
   setSuccess(true)
  //  alert(resp.detail)
   manageAuth(resp.data)
   setProcessing(false)

  }
  setProcessing(false)
   }
   const manageAuth=(data)=>{
    setAuth('auth',data,{
      maxAge:3600 ,
      expires:0
   })
   }
   const freeTierB=()=>{

    setProcessing(true)
    // fetch(' https://d6-halo.herokuapp.com/memInfo',
    fetch(live+'update',

    {
    method: 'POST',
    headers:{
    'Content-Type' :'application/json'  
    },
    body: JSON.stringify({
      cardID:cardID
    })
})
.then(res => res.json())
.then((resp)=>updateResB(resp))
   }
   const updateResB=(val)=>{
if(val.detail=='Success'){
  setSuccess(true)
  //  alert(resp.detail)
  
   setProcessing(false)
}
   }



   const checkReferal=()=>{
     if(code.length>0){
     setAppling(true)
    const formData=new FormData()
    formData.append('memberID',auth.auth.memberID)
    formData.append('referalcode',code)
    fetch(live+'referal',
    {
    method: 'POST',
   
    body:formData
})
.then(res => res.json())
.then((resp)=>updateResRef(resp))
   

     }
     else{
       setMsg('Referal code required')
       setOpen1(true)
     }
   }
  const updateResRef=(resp)=>{
if(resp.status){
  setMsg(resp.detail)
  setOpen(true)
  setAppling(false)
  setDiscount(resp.data)
}
else{
  setMsg(resp.detail)

  setOpen1(true)
  setAppling(false)
}
  }
  const handleClose=()=>{
    setOpen(false)
    setOpen1(false)
  }
  const  Code=(val)=>{
    if(!discount){
      setCode(val)
    }

  }
  const  Code1=(val)=>{
    if(!pointO){
      if(val>0){
      if(parseFloat(val)<=parseFloat(points.points)){
        if(parseFloat(val)<(cat==='halo pass'&&current&&!down&&template&&template.amount?template.amount:parseInt(filter[0].amount))){
          setOpen1(false)
setCode(val)
setMsg('')
        }
        else{
       
            setMsg('Redeem amount should be less than the amount')
            setOpen1(true)
      
        }

      }
      else{
        setMsg("Don't have enough points to redeem")
        setOpen1(true)
      }
    }
    else{
      setCode(val)
    }
  }
  }
  const DiscountOption=(val)=>{
    if(val=='Referal Code'){
      setCode('')
      setPointO(false)
      setDiscountOption(val)
    }
    else{
      setCode('')
      setDiscount('')
      setDiscountOption(val)

    }
  }
  const checkPoints=()=>{
    setPointO(true)
  }
  const Reset1=()=>{
    setDiscount('')
    setPointO(false)
    setCode('')
  }

  return (
    <Grid className='PricingCont'  container sm={12}>
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>{msg}</strong>
      </Alert>
      </Snackbar>
      <Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>{msg}</strong>
      </Alert>
      </Snackbar>
          <Grid  xs={12} item sm={7}>
            <Paper className='PricingPpr' elevation={6}>
              <div >
                {plan!='business'?
                <h1 className='PlanDH' >
                  Plan selected : {cat.toUpperCase()+'-'+plan.toUpperCase()}
                </h1>:<h1 className='PlanDH' >
                  {plan.toUpperCase()+' '+'PROFILE'}
                </h1>}
                {!success&&plan!='business'?
                <h1 className='Payoptions' >
                    Select a payment option
                </h1>:null}
                </div>

                {!success&&plan!='business'?
                 <FormControl component="fieldset">
                  
                 {/* <FormLabel component="legend"></FormLabel> */}
                 <RadioGroup row aria-label="Select a payment option" value={payOption} onChange={(e)=>setOption(e.target.value)} name="row-radio-buttons-group">
               {plan=='individual'&&(diffDays1<31||!diffDays1)&&!auth.auth.expiryData? <FormControlLabel value="Free tier" control={<Radio />} label="Free tier (30 days)" />:null}
                   <FormControlLabel value="Card" control={<Radio />} label="Card" />
                  { filter&&filter[0]&&filter[0].countryCurrency=='inr'?  <FormControlLabel value="Paytm" control={<Radio />} label="Scan & Pay" />:null}
                  
                 </RadioGroup>
               </FormControl>:null}


              
              {payOption=='Free tier'&&!success?

               <div style={{paddingTop:'50px'}} >
               <Button onClick={()=>freeTier()} variant='outlined' disabled={processing||success} className='btnPricing' >
                {processing?"Processing":"Proceed to buy"}
               </Button>
                </div>:null}
                <div>
                <h1 className='Payoptions' >
                    Select Discount option
                </h1>
                <FormControl component="fieldset1">
                  
                  <RadioGroup row aria-label="Select discount" value={discountOption}  onChange={(e)=>DiscountOption(e.target.value)} name="row-radio-buttons-group1">
                 <FormControlLabel  value="Referal Code" control={<Radio />} label="Referal Code" />
{         points&&points.points&&points.points>0?           <FormControlLabel value="Halo Points" control={<Radio />} label="Halo Points" />
:null}                   
                  </RadioGroup>
                </FormControl>
                </div>
                {payOption!='Free tier'&&!success&&discountOption=='Referal Code'?<>
                <Grid>
              <TextField  value={code}    onChange={(e)=>Code(e.target.value)} style={{width:'34%',marginTop:'10px'}} label='Referal code' />

              </Grid>

              {discountOption=='Referal Code'? 
              <Button style={{marginTop:'15px'}} onClick={()=>checkReferal()} disabled={discount} variant='outlined' className='btnPricing' >
                 {appling&&!discount?'APPLYING':discount?'APPLIED':'APPLY REFERAL CODE'}
               </Button>:null}
               </>:null}
               {payOption!='Free tier'&&!success&&discountOption=='Halo Points'?<>
                <Grid>
              <TextField  value={code}  type={'number'}  onChange={(e)=>Code1(e.target.value)} className='text_dis' style={{width:'34%',marginTop:'10px'}} label='Enter your halo points' />

              </Grid>

              {discountOption=='Halo Points'? 
              <Button style={{marginTop:'15px'}} onClick={()=>checkPoints()} disabled={discount} variant='outlined' className='btnPricing' >
                 {appling&&!discount?'APPLYING':discount?'APPLIED':'REDEEM POINTS'}
               </Button>:null}
               </>:null}
                {plan!='business'? <div style={{paddingTop:'50px'}} >
               <Button onClick={()=>props.PricingSc()} variant='outlined' className='btnPricing' >
                 Back to plans
               </Button>
                </div>:null}    
{!success&&plan=='business'?
                <h1 className='Payoptions' >
                    Select plan 
                </h1>:null}
                {!success&&plan=='business' ?
                 <FormControl component="fieldset">
                  
                 <RadioGroup row aria-label="Select web type" value={web} onChange={(e)=>setWeb(e.target.value)} name="row-radio-buttons-group1">
               <FormControlLabel value="default" control={<Radio />} label="Default" />
                   <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                  
                 </RadioGroup>
               </FormControl>:null}
                {plan=='business'&&web=='custom'?<div style={{paddingTop:'30px'}}>
                <Button  onClick={()=>window.open('mailto:connect@halodigital.app')} variant='outlined' className='btnPricing' >
                 Request a custom business profile
               </Button>
                </div>:plan=='business'&&web=='default'?<div style={{paddingTop:'50px'}} >
               <Button  variant='outlined' className='btnPricing' >
                Business profile demo
               </Button>
              
                </div>:null}
                {plan=='business'&&web!='custom'&&!success?<div style={{paddingTop:'30px'}}>
                <Button  variant='outlined' onClick={()=>freeTierB()} className='btnPricing' >
                 {processing?"Purchasing":"Purchase free tier (30 Days) "}
               </Button>
                </div>:null}
                {plan=='business'&&web!='custom'&&success?<div style={{paddingTop:'30px'}}>
                <Button  variant='outlined' onClick={()=>props.createB()} className='btnPricing' >
Create Business               </Button>
                </div>:null}
                
            </Paper>
{/* <img src={Image1} alt="Cls2" /> */}
</Grid>
{payOption!='Free tier'&&plan!='business'?
    <Grid item  xs={12}sm={5}>
<Paper className='PlanDH' elevation={6}>
    <div className="AppWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm payOption={payOption} Reset1={Reset1} pointO={pointO} down={down} template={template} current={current} points={points} code={code}  discount={discount} live={live} cat={cat} plan={plan} stripeResponse={stripeResponse} filter={filter} pay={pay} />
      </Elements>

    </div>
    </Paper>
    </Grid>:null}
    {(payOption=='Free tier'||web!='custom')&&success?
    <Grid item  className="freetier-grid"  xs={12}sm={5}>
<Paper className='PlanDH1' elevation={6}>
<div className="freetier-result">
      <div className="ResultTitle" role="alert">
        Free-tier  purchase successful !
      </div>
     {web!='custom'?<div className="ResultMessage">
        Thank you for business profile selection, please create your business.
      </div>:<div className="ResultMessage">
        Thank you for plan selection, please create your Halo Digital card.
      </div> }  
    </div>
    </Paper>
    </Grid>:null}
    </Grid>
  );
};

export default StripePay;
