import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './D6_SL_Menu';
import '../../../MainCSS.css'
import { D6_sl_MenuList } from './D6_sl_MenuList';
import D6_SL_Menu from './D6_SL_Menu';
import TemporaryDrawer from './D6_Sl_Drawer';

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 32px;
  right: 20px;
  right: 20px;
  z-index: 20;
  display: none;
  cursor:pointer;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  div {
    width: 2rem;
    height: 2px;  
    background-color: ${({ open }) => open ? 'var(--ycolor)' : 'white'};
    border-radius: 10px;  
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;


const D6_SL_Hamburger = (props) => {
  const {live}=props
  const [open, setOpen] = useState(false)
  const country=(value)=>{
    props.country(value)
  }
  const Toggle=()=>{
    setOpen(!open)
  }
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div className="HBLine1"/>
        <div className="HBLine2"/>
    
        <div className="HBLine3"/>
        <TemporaryDrawer Toggle={Toggle} open={open}  />
        {/* <D6_SL_Menu open={open} live={live} country={country}/> */}
      </StyledBurger>
      <TemporaryDrawer Toggle={Toggle} open={open}  />

      {/* <D6_SL_Menu   live={live} country={country}/> */}
      
    

    
    </>
    
  )
}
export default D6_SL_Hamburger