import React from 'react'
import Button from '@mui/material/Button';
import './D6_DASAC_Form_CSS.css'

import {Container,Paper, SvgIcon} from '@mui/material'

import axios from 'axios'
import ReactPhoneInput from "react-phone-input-2";
 import 'react-phone-input-2/lib/style.css' 
 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';



 

const  D6_DASAC_Form =(props)=> {
  const ref=React.useRef()
  const {all,value,active}=props

  const [data,setData]=React.useState({
    section1:props.template.para1?props.template.para1:'',
    section2:props.template.para2?props.template.para2:'',
    section3:props.template.para3?props.template.para3:'',
    section4:props.template.para4?props.template.para4:''

})
const [processing,setProcessing]=React.useState(false)
const [submitted,setSubmitted]=React.useState(false)
const handleSubmit=()=>{

  setProcessing(true)

  if(all){
    const formData=new FormData
    formData.append('template',props.template.name)
    
    formData.append('template',props.template.name)
    formData.append('section1',data.section1)
    formData.append('section2',data.section2)
    formData.append('section3',data.section3)
    formData.append('section4',data.section4)
    formData.append('memberID',active==='Member ID'&&value?value:'')
    formData.append('fname',active==='First Name'&&value?value:'')
    formData.append('email',active==='Email'&&value?value:'')
    formData.append('planCategory',active==='Plan Category'&&value?value:'')
    formData.append('planType',active==='planType'&&value?value:'')
    formData.append('proPlan',active==='Pro Plan'&&value?value:'')
    formData.append('createdDate',active==='Created Date'&&value?value:'')
    formData.append('expiaryTime',active==='Expiary date'&&value?value:'')
    formData.append('phonenumber',active==='Phone Number'&&value?value:'')
    axios.put(`${props.live}custom-update`, formData)
    .then(response =>MailRes(response.detail))    
  }
  else{
    const formData=new FormData
    formData.append('memberID',props.member)
    formData.append('template',props.template.name)
    formData.append('section1',data.section1)
    formData.append('section2',data.section2)
    formData.append('section3',data.section3)
    formData.append('section4',data.section4)
 

    axios.post(`${props.live}custom-update`, formData)
    .then(response =>MailRes(response.detail))    }      }

    const MailRes=(val)=>{
      setProcessing(false)
setSubmitted(true)
// setData({
// ...data,name:'',email:'',phone:'',message:''
// })
    }
    return (
    <Container className="A_Form_Container">
    <Paper elevation={0} className="A_Form_Cont_Paper">
        <div className="A_Form_Div">

            <div className="Floating_Form">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit}
            >
            

            <div className="TF_div">
              <div>
                <label className="TF_Label">Section1</label>
              </div>
                
               <TextValidator 
               multiline
               maxRows={2}
               
                        name='message'
                        onChange={(e)=>setData({...data,
                            section1:e.target.value
                        })}       value={data.section1}
                    /> 
              </div>
              <div className="TF_div">
              <div>
                <label className="TF_Label">Section2</label>
              </div>
                
               <TextValidator 
               multiline
               maxRows={2}
               
                        name='message2'
                        onChange={(e)=>setData({...data,
                            section2:e.target.value
                        })}       value={data.section2}
                    /> 
              </div>
              <div className="TF_div">
              <div>
                <label className="TF_Label">Section3</label>
              </div>
                
               <TextValidator 
               multiline
               maxRows={2}
               
                        name='message3'
                        onChange={(e)=>setData({...data,
                            section3:e.target.value
                        })}       value={data.section3}
                    /> 
              </div>
              <div className="TF_div">
              <div>
                <label className="TF_Label">Section4</label>
              </div>
                
               <TextValidator 
               multiline
               maxRows={2}
               
                        name='message4'
                        onChange={(e)=>setData({...data,
                            section4:e.target.value
                        })}       value={data.section4}
                    /> 
              </div>
              
              <div className="Button_UL"></div>

                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted}
                >
                    {
                    submitted&&!processing?'Submitted':processing?'Submitting':'Send mail'
                    }
                </Button>       
        </ValidatorForm>
        </div>
    </div>
    </Paper>
    </Container>
  
    )
    }


export default D6_DASAC_Form
