import React from 'react'
import Button from '@mui/material/Button';
import './D6_DASAC_Form_CSS.css'

import {Container,Paper, SvgIcon} from '@mui/material'

import axios from 'axios'
import ReactPhoneInput from "react-phone-input-2";
 import 'react-phone-input-2/lib/style.css' 
 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';



 

const  D6_DASAC_Form_planUpdate =(props)=> {
  const ref=React.useRef()
  const [data,setData]=React.useState({
    transactionID:'',
    planCategory:'',
    planType:'',
    amount:'',
    expiryDate:'',
    createdDate:'',
    currency:''

})
const [processing,setProcessing]=React.useState(false)
const [submitted,setSubmitted]=React.useState(false)
const handleSubmit=()=>{
  setProcessing(true)
    const formData=new FormData
    formData.append('memberID',props.member)
    formData.append('template',props.template.name)
    formData.append('transactionID',data.transactionID)
    formData.append('planCategory',data.planCategory)
    formData.append('planType',data.planType)
    formData.append('amount',data.amount)
    formData.append('currency',data.currency)
    formData.append('createdDate',data.createdDate)
    formData.append('expiryDate',data.expiryDate)

    axios.post(`${props.live}custom-update`, formData)
    .then(response =>MailRes(response.detail))          }

    const MailRes=(val)=>{
      setProcessing(false)
setSubmitted(true)
setData({
...data,transactionID:'',planCategory:'',planType:'',amount:'',expiryDate:'',createdDate:'',currency:''
})
    }
    return (
    <Container className="A_Form_Container">
    <Paper elevation={0} className="A_Form_Cont_Paper">
        <div className="A_Form_Div">

            <div className="Floating_Form">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit}
            >
            <div className="TF_div">
                <label className="TF_Label">Transaction ID</label>
                <TextValidator
                    label=""
                    onChange={(e)=>setData({...data,
                      transactionID:e.target.value
                  })}
                  name="transactionID"
                  value={data.transactionID}
                    validators={['required']}
                    errorMessages={['Transaction id required']}  
                />  
            </div>
            <div className="TF_div">
              <label className="TF_Label">Plan Category</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        planCategory:e.target.value
                    })}  name="planCategory"
                      value={data.planCategory}
                      validators={['required']}
                      errorMessages={['Plan Category required']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">Plan Type</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        planType:e.target.value
                    })}  name="plantype"
                      value={data.planType}
                      validators={['required']}
                      errorMessages={['Plan type required']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">Created Date</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        createdDate:e.target.value
                    })}  name="date"
                      value={data.createdDate}
                      validators={['required']}
                      errorMessages={['Expiry date required']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">Expiry Date</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        expiryDate:e.target.value
                    })}  name="date"
                      value={data.expiryDate}
                      validators={['required']}
                      errorMessages={['Created date required']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">Amount</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        amount:e.target.value
                    })}  name="number"
                      value={data.amount}
                      validators={['required']}
                      errorMessages={['Amount required']}
                  />
            </div>
            <div className="TF_div">
              <label className="TF_Label">Currency</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        currency:e.target.value
                    })}  name="number"
                      value={data.currency}
                      validators={['required']}
                      errorMessages={['Currency required']}
                  />
            </div>
            

            
              
              <div className="Button_UL"></div>

                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted}
                >
                    {
                    submitted&&!processing?'Submitted':submitted&&processing?'Submitting':'Send mail'
                    }
                </Button>       
        </ValidatorForm>
        </div>
    </div>
    </Paper>
    </Container>
  
    )
    }


export default D6_DASAC_Form_planUpdate
