import React, { useState ,useEffect} from 'react';
import {useCookies} from 'react-cookie'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import {Link, Grid, Box} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    'search-box-wrapper': {
      backgroundColor: '#ffffff',
      height: '48px',
      borderRadius: '4px',
      // minWidth: '130px',
      width: '100%',
      border: '1px solid rgb(97, 18, 107)',
      display: 'flex',
      paddingLeft: '10px',
      justifyContent: 'space-between',
      margin: '0px auto 10px ',
      // position: 'absolute',
      // top:'20%',
      // left: '50%',
      // marginLeft: '-350px',
    },
    'search-field': {
      height: '46px',
      width: 'calc(100% - 130px)',
      display: 'flex',
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
  }));

export default function AddressBook(props) {
  const{live}=props
    const[auth]=useCookies(['auth'])
    const[filter,setFilter]=useState([])
    const[filter1,setFilter1]=useState([])
    const [deleting,setDelete]=useState(false)
    const [err,setError]=useState(false)
    const [msg,setMsg]=useState('Deleting...')
    const [load,setLoad]=useState(null)
    const [status,setStatus]=useState(false)

const  cardLink='https://halodigital.app/info?card='
    useEffect(() => {
        if(!auth.auth){
          window.location.href='/signin'
        }
         }, [auth.auth])
    useEffect(() => {
        if(auth.auth){
        //  fetch(live+`add-list?q=${auth.auth.memberID}`,
        fetch(live+`add-list?q=${auth.auth.memberID}`,

        { method: 'GET',
        headers:{
          'Content-Type' :'application/json'  
        }})
        //.then(resp=>console.log(resp))

        .then(res => res.json())
        .then((resp)=>Filter(resp))
     } }, [auth.auth,load])
     const Filter =(resp)=>{
       setFilter(resp)
       setFilter1(resp)
       setStatus(true)
     }
     const setSearchBar=(val)=>{if(val){
      setFilter1(filter.sort((a, b) => a.dcardID < b.dcardID ? 1 : -1).filter((count)=>
      (count.name.toLowerCase().includes(val.toLowerCase())
      // ||

      // (count.userEmail.toLowerCase().includes(val.toLowerCase())
      // ||(count.phonenumber.toString().includes(val.toLowerCase())
      //  ||(count.organisation.includes(val.toLowerCase()))
      // ))
      
      
      )))
    }
    else{
      setFilter1(filter)
    }

    }

        //   fetch('https://d6-halolive.herokuapp.com/create',
           

  const DeleteCon=(id)=>{
    setDelete(true)
    const formData=new FormData()
    formData.append('id',id)    
    fetch(live+'addmember'

    ,
      {
  
        method:'DELETE',
   
        body:formData
        })
        .then(res=> res.json())
        .then((jsonRes)=>delRes(jsonRes))

        
      
        .catch(error=>console.log(error))


  }
  const delRes=(resp)=>{
    if(resp.detail){
setMsg(resp.detail) 
Load()   }
    else{
      setError(true)
    }
  }
  const Load=()=>{
    setDelete(false)
    setMsg('Deleting ..')
    if(!load){
      setLoad(auth.auth.cards+1)

    }
    else{
      setLoad(load+1)

    }
  }
  
  const classes = useStyles();
  if(auth.auth&&status&&filter[0]){

  return (<>
      <div className={classes.root}>
      <Snackbar  open={err}  anchorOrigin={{vertical:'top',horizontal:'center'}} onClose={()=>setError(false)}>
  <Alert   severity="warning">
Error in deleting connection    </Alert>
</Snackbar>
<Snackbar  open={deleting}  anchorOrigin={{vertical:'top',horizontal:'center'}} onClose={()=>setDelete(false)}>
  <Alert   severity="info">
{msg}   </Alert>
</Snackbar>
      <div >
                    <Grid item xs={12} className={classes['search-box-wrapper']}>
                        <input type="text" placeholder='Search your Halo Connections' onChange={(e)=>setSearchBar(e.target.value)} className={classes['search-field']} />
                    </Grid>
                </div>
      {filter1.sort((a, b) => a.ID < b.ID ? 1 : -1).map((fil,idx)=>{
          return(
            <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Name: {fil.name}</Typography>
            </AccordionSummary>
            {/* <AccordionDetails>
              <Typography>
               Name: {fil.name}
              </Typography>
           
            </AccordionDetails> */}
            {fil.userEmail?
            <AccordionDetails>
            
              <Typography onClick={()=>window.open(`mailto:${fil.userEmail}`)} style={{cursor:'pointer'}}>
               Email: {fil.userEmail}
              </Typography>
            
            </AccordionDetails>:null}
            {fil.phonenumber?
            <AccordionDetails>
             
              <Typography  onClick={()=>window.location.href=`tel:${fil.phonenumber}`}style={{cursor:'pointer'}}>
               Phone: { fil.phonenumber} 
              </Typography>
           
            </AccordionDetails>:null}
            {fil.designation?
            <AccordionDetails>
            <Typography>
               Designation: {fil.designation} 
              </Typography>
            </AccordionDetails>:null}
            {fil.organisation?
            <AccordionDetails>

            <Typography>
               Organisation: {fil.organisation} 
              </Typography>
            </AccordionDetails>:null}
            <AccordionDetails>
            <Typography>
              <Grid container>
                <Grid >
            <Link style={{color:'#61126b',fontWeight:'bold'}} href={cardLink+fil.key} target='_blank'>
                     View Card              
                    </Link>
                    </Grid>
                    <Grid onClick={()=>DeleteCon(fil.ID)} style={{color:'#e1163c',fontWeight:'bold',marginLeft:'30px',cursor:'pointer'}}> 
                        Delete Connection
                    </Grid>

                    </Grid>
</Typography>
            </AccordionDetails>
          </Accordion>
          )
      })}

     
</div>
      </>
  )}
  if(!status){
  
    return(   
        <Box sx={{ display: 'flex',justifyContent:'center' ,paddingTop:'20px'}}>
        <CircularProgress />
      </Box>)
  
  }
  if(status&&!filter[0]){
  
    return(   
      <div>          
      <h1 style={{fontWeight:'bold',textAlign:'center',marginTop:'25px'}}>No Connections Added</h1>
      </div>)
  
  }
}