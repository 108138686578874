import React, { useState ,useEffect} from 'react';
import {useCookies} from 'react-cookie'
import { makeStyles } from '@material-ui/core/styles';

import { Grid ,Button, Box, Chip, TextField,Typography,Dialog} from '@material-ui/core';
import {DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import './profile.css'
import { Accordion,AccordionSummary,AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@mui/material/Menu';
import Halo_Pass_Template from '../HomePage/Home_WelcomeSection/Halo_Pass';
import Halo_Pass_Preview from '../HomePage/Home_WelcomeSection/Halo_pass_preview';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
export default function Halo_Pass(props){
    const [value, setValue] = React.useState(0);

    return(
        <div>
<Grid  item sm={12} xs={12}>
                                <AppBar style={{paddingTop:'20px'}} position="static" className="D6_PreviewAppBarPlt">
                    <Tabs value={value} aria-label="tabs">
                    <Tab label="Halo Pass template" {...a11yProps(0)} />
                    {/* <Tab label="Business" {...a11yProps(1)} /> */}
                    </Tabs>
                </AppBar>
                        </Grid>

                        <Grid  item sm={12} xs={12}>
                        <TabPanel value={value} index={0}>
                            
   <Grid item sm={12} xs={12}>
       <Halo_Pass_Preview live={props.live}/>

       </Grid>
 
              </TabPanel>
                        </Grid>
        </div>
    )
}