import React,{useState,useEffect} from 'react';
import D6_halo_cardclsweb from '../ContactPage/ClsCard/D6_halo_cardclsweb';
import D6_halo_cardpltweb from '../ContactPage/PltCard/D6_halo_cardpltweb';
import D6_halo_cardsigweb from '../ContactPage/SigCard/D6_halo_cardsigweb';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import {Link} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {useCookies} from 'react-cookie'

import './info.css'
// import FileSaver from 'file-saver'
import vCardsJS from 'vcards-js'
// import infofile from './info.vcf'
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Info(props) {

  const[auth]=useCookies(['auth'])
const [adding,setAdding]=useState(false)
  const{live}=props
  const [open,setOpen] = React.useState(false);
  const [openP,setOpenP] = React.useState(props.openP?props.openP:false);

  const [added,setAdded]=useState(false)
  const [error,setError]=useState(false)
  const [resp,setRes]=useState(false)
const [business,setBusiness]=useState(props.business?props.business:'')

    const handleClose = () => {
      setOpen(false);
    };
  // const {name,email,designation,organization,web,mobile,image}=props
  const [copy,setCopy]=useState(false)

  const copyToClipboard = (valu) => {
    setCopy(true)

   const el = document.createElement('textarea');
   el.value =valu;
   document.body.appendChild(el);
   el.select();
   document.execCommand('copy');
   document.body.removeChild(el);


 };
  let search = window.location.search;
  
  let params = new URLSearchParams(search);
  let foo = params.get('card');

    const[card,setCard]=useState(props.card?props.card:'')
    const[query,setQuery]=useState(foo)
    const[blob1,setBlob]=useState(null)
    const [otp,setOtp]=useState('')
    // const [email,setEmail]=useState('')
const [label,setLabel]=useState(null)

    // useEffect(() => {

    // }, [])

    const addContact=()=>{
      setAdding(true)
      const formData=new FormData()
      formData.append('name',(card.fname?(card.fname):'')+' '+(card.lastname?card.lastname:''))
      formData.append('organisation',card.organisation?card.organisation:'')
      formData.append('designation',card.organisation_tittle)
      formData.append('email',card.email)
      formData.append('phone',card.work_phone)
      formData.append('key',card.key)
      formData.append('member',auth.auth.memberID)




     
    
          
    
    
              fetch(live+'addmember'
                  // fetch('http://127.0.0.1:8000/addmember'

              ,
                {
            
                  method:'POST',
             
                  body:formData
                  })
                  .then(res=> res.json())
                  .then((jsonRes)=>addRes(jsonRes))
    
                  
                
                  .catch(error=>console.log(error))
    }
    const addRes=(resp)=>{
      if(resp.detail)
      {
        setAdding(false)
        setRes(resp.detail)
        setAdded(true)
        
      }
      else{
        setAdding(false)

        setAdded(false)
        setError(true)
      }
    }

    const generateVCF=()=>{
      var vCard = vCardsJS();
      vCard.firstName = card.fname;
      vCard.lastName = card.lastname;
      vCard.organization = card.organisation;
      vCard.workPhone = card.work_phone;
      vCard.homePhone=card.home_phone
      vCard.title = card.organisation_tittle
      vCard.url =`https://halodigital.app/${card.username?card.username:card.key}`
      vCard.photo.attachFromUrl(`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/profile/${card.pname}`, 'JPEG');

      vCard.email = card.email
      // vCard.workEmail = card.work_email
      vCard.workAddress.label = card.location?card.location:null
      
       vCard.workAddress.street = card.location?card.location:null
       vCard.workUrl=card.web
      // vCard.workAddress.city =card.address_city
      // vCard.workAddress.countryRegion = card.address_country 
     
      
      vCard.version = '3.0'; 
      // console.log(vCard.getFormattedString())
      
      const blob = new Blob([ vCard.getFormattedString() ], {type: "text/vcard"});
      setBlob(URL.createObjectURL(blob))
   
      // console.log(blob1.blob)
     // FileSaver.saveAs(blob, "./dcard.vcf");
      
         }
 useEffect(()=>{

   if(query){
  const formData=new FormData()
  formData.append('did',query)
 
          

           fetch(live+'create'
          ,
            {
        
              method:'PUT',
         
              body:formData
              })
              .then(resp=> resp.json())
              .then((jsonRes)=>handleRes(jsonRes))

              
            
              .catch(error=>console.log(error))



            }
            },[])
            

            const handleRes=(jsonRes)=>{
              if(jsonRes.detail==='Private DigiCard'){
                setCard(jsonRes.data.card)
                setBusiness(jsonRes.data.business)
                setOpenP(true)
                // setEmail(jsonRes.data.email)


              }
              if(jsonRes.detail==='DigiCard'){
                setCard(jsonRes.data)
                setBusiness(jsonRes.business)

              }
            
              if(!jsonRes.data){
                setQuery(null)
              }
            }



        const privateClicked=()=>{
          setAdding(true)

          fetch(live+'verifyP',
          {
          method: 'post',
          headers:{
          'Content-Type' :'application/json'
          },
          body: JSON.stringify({dcardID:card.dcardID,passwd:otp})
          })
          .then(res => res.json())
          .then((jsonRes)=>otpRes(jsonRes))

              
            
          .catch(error=>console.log(error))

        }    
        const otpRes=(res)=>{
          setAdding(false)
          if(res.detail==='Confirmed successfully'){
           setOpenP(false)
        }
        else{
          setLabel(res.detail)
        }}
           
if (query||card){
  if(!openP){
  return (
    <>

  
      <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'5px',marginBottom:'5px'}}>
    {
        card.planCategory=='classic'?
        <D6_halo_cardclsweb card={card.card} name={card.fname+' '+card.lastname} email={card.email} designation={card.organisation_tittle} 
        organization={card.organisation} web={card.web} mobile={card.work_phone} mobile1={card.home_phone} image1={card.pname}
        facebook={card.facebook} instagram={card.instagram}
        whatsapp={card.whatsapp} linkedIn={card.linkedIn}twitter={card.twitter}gitHub={card.github}youTube={card.youtube}
        telegram={card.telegram} googleMeet={card.googleMeet} key1={card.key} username={card.username} business={business}/>:
        card.planCategory=='signature'?
        <D6_halo_cardsigweb card={card.card} location={card.location} name={card.fname+' '+card.lastname} email={card.email} designation={card.organisation_tittle} 
        organization={card.organisation} web={card.web} mobile={card.work_phone} mobile1={card.home_phone} image1={card.pname} 
        facebook={card.facebook} instagram={card.instagram} key1={card.dcard_url} headline={card.headline}
        whatsapp={card.whatsapp} linkedIn={card.linkedIn}twitter={card.twitter}gitHub={card.github}youTube={card.youtube}
        telegram={card.telegram} googleMeet={card.googleMeet} username={card.username}/>:
         card.planCategory=='platinum'?

        <D6_halo_cardpltweb  card={card.card} location={card.location} name={card.fname+' '+card.lastname} email={card.email} designation={card.organisation_tittle} 
        organization={card.organisation} web={card.web} mobile={card.work_phone} mobile1={card.home_phone} image1={card.pname}
        facebook={card.facebook} instagram={card.instagram} color1={card.color1} image4={card.logoname}  stat={card.imgVal}
        headline={card.headline} username={card.username}
        whatsapp={card.whatsapp} linkedIn={card.linkedIn}twitter={card.twitter}gitHub={card.github}youTube={card.youtube} business={business}
        telegram={card.telegram} googleMeet={card.googleMeet} snapChat={card.snapChat} key1={card.key} sun={card.sunHrs} mon={card.monHrs}tue={card.tuesHrs}wed={card.wedHrs}thur={card.thusHrs}fri={card.friHrs} sat={card.satHrs}
        pay1={card.pay1} pay1Value={card.pay1Value} pay2={card.pay2} pay2Value={card.pay2Value}pay3={card.pay3} pay3Value={card.pay3Value} note={card.note} color1={card.color1} color2={card.color2} colorT={card.colorT} 
        pay1ValueN={card.pay1ValueN} pay2ValueN={card.pay2ValueN}  pay3ValueN={card.pay3ValueN}/>
       :null }{card.card?

       
        // <Button   download={blob1?blob1:null} onClick={generateVCF}className='D6_PreviewButton1' variant="contained" >
      <>
        <Button className="D6_Info_Button"
      
        variant="contained"
        color="primary"
        disableElevation
        // className={classes["ic-btn"]}
      >
       <a  href={blob1?blob1:null} onClick={generateVCF} className='fas fa-download' variant="contained" >
       <a href={blob1?blob1:null} onClick={generateVCF} className="D6_Info_ButtonText"> Save Contact</a> 
        </a>
    </Button>
    {copy?<>
            <Snackbar  open={copy} autoHideDuration={2000} anchorOrigin={{vertical:'top',horizontal:'center'}} onClose={()=>setCopy(false)}>
  <Alert   severity="info">
    Card URL copied
    </Alert>
</Snackbar>
          </>:null}
          {added?<>
            <Snackbar  open={added} autoHideDuration={2000} anchorOrigin={{vertical:'top',horizontal:'center'}} onClose={()=>setAdded(false)}>
  <Alert   severity="success">
{resp}</Alert>
</Snackbar>
          </>:null}  {error?<>
            <Snackbar  open={error} autoHideDuration={2000} anchorOrigin={{vertical:'top',horizontal:'center'}} onClose={()=>setError(false)}>
  <Alert   severity="warning">
    Unable to add connection
    </Alert>
</Snackbar>
          </>:null}
          {auth.auth?
          <Grid style={{textAlign:'-webkit-right'}} item xs={6}>
    <Button  onClick={()=>addContact()} className="D6_Info_Button D6_Info_ButtonText"
        variant="contained"
        color="primary"
        disableElevation
        // className={classes["ic-btn"]}
      >
{!adding?'add to connections':'connecting'}    </Button>    
    </Grid>:null}
    <Grid container   xs={12}>
      <Grid style={{textAlign:'-webkit-right'}} item xs={6}>
    <Button  onClick={()=>setOpen(true)} className="D6_Info_Button D6_Info_ButtonText"
        variant="contained"
        color="primary"
        disableElevation
        // className={classes["ic-btn"]}
      >
      Share via Email
    </Button>    
    </Grid>
    <Grid style={{marginLeft:'2px'}} spacing={1} xs={5}>
    <Button  onClick={()=>copyToClipboard(`https://halodigital.app/${card.username?card.username:card.key}`)}  className="D6_Info_Button D6_Info_ButtonText"
        variant="contained"
        color="primary"
        disableElevation
        // className={classes["ic-btn"]}
      >
Copy card url    
</Button>    
      </Grid>

    </Grid>

    {!card.custom?<div className="D6_Info_NewCard">  
                    <Link className="D6_Info_NewCardLinkSignup" href="https://halodigital.app/signin" target='_blank'>
                        Sign up &amp; create your own digital card
                    </Link>
                </div>:null}
    <div className="D6_Info_NewCard">  
                    <Link className="D6_Info_NewCardLinkSignup" href="https://halodigital.app" target='_blank'>
                        &#169;2021 Dec6 Consulting Private Limited
                    </Link>
                </div>
        
        </>
        :null}
        <Dialog
      disableScrollLock={ true }
      open={open}
      onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <Share message={card}  live={live} name={card.fname} handleClose={handleClose}/>
    </Dialog>

  

               </div>

       
        </>
  );}
  else{
    return(
    <>
      <Dialog
      disableScrollLock={ true }
      open={openP}
      // onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
 <div >
   <div style={{margin:20}}>
   <h2>You are accessing a private card ! </h2>< br />
   <h4>Please contact card owner for Security PIN</h4>
   </div>
            <Grid container style={{marginLeft:20}} spacing={0} xs={12} sm={10} >
            <ValidatorForm
              //  useRef="form"
                onSubmit={privateClicked}

            >
              <Grid  item xs={12}>

              <TextValidator
                      label="Security Pin"

                      onChange={(e)=>setOtp(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='number'

                      value={otp}     
                      validators={['required']}
                      errorMessages={['Security pin is required']}
                  />

         

                </Grid>
                {label?<div style={{color:'red',fontSize:14}}>{label}</div>:null}

                <Grid  style={{margin:'20px'}}item xs={12}>
          
                  <Button type='submit' className="D6_Info_Button">
                  {/* { !send?' Send':'sending'} */}
                  {adding?'verifying':'Verify'}
                  </Button>
                  
  </Grid>
                  

                  </ValidatorForm>
              </Grid>

    </div>    </Dialog>
    </>)
  }
      }
      else{
        return(window.location.href='/')
      }
}

function Share(props){
  const{live}=props
  const [email,setEmail]=useState('')
  const [send,setSending]=useState(false)

const handleSubmit=()=>{
  const msg=`https://halodigital.app/${props.message.username?props.message.username:props.message.key}`
  setSending(true)
  const formData=new FormData()
  formData.append('email',email)
  formData.append('msg',msg)
  formData.append('name',props.name)


  {


    fetch(live+'share'
    ,
      {
  
        method:'POST',
   
        body:formData
        })
        .then(res=> res.json())
        .then((jsonRes)=>SendRes(jsonRes))

        
      
        .catch(error=>console.log(error))
      
      }
      const SendRes=(resp)=>{
        if(resp.detail==='Mail Sent')
        {
          props.handleClose()
        }
      }

}
return(
  <div >
            <Grid container spacing={0} xs={12} >
            <ValidatorForm
              //  useRef="form"
                onSubmit={handleSubmit}
               
            >
              <Grid  style={{margin:'20px'}}item xs={12}>
          
              <TextValidator
                      label="Email"

                      onChange={(e)=>setEmail(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      type='email'

                      value={email}     
                      validators={['required','isEmail']}
                      errorMessages={['Email  is required']}
                  />

         

                </Grid>
                <Grid  style={{margin:'20px'}}item xs={12}>
          
                  <Button type='submit' className="D6_Info_Button">
                  { !send?' Send':'sending'}
                  </Button>
                  
  </Grid>
                  

                  </ValidatorForm>
              </Grid>

    </div>
)


}