import React ,{useState}from 'react';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

import Grid from '@material-ui/core/Grid';
import Image from '../../../assets/Images/HomeHair.png';
import Image1 from '../../../assets/Images/cover.jpg';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import './D6_halo_cardclsweb2H.css';
import Avatar from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import Loc from '@material-ui/icons/LocationOnOutlined';
import HomePh from '@material-ui/icons/PhoneIphoneOutlined';
import WorkPh from '@material-ui/icons/PhoneInTalkOutlined';
import WorkEmail from '@material-ui/icons/EmailOutlined';
import Website from '@material-ui/icons/LanguageOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { QRCode } from 'react-qrcode-logo';


export default function CardClsweb2H(props) {
  const {name,email,designation,organization,web,mobile,mobile1,image,facebook,instagram,whatsapp,linkedIn,twitter,gitHub,youTube,telegram
    ,googleMeet,sun,location,mon,tue,wed,thur,fri,sat,headline,image3,pay1,pay2,pay3,pay1Value,pay2Value,pay3Value,note,
  stat}=props
    const [active,setActive]=useState(mobile?'m1':mobile1?'m2':'m1')

    const [active1,setActive1]=useState('mon')

    const handleClose = () => {
      setOpen1(false);
      setOpen2(false)




    };
    const [open2, setOpen2] = React.useState(false);

    const [open1, setOpen1] = React.useState(false);
    const image2=`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/profile/${props.image1?props.image1:null}`
    const image5=`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/profile/${props.image4?props.image4:null}`
  return (
    <div className="ClsDivW2H">
      
    <div className="ClsBoxContainerWeb2H">
    {/* <div> */}
      {/* <img  src={props.image4&&!image3?image5:image3?URL.createObjectURL(image3):stat==='0'?Image1:null} className='PltTopContainerWeb3' /> */}
      {/* <img src={props.image4&&stat=='0'&&!image3?Image1:props.image4&&stat=='1'&&!image3?image5:image3?URL.createObjectURL(image3):Image1}  className='PltTopContainerWeb3'/>
       </div> */}
       {/* <div className="PltBackDropLeftW3">
       </div>
       <div className="PltBackDropRightW3">
       </div> */}
       
       {/* <div className="PltAvatarCont3">
       <Avatar alt="Remy Sharp" src={props.image1?image2:image?URL.createObjectURL(image):Image} className="PltAvatarPic3"/>
       </div> */}
            <div className="D6_CARD_ClsW2_Grid_NameH">
            {name?name:!name?'Your Name':null} 
            </div>
            {/* <div className="D6_CARD_PltW3_Grid_headline">
            {headline?headline:!name?'Headline':null}

            </div> */}
            <div className="D6_CARD_ClsW2_Grid_desigH">
            {designation?designation:!name?'Your Designation':null} 
            </div>
            <Grid container >
               {/* <Grid container item xs={6} className="D6_Card_PltW3_Loc_Grid">
               <Grid  item xs={4}>
                        {
                                  !name||location?
                          <Loc onClick={()=>window.open(`https://www.google.co.in/maps/place/${location}`,'_blank')}/>:null}
                        </Grid>
                      
                </Grid> */}
                {/* <Grid container item xs={6} className="D6_Card_PltW3_BH_Grid">
                      <Grid container item xs={10}>
                      
                        { mon?
                        <Grid  onClick={()=>setActive1('mon')}item xs={2}>
                          M
                        </Grid>:null}
                        { tue?<Grid  onClick={()=>setActive1('tue')}item xs={2}>
                          T
                        </Grid>:null}
                        {wed?
                        <Grid onClick={()=>setActive1('wed')} item xs={2}>
                          W
                        </Grid>:null}
                        {thur?
                        <Grid onClick={()=>setActive1('thur')} item xs={2}>
                          T
                        </Grid>:null}
                        {fri?
                        <Grid  onClick={()=>setActive1('fri')} item xs={2}>
                          F
                        </Grid>:null}
                        {sat?
                        <Grid  onClick={()=>setActive1('sat')} item xs={2}>
                          S
                        </Grid>:null}
                        </Grid>
                    
                        {sun?
                        <Grid  onClick={()=>setActive1('sun')} item xs={2}>
                          S
                        </Grid>:null}
                        <Grid item xs={12}>
                        {(active1==='sun'?sun:
                        (active1==='mon')?mon
                        :(active1==='tue'?tue
                        :(active1==='wed'?wed
                        :(active1==='thur'?thur
                        :(active1==='fri'?fri
                        :sat
            
                        )))))}                         </Grid>
                </Grid> */}
            </Grid>
            <div className="D6_CARD_ClsW2_Grid_compH">
                    {organization?organization:!name?'Your Organization':null} 
                    </div>
            <Grid container spacing={0} direction='row' item xs={12} >
                <Grid  container item xs={8} >
                    <div className="D6_Card_ClsW2_ContactData_GridH">
                    <div >
                       {/* {name?name:'Your Name'}  */}
                    </div>
                    <div >
                    {/* {designation?designation:'Your Designation'}  */}
                    </div>
                    {/* <div className="D6_CARD_PltW3_Grid_comp">
                    {organization?organization:!name?'Your Organization':null} 
                    </div> */}
                    <div >
                       {/* Headline */}
                    </div>
                    <div className="D6_CARD_ClsW2_Grid_emailH">
                    <Grid onClick={()=>window.open(`mailto:${email}`)} container direction="row" alignItems="center" item xs={12}>
                    {/* <Grid item xs={2}>{
                                  !name||email?<WorkEmail /> :null}                       
                                </Grid> */}
                                <Grid item xs={10}>
                                  {email?email:!name?'Your Email':null}
                                </Grid>
                        </Grid>
                    </div>
                    {/* <div className="D6_CARD_PltW3_Grid_web">
                          <Grid  onClick={()=>{web.includes('://')?window.open(web,'_blank'):window.open(`https://${web}`,'_blank')}}container direction="row" alignItems="center" item xs={12}>
                          <Grid item xs={2}>
                                  {!name||web?
                                  <Website /> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                {web?web:!name?'Your Website':null}         
                                </Grid>
                          </Grid>
                    </div> */}
                    <div className="D6_CARD_ClsW2_Grid_telH">
                         <Grid container direction="row" alignItems="center" item xs={12}>
                         {/* <Grid item xs={2}>
                                  {!name||mobile?
                                  <HomePh onClick={()=>setActive('m1')}/>:null}                        
                                </Grid> */}
                                
                                {/* <Grid item xs={2}>
                                {!name||mobile1?
                                  <WorkPh onClick={()=>setActive('m2')}/>:null}
                                </Grid> */}
                                <Grid onClick={()=>window.location.href=`tel:${mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:null}`} item xs={8}>
                                                                {mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:!name?'Your Mobile Number':!mobile&&mobile1?mobile1:!mobile1&&mobile?mobile:null} 
 
                                </Grid>
                        </Grid>     
                          </div>
                    </div>
                  </Grid>
                <Grid container direction='row' item xs={4} className="D6_Card_ClsW2_QRCont_GridH">
                      <Grid item xs={12} >
                         
                      </Grid>
                      <Grid item xs={12} className="D6_Card_ClsW2_QR_GridH">
                      <QRCode
          size={90}
          value={`https://halodigital.app/${props.username?props.username:props.key1}`}
        //   logoImage={logo}
        ecLevel='H'
          logoOpacity='1'
          qrStyle='dots'
          // logoWidth='60'
          quietZone='5'
          fgColor='#000'
        
        />           
                      </Grid>
                </Grid>
            </Grid>
            {/* <Grid container direction='column' item xs={12}  >
                <Grid container spacing={1} direction="column" item xs={12}> */}
                           {/* <Grid  item xs={2}>
                            <FacebookIcon  className="D6_Card_Opt_Fields"/>                        
                          </Grid>
                          <Grid item xs={2}>
                            <WhatsAppIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid item xs={2}>
                            <LinkedInIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid item xs={2}>
                            <InstagramIcon className="D6_Card_Opt_Fields"/>                       
                          </Grid>
                          <Grid  item xs={2}>
                             <GitHubIcon className="D6_Card_Opt_Fields"/>
                          </Grid>
                          <Grid item xs={2}>
                            <TwitterIcon  className="D6_Card_Opt_Fields"/>
                          </Grid> */}

                {/* </Grid>
            </Grid> */}
    </div>

    {/* <Grid container  item xs={12}  alignItems="center" className="PltBackDropBottomW3">
               {/* <Grid container item xs={12}></Grid> */}
                {/* <Grid container spacing={1}  item xs={12} alignItems="center"  >
                <Grid container item xs={12} alignItems="center" >
                  <div className="D6_Bottom_SMCont"></div>
                </Grid>
                {facebook?

<Grid className="D6_Bottom_SM" item xs={2}>
<FacebookIcon onClick={()=>{facebook.includes('://')?window.open(facebook,'_blank'):window.open(`https://www.facebook.com/${facebook}`,'_blank')}} />                        
 </Grid>:null}
 {whatsapp?
 <Grid  className="D6_Bottom_SM" item xs={2}>
                  <WhatsAppIcon onClick={()=>{whatsapp.includes('://')?window.open(whatsapp,'_blank'):window.open(`https://wa.me/${whatsapp}`,'_blank')}} />
 </Grid>:null}
 {linkedIn?
 <Grid className="D6_Bottom_SM" item xs={2}>
                  <LinkedInIcon   onClick={()=>{linkedIn.includes('://')?window.open(linkedIn,'_blank'):window.open(`https://www.linkedin.com/in/${linkedIn}`,'_blank')}}/>
 </Grid>:null}
 {instagram?
 <Grid className="D6_Bottom_SM" item xs={2}>
                  <InstagramIcon onClick={()=>instagram.includes('://')?window.open(instagram,'_blank'): window.open(`https://www.instagram.com/${instagram}`,'_blank')} />                       
 </Grid>:null}
 {gitHub?
 <Grid  className="D6_Bottom_SM" item xs={2}>
                    <GitHubIcon onClick={()=>{gitHub.includes('://')?window.open(gitHub,'_blank'):window.open(`https://github.com/${gitHub}`,'_blank')}}  />
 </Grid>:null}
 {twitter?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <TwitterIcon  onClick={()=>{twitter.includes('://')?window.open(twitter,'_blank'): window.open(`https://twitter.com/${twitter}`,'_blank')}} />
 </Grid>:null}

 
 {youTube?

<Grid className="D6_Bottom_SM" item xs={2}>
   <YouTube onClick={()=>{youTube.includes('://')?window.open(youTube,'_blank'):window.open(`https://www.youtube.com/watch?v=${youTube}`,'_blank')}} />                        
 </Grid>:null}
 {telegram?
 <Grid  className="D6_Bottom_SM" item xs={2}>
   <Telegram onClick={()=>{telegram.includes('://')?window.open(telegram,'_blank'):window.open(`https://t.me/${telegram}`,'_blank')}} />
 </Grid>:null}
 {googleMeet?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <GoogleMeetIcon   onClick={()=>{googleMeet.includes('://')?window.open(googleMeet,'_blank'):window.open(`https://meet.google.com/${googleMeet}`,'_blank')}}/>
 </Grid>:null}

 {(pay1&&pay1Value)||(pay2&&pay2Value)||(pay3&&pay3Value)?
              <Grid onClick={()=>setOpen1(true)}  className="D6_Bottom_SM" item xs={2}>
                <AccountBalanceIcon />
             
         </Grid>:null}
         {note?
              <Grid onClick={()=>setOpen2(true)} className="D6_Bottom_SM" item xs={2}>
                <SpeakerNotesIcon />
              </Grid>:null}
            <Dialog
      open={open1}
      fullWidth
    // maxWidth='sm'
      // style={{width}}
      onClose={handleClose}
      // aria-labelledby="draggable-dialog-title"
      // maxWidth='xl'
    >
      <PayDialog1  pay1={pay1} pay2={pay2} pay3={pay3} pay1Value={pay1Value} payValue={pay2Value} pay3Value={pay3Value} /> 
  </Dialog>
  <Dialog
      open={open2}
      
    // maxWidth='sm'
      // style={{width}}
      onClose={handleClose}
      // aria-labelledby="draggable-dialog-title"
      // maxWidth='xl'
    >
      <div style={{margin:'10px'}} >
      <TextField 
      
                        value={note}
                        disabled
                        style={{width:'300px'}}
          label="Notes"
          placeholder="Write your notes here.."
          multiline
          rows={7}

          variant="outlined"
        />        
        </div>
  </Dialog>

                </Grid>
            </Grid>
    */} 
    </div>
  );
}
