import React ,{useState,useRef}from 'react';


import Grid from '@material-ui/core/Grid';

import './Halo_Pass.css'
import reactCSS from 'reactcss'
import * as htmlToImage from 'html-to-image';


import { QRCode } from 'react-qrcode-logo';
import { Chip } from '@material-ui/core';


const Halo_Pass_Template=React.forwardRef((props,ref) =>{
  
const color1=props.colorB1?props.colorB1:props.template&&props.template.colorB1?props.template.colorB1:''
const color2=props.colorB2?props.colorB2:props.template&&props.template.colorB2?props.template.colorB2:''
const colorH=props.colorH?props.colorH:props.template&&props.template.colorH?props.template.colorH:'goldenrod'
const colorV=props.colorV?props.colorV:props.template&&props.template.colorV?props.template.colorV:'white'
const colorQR=props.colorQR?props.colorQR:props.template&&props.template.colorQR?props.template.colorQR:'black'

const date1=new Date()
const date1N=date1.getFullYear()+'-'+((date1.getUTCMonth()+1)<10?('0'+(date1.getUTCMonth()+1)):date1.getUTCMonth()+1)+'-'+((date1.getDate())<10?('0'+date1.getDate()):date1.getDate())

    const styles = reactCSS({
        'default': {
halo_pass:{
    background:`linear-gradient(180deg,${color1?color1:'black'} 5%,${color2?color2:'black'} 80%)`,
    borderRadius:'20px',
    padding:'10px'
},
heading:{
    color:colorH,
    fontSize:'20px',
    fontWeight:'700',
    // paddingLeft:'10px'
},
heading1:{
    color:colorH,
    fontSize:'16px',
    fontWeight:'700',
    paddingRight:'10px',

},
expDate:{
    color:colorV,
    fontSize:'20px',
    fontWeight:'700',
    paddingRight:'10px',

},
name:{
    color:colorV,
    fontSize:'20px',
    fontWeight:'700',
     
}


        }})



        const exportAsPicture1 = (val) => {
    

            var data = document.getElementsByClassName(val)
          
            for (var i = 0; i < data.length; ++i){
                htmlToImage.toPng(data[i]).then((dataUrl) => {
                  saveAs(dataUrl,val);
                });
            }
          
          
          
          }
          const saveAs = (blob, fileName) =>{
            var elem = window.document.createElement('a');
            elem.href = blob
            elem.download = fileName;
            elem.style = 'display:none;';
            (document.body || document.documentElement).appendChild(elem);
            if (typeof elem.click === 'function') {
              elem.click();
            } else {
              elem.target = '_blank';
              elem.dispatchEvent(new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
              }));
            }
            URL.revokeObjectURL(elem.href);
            elem.remove()
          }



  return (
      <>
      <Grid container style={{justifyContent:'center'}}>
  <div style={styles.halo_pass} className='halo_pass'>
      <Grid container>
          <Grid item  style={styles.heading} sm={6} xs={6}>{props.template&&props.template.passTitle?props.template.passTitle:props.data&&props.data.title?props.data.title:'Halo Pass'}</Grid>
          <Grid item style={{textAlignLast:'end'}} sm={6} xs={6}>
          <Grid item style={styles.heading1} sm={12} xs={12} >Expiry Date</Grid>
          <Grid item style={styles.expDate} sm={12} xs={12} >{props.value?props.value:props.template.expiryDate?props.template.expiryDate:date1N}
          </Grid>
          </Grid>
      </Grid>
      <Grid container style={{paddingTop:'36px',paddingBottom:'20px'}}>
          <Grid item style={styles.heading1} sm={12} xs={12}>Member Name</Grid>
          <Grid item style={styles.name} sm={12} xs={12}>
        {props.template&&props.template.passMemberName?props.template.passMemberName:props.data&&props.data.name?props.data.name:'John Doe'}
          </Grid>
      </Grid>

      <Grid container>
      <Grid item sm={6}   xs={6}>
          <Grid item sm={12} style={styles.heading1} xs={12} >Pass Number</Grid>
          <Grid item style={styles.expDate} sm={12} xs={12} >{props.template&&props.template.passNumber?props.template.passNumber:'12345678988'}
          </Grid>
          </Grid>
          <Grid item style={{textAlignLast:'end'}}  sm={6} xs={6}>
          <Grid item style={styles.heading1} sm={12} xs={12} >Issued On</Grid>
          <Grid item style={styles.expDate}  sm={12} xs={12} >{props.valueC?props.valueC:props.template.createdDate?props.template.createdDate:date1N}
          </Grid>
          </Grid>
      </Grid>
      <Grid container 
      style={{paddingTop:'40px',paddingBottom:'10px',textAlign:'center'}}
      >
      <Grid item sm={12} xs={12}>
      <QRCode
          size={130}
          value={props.template&&props.template.passNumber?props.template.passNumber:`https://halodigital.app/`}
        //   logoImage={logo}
        ecLevel='H'
          logoOpacity='1'
          qrStyle='dots'
          // logoWidth='60'
          quietZone='5'
          fgColor={colorQR}
        
        />  
          </Grid>
        
      </Grid>
      

  </div>
  </Grid>
 {props.saved||props.selected? <Grid item sm={12} style={{margin:'10px',textAlign:'center'}}   xs={12}>
  < Chip   color="secondary" label="Download Halo Pass" onClick={()=>exportAsPicture1('halo_pass')}  />


  </Grid>:null}
  </>
)
})
export default Halo_Pass_Template
