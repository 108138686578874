import { exportComponentAsJPEG } from 'react-component-export-image';
import React, { useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import jsPDF from 'jspdf'
import {useCookies} from 'react-cookie'
import D6_halo_cardclswebH from '../ContactPage/ClsCard/D6_halo_cardclswebH';
import D6_halo_cardpltwebH from '../ContactPage/PltCard/D6_halo_cardpltwebH';
import D6_halo_cardsigwebH from '../ContactPage/SigCard/D6_halo_cardsigwebH';
import logo from '../../assets/Images/HaloFIcon.png'
import * as htmlToImage from 'html-to-image';

const ComponentToPrint = React.forwardRef(({card}, ref) => (
  <div   className='qr-code' ref={ref}>
        <QRCode
          size={160}
          value={`https://halodigital.app/${card.username?card.username:card.key}`}
        //   logoImage={logo}
        ecLevel='H'
          logoOpacity='1'
          qrStyle='dots'
          logoWidth='60'
          quietZone='5'
          fgColor='#61126b'
        
        />
  </div>
));
const exportAsPicture = (val) => {
    

  var data = document.getElementsByClassName(val)

  for (var i = 0; i < data.length; ++i){
      htmlToImage.toPng(data[i]).then((dataUrl) => {
        saveAs(dataUrl,val);
      });
  }



}
const saveAs = (blob, fileName) =>{
  var elem = window.document.createElement('a');
  elem.href = blob
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    }));
  }
  URL.revokeObjectURL(elem.href);
  elem.remove()
}
const Card = React.forwardRef(({card}, ref) => (
    <div className='card' style={{width:'400px',height:'250px',paddingTop:5}} ref={ref} >
    {/* <div className='download' ref={ref} style={{ minWidth: "450px",minHeight:'300px',paddingTop:10,paddingRight:20,textAlign:'center'}}> */}

{
        card.planCategory==='classic'?
        <D6_halo_cardclswebH card={card.card}name={card.fname+' '+card.lastname} email={card.email} designation={card.organisation_tittle} 
        organization={card.organisation} web={card.web} mobile={card.work_phone} image1={card.pname} 
        facebook={card.facebook} instagram={card.instagram} username={card.username}
                whatsapp={card.whatsapp} linkedIn={card.linkedIn}twitter={card.twitter}gitHub={card.github}youTube={card.youtube}
                telegram={card.telegram} googleMeet={card.googleMeet}key1={card.key}/>:
        card.planCategory==='signature'?
        <D6_halo_cardsigwebH location={card.location} card={card.card}name={card.fname+' '+card.lastname} email={card.email} designation={card.organisation_tittle} 
        organization={card.organisation} web={card.web} mobile={card.work_phone} mobile1={card.home_phone} image1={card.pname} 
        facebook={card.facebook} instagram={card.instagram}key1={card.key}
        whatsapp={card.whatsapp} linkedIn={card.linkedIn}twitter={card.twitter}gitHub={card.github}youTube={card.youtube}
        telegram={card.telegram} googleMeet={card.googleMeet} headline={card.headline} username={card.username}/>:
         card.planCategory==='platinum'?

        <D6_halo_cardpltwebH  card={card.card}name={card.fname+' '+card.lastname} email={card.email} designation={card.organisation_tittle} 
        organization={card.organisation} web={card.web} mobile={card.work_phone} image1={card.pname}
        facebook={card.facebook} instagram={card.instagram} image4={card.logoname} key1={card.key} location={card.location}
        whatsapp={card.whatsapp} linkedIn={card.linkedIn}twitter={card.twitter}gitHub={card.github}youTube={card.youtube}
        telegram={card.telegram} googleMeet={card.googleMeet} username={card.username}
        pay1={card.pay1} pay1Value={card.pay1Value} pay2={card.pay2} pay2Value={card.pay2Value}pay3={card.pay3} pay3Value={card.pay3Value} note={card.note} headline={card.headline}color1={card.color1} sun={card.sunHrs} mon={card.monHrs}tue={card.tuesHrs}wed={card.wedHrs}thur={card.thusHrs}fri={card.friHrs} sat={card.satHrs}
        color1={card.color1} color2={card.color2} colorT={card.colorT}/>
       :null}
 {/* <HaloInvoice /> */}
 </div>
  ));

const Invoice = (props) => {
  const card=props.cardRes
  const payment=props.paymentRes
  const[auth]=useCookies(['auth'])

    const {value}=props

  const componentRef = useRef();
  const cardRef=useRef()
  const pdfGenerate=()=>{
    var doc=new jsPDF('portrait','px','a4','false')
    doc.setFont('Helvetica','bold')
    doc.setTextColor('#e1163c')
    doc.text(49,68,'Dec6 Consulting Pvt Ltd')
    doc.text(49,79,'Gurgaon,india')
    // doc.setFont('Times')
    doc.setFontSize(14)
    doc.setTextColor('#61126b')
    doc.addImage(logo,'PNG',550,60,60,60)
    
    doc.text(47,170,auth.auth.fname)
    doc.text(47,180,auth.auth.city+',  '+auth.auth.stat)
    doc.text(47,190,auth.auth.country)
    doc.text(350,170,'PaymentID')
    doc.setTextColor('#A7BBC7')

    doc.text(500,170,payment.paymentID.toString())
    doc.text(350,180,'Invoice Date')
    doc.setTextColor('#A7BBC7')

    doc.text(500,180,card.addedOn.toString())
    doc.text(350,190,'Amount Due')
    doc.setTextColor('#A7BBC7')

    doc.text(500,190,payment.amount)
    doc.text(30,270,'Item')
    doc.text(30,310,'DigiCard')
    doc.text(130,270,'Description')
    doc.text(130,310,'Digital Business Card')

    doc.text(280,270,'Product Cost')
    doc.text(280,310,'$84.88')

    doc.text(380,270,'Tax')
    doc.text(380,310,'$15.12')

    doc.text(480,270,'Line total')
    doc.text(480,310,' $100')
    doc.save('invoice.pdf')
  }

  return (
    <React.Fragment>{
        value==='1'?<>
        <ComponentToPrint card={card}ref={componentRef}  />
        <Grid container  sm={12} xs={12}>
              <Grid  onClick={() => exportAsPicture('qr-code')}item sm={6} xs={12}>
                <Paper className="D6_DWNLD_ButtonNewCard">Save QR Code</Paper>
              </Grid>
              <Grid onClick={()=>window.open(`https://halodigital.app/${card.username?card.username:card.key}`,'_blank')} item sm={6} xs={12}>
                <Paper className="D6_DWNLD_ButtonNewCard">Open Web URL</Paper>
              </Grid>
            </Grid>
        {/* <button >
          Export As JPEG
        </button> */}
        </>:value==='2'?<>
        
      <Card  card={card} ref={cardRef} />
      <Grid container  style={{paddingTop:10}} sm={12} xs={12}>
              {/* <Grid  onClick={() => exportComponentAsJPEG(cardRef,{fileName:'Card'})}item sm={12} xs={12}> */}
              <Grid  onClick={() => exportAsPicture('card')}item sm={12} xs={12}>

                <Paper className="D6_DWNLD_ButtonNewCard">Save Card</Paper>
              </Grid>
              </Grid>
      {/* <button >
        Download  card
      </button> */}
        </>:value==='3'&&payment? <button onClick={() =>pdfGenerate()}>
        Download  Invoice 
      </button>
       :null }

     
     
    </React.Fragment>
  );
};

export default Invoice;