import React from 'react'
import CardSigweb1copyH from './D6_halo_cardsigweb1copyH'
import CardSigWeb2copyH from './D6_halo_cardsigweb2copyH'
import CardSigWeb3copyH from './D6_halo_cardsigweb3copyH'

export default function D6_halo_cardsigwebH(props) {
  const{card}=props
  const {name,email,designation,organization,mobile,mobile1,key1}=props
    return (
      <div>{card=='1'?
        <CardSigweb1copyH name={name} email={email} designation={designation}  
        organization={organization}   mobile1={mobile1}  mobile={mobile}key1={key1}  />:
        card=='2'?
        <CardSigWeb2copyH  name={name} email={email} designation={designation}  mobile={mobile} 
        organization={organization}    mobile1={mobile1} mobile={mobile}  key1={key1}/>:
        card=='3'?
        <CardSigWeb3copyH name={name} email={email} designation={designation} 
        organization={organization}   mobile={mobile}   mobile1={mobile1} key1={key1} mobile={mobile}   />
        :null
      }
      </div>
    )
  }