import React,{useState} from 'react'
import   {BoxContainer,SubmitButton, MutedLink} from './D6_FE_CommonElements';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Marginer } from "./Marginer";
import './D6_OTPPage_CSS.css'
import './D6_FE_LoginForm_CSS.css'
import {useCookies} from 'react-cookie'
import { sha256 } from 'js-sha256';




const D6_FE_OTPPage = (props) => {
  const{live}=props
  const[processing,setProcessing]=useState(null)

    const email=props.email
    //const name=props.name
    const screen1=props.screen
    const data1=props.data1.detail
    const data=props.data1
    const[label,setLabel]=useState('')
    var tempDate = new Date();
    var data2 = data1+tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
    var hash=sha256(data2)

    const[auth,setAuth]=useCookies(['auth'])
  

      const [value,setValue]=useState('');
      //const [screen,setScreen]=useState('');
    
      
      const onSubmit = ()=> {
        setProcessing(true)
setLabel('')
            fetch(live+'verify',
            {
            method: 'post',
            headers:{
            'Content-Type' :'application/json'
            },
            body: JSON.stringify({email:email ,otp:value})
            })
            .then(res => res.json())
         // .then(res=> console.log(res))
          .then((res)=>screenComponent(res))
      };
      const emailClicked=()=>{
        {if(email)
          {setProcessing(true)
            setLabel('')
            fetch(live+'forpass',
          // fetch(' http://127.0.0.1:8000/forpass',
          {
          method: 'POST',
          headers:{
            'Content-Type' :'application/json'  
          },
          body: JSON.stringify({email:email})
        })
        .then(res => res.json())
        .then((resp)=>Label(resp.detail))  }
        else{
          setLabel('Error in processing')
        }
      }}
      const Label=(val)=>{
        setProcessing(false)
        setLabel(val)

      }
      
      const screenComponent=(res)=>{
        setProcessing(null)

        if(res.detail==='Confirmed successfully'){
 
         
          if(props.handleClose&&screen1==='login'){
            props.handleClose()
          }
          
          else{
          props.screenComponent()
          }
          setAuth('auth',res.data?res.data:data,{
            maxAge: 3600 ,
            expires:0,
            path:'/'
         })
       ///  window.location.href='/admin'
          
        }
        else{
          setLabel(res.detail)
        }
          

      }

    

    return (
    <>
     
    <BoxContainer>
    <div className="SignIn_Form_Div">
            <ValidatorForm
                useRef="form"
                onSubmit={onSubmit}
               
            >

            <div className="D6_SIForm_TV_Div">
                <label className="D6_OTPPage_Label">OTP</label>
               <TextValidator
                    label=""
                    value={value}     

                    onChange={(e)=>setValue(e.target.value)}
                    name="otp"
                    type="password"
                    validators={['required']}
                    errorMessages={['Password is required']}
                   
                />
                {(label==''?null:<label className="D6_SIForm_error">{label}</label>)}
            </div>
 
          <Marginer direction="vertical" margin="1.6em" />
          <SubmitButton disabled={processing} type="submit"  >{!processing?'Verify':'Processing'}</SubmitButton>
          <Marginer direction="vertical" margin="1em" />
          <MutedLink onClick={emailClicked}className="D6_OTPPage_link">Resend OTP</MutedLink>
          </ValidatorForm>
        </div>
    </BoxContainer>
    </>
    )
    
}

export default D6_FE_OTPPage
