
import React,{useState} from 'react';
import './D6_SL_Header_CSS.css';
import HamBurger from './D6_SL_Hamburger';
import Logo from '../../../assets/Images/HaloWhite.svg'
/*import Logo1 from '..../src/assets/Images/Logo.svg' */
import {NavLink} from "react-router-dom";

function D6_SL_Header(props) {
const live=props.live
    const [D6_SL_Navbar_Items,setNavbar] = useState(false);
    const changeFixed = () => {
        if(window.scrollY >= 10)
        {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };
    window.addEventListener('scroll',changeFixed);
    const country=(value)=>{
         props.Country(value)
    }
    return(
        <>
    {/*    <nav className="D6_SL_Navbar_Items">  */}
        <nav className={D6_SL_Navbar_Items ? 'D6_SL_Navbar_Items active' : 'D6_SL_Navbar_Items'}>
            <div>
                <div className="D6_SL_Navbar_Link" style={{cursor:"pointer"}} onClick={()=>window.location.href='/'}>
                    <img src={Logo} className="D6_SL_Navbar_Items1_Image" alt="Halo" /> 
                </div>
            </div>
            <HamBurger country={country} live={live}/>     
        </nav>
        </>
    );
}

export default D6_SL_Header;
