import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './D6_halo_dwnld.css';
import { Button } from '@material-ui/core';
import Invoice from '../invoice/invoice';

export default function HaloDWNLD(props) {
  const{cardRes,paymentRes}=props
  // const classes = useStyles();
const [value,setValue]=useState(null)
  return (
    <Grid className="D6_DWNLDCont_Grid">
      {/* <Grid container > */}
        <Grid container direction="row" spacing={0} item sm={12} xs={12}>
            <Grid item sm={12} xs={12}>
              <Paper onClick={()=>setValue('1')} className="D6_DWNLD_Grid">
                  <i  class="fas fa-qrcode fa-5x"></i>
                  <div >Click here to access your QR Code &amp; Web URL</div>
               </Paper>
               {/* <div className="D6_DWNLD_ButtonQR">Access your QR Code &amp; Web URL</div> */}

            </Grid>
             <Grid  item sm={12} xs={12}>
              <Paper onClick={()=>setValue('2')}  className="D6_DWNLD_Grid">
                <i  class="fas fa-address-card fa-5x"></i>
                <div >Download your card</div>
              </Paper>
            </Grid>
            {/* <Grid item sm={12} xs={4}>
              <Paper className="D6_DWNLD_Grid">
                <i  onClick={()=>setValue('3')}class="fas fa-file-invoice fa-5x"></i>
              </Paper>
            </Grid> */} 
        </Grid>
        <Grid direction='col' item sm={6} xs={12} className="D6_DWNLD_Preview_Grid">
          {/* <Paper className="D6_DWNLD_Preview_Grid">QR Code</Paper> */}
          <Invoice  paymentRes={paymentRes} value={value} cardRes={cardRes} />
         
        </Grid>
      {/* </Grid> */}
      <Grid container  sm={12} xs={12}>
              <Grid item  onClick={()=>window.location.href='/dash'} sm={6} xs={12}>
                <Paper className="D6_DWNLD_ButtonNewCard">My Cards</Paper>
                {/* <Button className="D6_DWNLD_Button">Create another card</Button> */}
              </Grid>
              <Grid  onClick={
                ()=>window.location.href='/'
              }item sm={6} xs={12}>
                <Paper className="D6_DWNLD_ButtonNewCard">Create new card</Paper>
                {/* <Button className="D6_DWNLD_Button">Create another card</Button> */}
              </Grid>
            </Grid>
    </Grid>
  );
}
