import React from 'react'
import CardPltWeb1copyH from './D6_halo_cardpltweb1copyH'
import CardPltWeb2copyH from './D6_halo_cardpltweb2copyH'
import CardPltWeb3CopyH from './D6_halo_cardpltweb3copyH'
import CardPltWeb5CopyH from './D6_halo_cardpltweb5copyH'
import CardPltWeb6CopyH from './D6_halo_cardpltweb6copyH'
import CardPltWeb7CopyH from './D6_halo_cardpltweb7H'

export default function D6_halo_cardpltwebH(props) {
  const{card}=props
  const {name,email,designation,organization,mobile,mobile1,key1,username,
  color1,color2,colorT}=props
    return (
      <div>{card==='1'?
        <CardPltWeb1copyH name={name} email={email} designation={designation}  
        organization={organization}   mobile1={mobile1} username={username}  mobile={mobile}key1={key1}  />:
        card==='2'?
        <CardPltWeb2copyH  name={name} email={email} designation={designation}  mobile={mobile} 
        organization={organization}    mobile1={mobile1} username={username} mobile={mobile}  key1={key1}/>:
        card==='3'?
        <CardPltWeb3CopyH name={name} email={email} designation={designation} 
        organization={organization}   mobile={mobile}   mobile1={mobile1} username={username} key1={key1} mobile={mobile}   />
        :
        card==='4'?
        <CardPltWeb5CopyH name={name} email={email} designation={designation} 
        organization={organization}   mobile={mobile}   mobile1={mobile1} username={username} key1={key1} mobile={mobile}   />:
        card==='5'?
        <CardPltWeb6CopyH name={name} email={email} designation={designation} 
        organization={organization}   mobile={mobile}   mobile1={mobile1} username={username} key1={key1} mobile={mobile}   />
        :card==='6'?
        <CardPltWeb7CopyH name={name} email={email} designation={designation} 
        organization={organization}   mobile={mobile}   mobile1={mobile1} username={username} key1={key1} mobile={mobile} color1={color1}
        color2={color2} colorT={colorT}   />
        :null
      }
      </div>
    )
  }

