import React, { useState ,useEffect,useRef} from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {useCookies} from 'react-cookie'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {  Button, Grid, Typography ,Box} from '@material-ui/core';
import D6_halo_cardclsweb from '../ContactPage/ClsCard/D6_halo_cardclsweb';
import D6_halo_cardsigweb from '../ContactPage/SigCard/D6_halo_cardsigweb';
import D6_halo_cardpltweb from '../ContactPage/PltCard/D6_halo_cardpltweb';
import PreviewPltFormPlt  from '../ContactPage/D6_halo_pltpreviewForm.jsx'
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(30),
    },
  },
  'search-box-wrapper': {
    backgroundColor: '#ffffff',
    height: '48px',
    borderRadius: '4px',
    // minWidth: '130px',
    width: '100%',
    border: '1px solid rgb(97, 18, 107)',
    display: 'flex',
    paddingLeft: '10px',
    justifyContent: 'space-between',
    margin: '0px auto 10px ', 
    // position: 'absolute',
    // top:'20%',
    // left: '50%',
    // marginLeft: '-350px',
  },
  'search-field': {
    height: '46px',
    width: 'calc(100% - 150px)',
    display: 'flex',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
}));
function Alert1(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Cards(props) {
  const [copy,setCopy]=useState(false)
    const myRef = useRef()
    const[auth,setAuth]=useCookies(['auth'])
    const[filter,setFilter]=useState([])
    const[vcard,setVcard]=useState(null)
    const[preview,setPreview]=useState(null)
    const[filter1,setFilter1]=useState([])
    const [delet,setDelete]=useState(false)
    const [msg,setMsg]=useState('')
    const [did,setDid]=useState('')
    const [status,setStatus]=useState(false)



    useEffect(() => {
      if(!auth.auth){
        window.location.href='/signin'
      }
       }, [auth.auth])

    const {cards,live,countryF}=props
    const Back=()=>{
      setPreview(null)
      setVcard(null)
      props.Load()

    }
    // console.log(vcard)
    const setSearchBar=(val)=>{if(val){
      setFilter1(filter.sort((a, b) => a.dcardID < b.dcardID ? 1 : -1).filter((count)=>
      (count.fname.toLowerCase().includes(val.toLowerCase())||
      (count.lastname.toLowerCase().includes(val.toLowerCase())
      ||(count.key.toString().includes(val.toLowerCase())))
      )))
    }
    else{
      setFilter1(filter)
    }

    }
    useEffect(()=>
    { 
      if(auth.auth&&cards[0]){
        //  console.log('',value)
            setFilter(cards.sort((a, b) => a.dcardID < b.dcardID ? 1 : -1).filter((count)=>
            (count.memberID.toString().includes(auth.auth.memberID.toString()))
            
          ))
          setFilter1(cards.sort((a, b) => a.dcardID < b.dcardID ? 1 : -1).filter((count)=>
          (count.memberID.toString().includes(auth.auth.memberID.toString()))
        ))
      setStatus(true)}else{
              setFilter(null)
              setStatus(true)
          }
            
        },[cards,auth.auth])

        const Delete=(value)=>{
          if(!delet){
            setDelete(true)
            setDid(value)

          }
          else{
setMsg('Deleting ....')
          fetch(live+'create',
                    //  fetch('http://127.0.0.1:8000/create',

          { method: 'DELETE',
          headers:{
            'Content-Type' :'application/json'  
          },
          body:JSON.stringify({dcardID:did})
        })
          .then(res => res.json())
          .then(resp=>DelRes(resp))
                  }}

     const DelRes=(resp)=>{
     
     setDelete(false)


     setVcard(null)
     AuthS(resp)
     props.Load()
     }   
     const AuthS=(val)=>{
       setMsg(false)
      setAuth('auth',val.data,{
        maxAge:3600 ,
        expires:0
     })
     }
     const copyToClipboard = (valu) => {
       setCopy(true)

      const el = document.createElement('textarea');
      el.value =valu;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);


    };
   
  const classes = useStyles();

  const Vcard1 =(value)=>{

    setVcard(value)
    if(myRef){
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  }


  }
  // console.log(vcard.pay1ValueN)
  if(cards[0]&&auth.auth&&!preview)
  return (
    <div style={{height:'100%'}}>

  <div >
 
  <Snackbar open={delet}  anchorOrigin={{vertical:'top', horizontal:'center'}} >
     
      <Alert severity="info"
       action={
          !msg?<>
          <Button className='btn_A' onClick={()=>Delete()} color='success'   size="small">
            Delete
          </Button>
          <Button  className='btn_A' onClick={()=>setDelete(false)} color="success" size="small">
          Cancel
        </Button>
        </>:null
        }
      >
        {!msg?'Card deletion will also delete associated business profile':msg}
      </Alert>
      </Snackbar>


                    <Grid item xs={12} className={classes['search-box-wrapper']}>
                        <input type="text" placeholder='Search your Halo Cards' onChange={(e)=>setSearchBar(e.target.value)} className={classes['search-field']} />

                    </Grid>
                </div>
      <Grid container item xs={12}>
          <Grid item xs={12} sm={6} >
          {/* <div style={{display:'flex'}}> */}
          {copy?<>
            <Snackbar  open={copy} autoHideDuration={2000} anchorOrigin={{vertical:'top',horizontal:'center'}} onClose={()=>setCopy(false)}>
  <Alert1   severity="info">
    Card URL copied
    </Alert1>
</Snackbar>
          </>:null
}
                  <div className={classes.root}>
   
                  
                      {filter1.map((card,idx)=>{
                          return(
                              <Paper className="D6_DashCardsGrid" key={idx} elevation={3} >
                              <Grid container item xs={12}>
                              <Grid item xs={10}>
                              <Typography style={{margin:'10px',fontSize:'14px'}}>
                              Name: {card.fname+' '+card.lastname}
                              </Typography>
                              </Grid>
                              <Grid item xs={2}>
                              <FileCopyIcon onClick={()=>copyToClipboard(`https://halodigital.app/${card.username?card.username:card.key}`)}  style={{margin:'5px',color:'e1126b'}} />
                              </Grid>
                              </Grid>
                              <Typography style={{margin:'10px',fontSize:'14px'}}>
                              Card ID: {card.key}
                              </Typography>
                              <Typography style={{margin:'10px',fontSize:'14px'}}>

                              Category: {card.planCategory.toUpperCase()}
                              </Typography>
                              <Typography style={{margin:'10px',fontSize:'14px'}}>

                              Created on: {card.addedOn}
                              </Typography>
                              {card.private=='1'?
                              <Typography style={{margin:'10px',fontSize:'14px'}}>

                      Card type : Private
                          </Typography>: <Typography style={{margin:'10px',fontSize:'14px'}}>

Card type : Public
    </Typography>}
    <Typography style={{margin:'10px',fontSize:'14px'}}>

Card views : {card.hits}
    </Typography>
                              
                              <Grid container xs={12}  >
                                <Grid  style={{textAlign:'center'}}item xs={6}>
                  <Button  variant="outlined" onClick={()=>Vcard1(card)} style={{backgroundColor:'#61126b',margin:'10px',height:'25px',width:'10px',fontSize:'12px',alignItems:'center'}}>
                  Edit
                  </Button>
                  </Grid>
                  <Grid  style={{textAlign:'center'}}item xs={6}>
                  <Button  variant="outlined" onClick={()=>Delete(card.dcardID)} style={{backgroundColor:'#e1163c',margin:'10px',height:'25px',width:'10px',fontSize:'12px',alignItems:'center'}}>
                  Delete
                  </Button>
                    </Grid>

                  </Grid>
                              </Paper>
                          )
                      })}
                       
                 
                  </div>
          </Grid>
          <Grid item xs={12} sm={6} className="D6_DashCardsContGrid">
            <div ref={myRef}>
          {vcard&&cards[0]&&auth.auth?
   < >
  <div  onClick={()=>setPreview(true)} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
 
  {

      vcard.planCategory=='classic'?
      <D6_halo_cardclsweb  category={vcard.planCategory} card={vcard.card} name={vcard.fname+' '+vcard.lastname} email={vcard.email} designation={vcard.organisation_tittle} 
      organization={vcard.organisation} web={vcard.web} mobile={vcard.work_phone} mobile1={vcard.home_phone} image1={vcard.pname}
      facebook={vcard.facebook} instagram={vcard.instagram} key1={vcard.key}
      whatsapp={vcard.whatsapp} linkedIn={vcard.linkedIn}twitter={vcard.twitter}gitHub={vcard.github}youTube={vcard.youtube}
      telegram={vcard.telegram} googleMeet={vcard.googleMeet} username={vcard.username?vcard.username:''}/>:
      vcard.planCategory=='signature'?
      <D6_halo_cardsigweb category={vcard.planCategory} card={vcard.card} location={vcard.location} name={vcard.fname+' '+vcard.lastname} email={vcard.email} designation={vcard.organisation_tittle} 
      organization={vcard.organisation} web={vcard.web} mobile={vcard.work_phone} mobile1={vcard.home_phone} image1={vcard.pname} 
      facebook={vcard.facebook} instagram={vcard.instagram}  headline={vcard.headline} key1={vcard.key}
      whatsapp={vcard.whatsapp} linkedIn={vcard.linkedIn}twitter={vcard.twitter}gitHub={vcard.github}youTube={vcard.youtube}
      telegram={vcard.telegram} googleMeet={vcard.googleMeet} username={vcard.username?vcard.username:''}/>:
       vcard.planCategory=='platinum'?

      <D6_halo_cardpltweb  category={vcard.planCategory} card={vcard.card}location={vcard.location} name={vcard.fname+' '+vcard.lastname} email={vcard.email} designation={vcard.organisation_tittle} 
      organization={vcard.organisation} web={vcard.web} mobile={vcard.work_phone} mobile1={vcard.home_phone} image1={vcard.pname}
      facebook={vcard.facebook} instagram={vcard.instagram}  image4={vcard.logoname} 
      headline={vcard.headline} key1={vcard.key} stat={vcard.imgVal}
      whatsapp={vcard.whatsapp} linkedIn={vcard.linkedIn}twitter={vcard.twitter} gitHub={vcard.github}youTube={vcard.youtube}
      telegram={vcard.telegram} googleMeet={vcard.googleMeet}  sun={vcard.sunHrs} mon={vcard.monHrs}tue={vcard.tuesHrs}wed={vcard.wedHrs}thur={vcard.thusHrs}fri={vcard.friHrs} sat={vcard.satHrs} 
       pay1={vcard.pay1} snapChat={vcard.snapChat} pay1Value={vcard.pay1Value} pay2={vcard.pay2} pay2Value={vcard.pay2Value}pay3={vcard.pay3} pay3Value={vcard.pay3Value} note={vcard.note} color1={vcard.color1} color2={vcard.color2} colorT={vcard.colorT} username={vcard.username?vcard.username:''} 
       pay1ValueN={vcard.pay1ValueN} pay2ValueN={vcard.pay2ValueN}  pay3ValueN={vcard.pay3ValueN}/>
                 :null}
 
             </div>
             </>
             :null}
             </div>
          </Grid>
      </Grid>
    </div>


  );
  if(cards[0]&&auth.auth&&preview){
    return(
    <div>
    <PreviewPltFormPlt countryF={countryF} category1={vcard.planCategory} live={live} identity='put' Back={Back}dcardID={vcard.dcardID} card={vcard.card} category={vcard.planCategory} fname={vcard.fname} lname={vcard.lastname} email={vcard.email} designation={vcard.organisation_tittle} 
    organization={vcard.organisation} web={vcard.web} mobile={vcard.work_phone} mobile1={vcard.home_phone} image1={vcard.pname}
    facebook={vcard.facebook} instagram={vcard.instagram}location={vcard.location}  image4={vcard.logoname} 
    headline={vcard.headline} dcard_url={vcard.dcard_url}
    whatsapp={vcard.whatsapp} linkedIn={vcard.linkedIn}twitter={vcard.twitter}gitHub={vcard.github}youTube={vcard.youtube}
    telegram={vcard.telegram} googleMeet={vcard.googleMeet}  sun={vcard.sunHrs} mon={vcard.monHrs}tue={vcard.tuesHrs}wed={vcard.wedHrs}thur={vcard.thusHrs}fri={vcard.friHrs} sat={vcard.satHrs} 
    pay1={vcard.pay1} pay1Value={vcard.pay1Value} pay2={vcard.pay2} pay2Value={vcard.pay2Value}pay3={vcard.pay3} pay3Value={vcard.pay3Value} note={vcard.note} stat={vcard.imgVal} private={vcard.private} shared={vcard.sharePassword} master={vcard.masterPassword} color1={vcard.color1} color2={vcard.color2} colorT={vcard.colorT}
    username={vcard.username?vcard.username:''} snapChat={vcard.snapChat} site={vcard.site?vcard.site:''}
    pay1ValueN={vcard.pay1ValueN} pay2ValueN={vcard.pay2ValueN}  pay3ValueN={vcard.pay3ValueN}/> 
    
    </div>)
  }
  if(!cards[0]&&status){
  
    return(   
      <div>          
      <h1 style={{fontWeight:'bold',textAlign:'center',marginTop:'25px'}}>No card created</h1>
      </div>)
  
  }
  if(!status){
  
    return(   
        <Box sx={{ display: 'flex',justifyContent:'center' ,paddingTop:'20px'}}>
        <CircularProgress />
      </Box>)
  
  }

  
}
