export const Data = [
  {
    question: 'Why choose Halo Digital?',
    answer: 'Halo Digital is a modern way of sharing information instantly without any dependencies on devices or applications. It is an eco-friendly platform to expand your reach digitally across the boundaries. Suitable for all kinds of business meetings, conferences & social gatherings where you exchange your information with your network.'
  },  
  {
      question: 'How does this work?',
      answer: 'Halo Digital Card comes with a unique web URL (link), and a QR Code. You can either share your web URL (link) or allow the other person to scan your QR Code. Your unique link or QR code will open your card details immediately. After opening the link, your contact information can be saved to phonebook instantly. '
    },
    {
      question: 'How do I create my Halo Digital card?',
      answer: 'You can create your Halo Digital card with just a simple sign-up. Explore variety of cards under each category (Classic, Signature & Platinum), where Classic Card comes free with sign-up. After sign-in & selecting the card, you can fill/update your information yourself. Information fields will appear automatically for you based on your card selection. Platinum Cards offer maximum information possibilities.'
    },
    {
      question: 'How can I find & share my card URL later, after creating it first time?',
      answer: 'After creating your Halo Digital card, simply open your Card Link (Web URL) & click SAVE CONTACT at the bottom of the card. It will save your information in your phone contacts, which contains your CARD URL already. Whenever you need to pull your card to share with anyone, simple click on the URL in your own saved contact and present the QR Code or share via other methods like SMS, Email, Whatsapp etc.'
    },
    {
      question: 'How should I change my card information after creating the card?',
      answer: 'Halo Digital card can be updated anytime & you can do it easily by editing your card information from My Account section available in dashboard.'
    },
    {
      question: 'If I would want to share my card details with someone, does it need any application/settings on other person phone?',
      answer: "Halo Digital cards are accessible on all devices and doesn't require any application installation or dependency on devices."
    },
    {
      question: 'How would I locate my card link after creating it?',
      answer: 'All you need is to save your own contact in your contacts after creating a Halo Digital card. Your unique web URL (link) will be available in your saved contact & can be opened with a click. No login required to access your card.'
    },
    {
      question: 'Does it need sign in to access Halo Digital card?',
      answer: 'No sign in required to access/view Halo Digital card, sign-in is only required if you wish to edit your existing cards or create a new one or to add Halo connections.'
    },
    {
      question: 'How can I change my card template?',
      answer: 'Our platinum membership offers anytime unlimited number of changes to templates and/or information on cards.'
    },
    {
      question: 'How can I view my payments history?',
      answer: 'You can access your payments history under My Account section.'
    },
    {
      question: 'Need to ask something not covered in here...',
      answer: 'Please drop us an email at our support email mentioned at the bottom of the website.'
    }
  ];