import React,{useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

import Grid from '@material-ui/core/Grid';
import Image from '../../../assets/Images/HomeHair.png';
import './D6_halo_cardpltweb2copy.css';
import Avatar from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import Loc from '@material-ui/icons/LocationOnOutlined';
import HomePh from '@material-ui/icons/PhoneIphoneOutlined';
import WorkPh from '@material-ui/icons/PhoneInTalkOutlined';
import WorkEmail from '@material-ui/icons/EmailOutlined';
import Website from '@material-ui/icons/LanguageOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import reactCSS from 'reactcss'
import { QRCode } from 'react-qrcode-logo';
import YouTube from '@material-ui/icons/YouTube';
import Telegram from '@material-ui/icons/Telegram';
import GoogleMeetIcon from '@material-ui/icons/Duo';
import CreditCard from '@material-ui/icons/CreditCard';
import { PayDialog1 } from './D6_halo_cardpltweb1copy';
import DateRangeIcon from '@material-ui/icons/DateRange';

import StorefrontIcon from '@material-ui/icons/Storefront';

export default function CardPltWeb2copy(props) {
  const {name,email,designation,organization,web,mobile,mobile1,image,facebook,instagram,whatsapp,linkedIn,twitter,gitHub,youTube,telegram
    ,googleMeet,color1,headline,location,pay1,pay2,pay3,pay1Value,pay2Value,pay3Value,note,snapChat}=props
    const [color,setColor]=useState('#FF7F50')

    const [active,setActive]=useState(mobile?'m1':mobile1?'m2':'m1')
    const [qr1,setQr1]=useState('card')

  const image2=`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/profile/${props.image1?props.image1:null}`
  const val=`https://halodigital.app/${props.username?props.username:props.key1}`
 
  const val1= qr1=='business'&&props.business&&props.business==1?`https://halodigital.app/business/${props.username?props.username:props.key1}`:`https://halodigital.app/${props.username?props.username:props.key1}`
                                                    
   const handleClose = () => {

    setOpen1(false);
    setOpen2(false)



    setOpen(false);



  };
  const [open, setOpen] = React.useState(false);

  const [open2, setOpen2] = React.useState(false);

  const [open1, setOpen1] = React.useState(false);
  const styles = reactCSS({
    'default': {
      total: {
        width: '100%',
    height:'150px',
    
    backgroundColor:`${color1?color1:color}`,
    background:`linear-gradient(${color},${color1})`,

    // backgroundColor:"linearGradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6))",

    opacity: '2px',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'flex-start',
    /* padding: 0 1.8em; */
    paddingBottom: '0.8em',
    top:'0',
    position: 'absolute',
    /* background-image: url('../../assets/Images/HomeHair.png');
    background-repeat: round; */
    /* background-image: url('../../../assets/Images/HomeHair.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
  borderRadius: '19px',
  alignItems: 'center',
  backgroundSize:'cover',
      }}})
  return (
    <div className="PltDivW2">
    <div className="PltBoxContainerWeb2">
    <div style={styles.total}>
       </div>
       {/* <div className="PltTopContainerWeb2"></div> */}
       {/* <div className="BackDropLeftW">
       </div>
       <div className="BackDropRightW">
       </div> */}
       <div className="PltAvatarCont2">
       <Avatar alt="Remy Sharp" src={props.image1?image2:image?URL.createObjectURL(image):Image} className="PltAvatarPic2"/>
       </div>
       <div className="D6_CARD_PltW2_Grid_Name">
       {name?name:!name?'Your Name':null} 
            </div>
            <Grid items xs={12} className="D6_CARD_PltW2_Grid_headline">
                {headline?headline:!name?'Headline':null}
                </Grid>
            <Grid container >
            <Grid container item xs={8} className="D6_Card_PltW2_BH_Grid">
                {/* <Grid items xs={12} className="D6_CARD_PltW2_Grid_headline">
                {headline?headline:!name?'Headline':null}
                </Grid> */}
                <Grid items xs={12} className="D6_CARD_PltW2_Grid_desig">
                {designation?designation:!name?'Your Designation':null} 
                </Grid>
                </Grid>
            <Grid container item xs={4} className="D6_Card_PltW2_Loc_Grid">
                        <Grid item xs={4}>
                        {
                                  !name||location?
   <Loc onClick={()=>{location.includes('://')?window.open(location,'_blank'):window.open(`https://www.google.co.in/maps/place/${location}`,'_blank')}}/>:null}
                        </Grid>
            </Grid>
            </Grid>
            <div className="D6_CARD_PltW2_Grid_comp">
                    {organization?organization:!name?'Your Organization':null} 
                    </div>
            <Grid container spacing={0} direction='row' item xs={12} >
                <Grid  item xs={8} >
                    <div className="D6_Card_PltW2_ContactData_Grid">
                  
                    <div className="D6_CARD_PltW2_Grid_email">
                    <Grid onClick={()=>window.open(`mailto:${email}`)} container direction="row" alignItems="center" item xs={12}>
                    <Grid item xs={2}>{
                                  !name||email?<WorkEmail /> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                  {email?email:!name?'Your Email':null}
                                </Grid>
                        </Grid>
                    </div>
                    <div className="D6_CARD_PltW2_Grid_web">
                          <Grid  onClick={()=>{web.includes('://')?window.open(web,'_blank'):window.open(`https://${web}`,'_blank')}}container direction="row" alignItems="center" item xs={12}>
                          <Grid item xs={2}>
                                  {!name||web?
                                  <Website/> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                {web?web:!name?'Your Website':null}         
                                </Grid>
                          </Grid>
                    </div>
                    <div className="D6_CARD_PltW2_Grid_tel">
                         <Grid container direction="row" alignItems="center" item xs={12}>
                         <Grid item xs={2}>
                                  {!name||mobile?
                                  <HomePh onClick={()=>setActive('m1')}/>:null}                        
                                </Grid>
                                
                                <Grid item xs={2}>
                                {!name||mobile1?
                                  <WorkPh onClick={()=>setActive('m2')}/>:null}
                                </Grid>
                                <Grid onClick={()=>window.location.href=`tel:${mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:null}`} item xs={8}>
                                {mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:!name?'Your Mobile Number':!mobile&&mobile1?mobile1:!mobile1&&mobile?mobile:null} 
                                </Grid>
                        </Grid>
                          </div>
                    </div>
                  </Grid>
                <Grid container direction='row' item xs={4} className="D6_Card_PltW2_QRCont_Grid">
                
                      <Grid onClick={() => setOpen(!open)} item xs={12} className="D6_Card_PltW2_QR_GridC">
                      <QRCode
          size={90}
          value={props.image1?val:'https://halodigital.app'}
        //   logoImage={logo}
        ecLevel='H'
          logoOpacity='1'
          qrStyle='dots'
          // logoWidth='60'
          quietZone='5'
          fgColor='#61126b'
        
        />           
                      </Grid>
                      <Dialog
                      disableScrollLock={ true }
                          style={{alignItems:'center'}}
                          open={open}
                          // onClose={handleClose}
                          // PaperComponent={PaperComponent}
                          aria-labelledby="draggable-dialog-title"
                        >
                     <Grid item xs={12} className="D6_Card_ClsW1_QR_Grid">
                                          <QRCode
                                          
                              size={200}
                              value={props.image1?val1:'https://halodigital.app'}
                            //   logoImage={logo}
                            ecLevel='H'
                              logoOpacity='1'
                              qrStyle='dots'
                              // logoWidth='60'
                              quietZone='5'
                              fgColor='#000'
                            
                            /> 
                            <Grid container justifyContent='center' sm={12} xs={12}>
<div onClick={()=>{setQr1('card')}} className='change_qr' style={qr1=='card'?{borderBottomStyle:'solid',borderBottomWidth:'2px'}:{borderBottomStyle:"none"}} >Card</div>
{props.business&&props.business==1?<div onClick={()=>{setQr1('business')}} className='change_qr' style={qr1=='business'?{borderBottomStyle:'solid',borderBottomWidth:'2px'}:{borderBottomStyle:"none"}}>Business</div>
:null}
</Grid>

                            <div onClick={()=>{setOpen(false)}} style={{marginTop:'10px',fontWeight:900,backgroundColor:'black',color:'white'}} >Back</div>
                            </Grid>   </Dialog>
                </Grid>
            </Grid>
          </div>
    <Grid container  item xs={12}  alignItems="center" className="PltBackDropBottomW2">
               {/* <Grid container item xs={12}></Grid> */}
                <Grid container spacing={1}  item xs={12} alignItems="center"  >
                <Grid container item xs={12} alignItems="center" >
                  <div className="D6_Bottom_SMCont"></div>
                </Grid>
                {facebook?

<Grid className="D6_Bottom_SM" item xs={2}>
<FacebookIcon onClick={()=>{facebook.includes('://')?window.open(facebook,'_blank'):window.open(`https://www.facebook.com/${facebook}`,'_blank')}} />                        
 </Grid>:null}
 {whatsapp?
 <Grid  className="D6_Bottom_SM" item xs={2}>
                  <WhatsAppIcon onClick={()=>{whatsapp.includes('://')?window.open(whatsapp,'_blank'):window.open(`https://wa.me/${whatsapp}`,'_blank')}} />
 </Grid>:null}
 {linkedIn?
 <Grid className="D6_Bottom_SM" item xs={2}>
                  <LinkedInIcon   onClick={()=>{linkedIn.includes('://')?window.open(linkedIn,'_blank'):window.open(`https://www.linkedin.com/in/${linkedIn}`,'_blank')}}/>
 </Grid>:null}
 {instagram?
 <Grid className="D6_Bottom_SM" item xs={2}>
                  <InstagramIcon onClick={()=>instagram.includes('://')?window.open(instagram,'_blank'): window.open(`https://www.instagram.com/${instagram}`,'_blank')} />                       
 </Grid>:null}
 {gitHub?
 <Grid  className="D6_Bottom_SM" item xs={2}>
                    <StorefrontIcon onClick={()=>{gitHub.includes('://')?window.open(gitHub,'_blank'):window.open(`${gitHub}`,'_blank')}}  />
 </Grid>:null}
 {twitter?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <TwitterIcon  onClick={()=>{twitter.includes('://')?window.open(twitter,'_blank'): window.open(`https://twitter.com/${twitter}`,'_blank')}} />
 </Grid>:null}

 
 {youTube?

<Grid className="D6_Bottom_SM" item xs={2}>
   <YouTube onClick={()=>{youTube.includes('://')?window.open(youTube,'_blank'):window.open(`https://www.youtube.com/watch?v=${youTube}`,'_blank')}} />                        
 </Grid>:null}
 {telegram?
 <Grid  className="D6_Bottom_SM" item xs={2}>
   <Telegram onClick={()=>{telegram.includes('://')?window.open(telegram,'_blank'):window.open(`https://t.me/${telegram}`,'_blank')}} />
 </Grid>:null}
 {googleMeet?
 <Grid className="D6_Bottom_SM" item xs={2}>
   <DateRangeIcon   onClick={()=>{googleMeet.includes('://')?window.open(googleMeet,'_blank'):window.open(`https://meet.google.com/${googleMeet}`,'_blank')}}/>
 </Grid>:null}
 {(pay1&&(pay1Value||props.imageQr1))||(pay2&&(pay2Value||props.imageQr2))||(pay3&&(pay3Value||props.imageQr3))?
              <Grid onClick={()=>setOpen1(true)}  className="D6_Bottom_SM" item xs={2}>
                <AccountBalanceIcon />
              </Grid>:null}
              {note?
              <Grid onClick={()=>setOpen2(true)} className="D6_Bottom_SM" item xs={2}>
                <SpeakerNotesIcon />
              </Grid>:null}
              {snapChat?<Grid onClick={()=>{snapChat.includes('://')?window.open(snapChat,'_blank'):window.open(`https://www.snapchat.com/add/${snapChat}`,'_blank')}}className="D6_Bottom_SM" item xs={2}>
                          <i class="fab fa-snapchat-ghost snap_plt_card"></i>
 </Grid>:null} {snapChat?<Grid onClick={()=>{snapChat.includes('://')?window.open(snapChat,'_blank'):window.open(`https://www.snapchat.com/add/${snapChat}`,'_blank')}}className="D6_Bottom_SM" item xs={2}>
                          <i class="fab fa-snapchat-ghost snap_plt_card"></i>
 </Grid>:null}
            <Dialog
            disableScrollLock={ true }
      open={open1}
      fullWidth
    // maxWidth='sm'
      // style={{width}}
      onClose={handleClose}
      // aria-labelledby="draggable-dialog-title"
      // maxWidth='xl'
    >
<PayDialog1 pay1={pay1} pay2={pay2} pay3={pay3} pay1Value={pay1Value} payValue={pay2Value} pay3Value={pay3Value} pay1ValueN={props.pay1ValueN} pay2ValueN={props.pay2ValueN} pay3ValueN={props.pay3ValueN}
            imageQr1={props.imageQr1} imageQr2={props.imageQr2} imageQr3={props.imageQr3}   /> 
  </Dialog>
  <Dialog
disableScrollLock={ true }
open={open2}
      
    // maxWidth='sm'
      // style={{width}}
      onClose={handleClose}
      // aria-labelledby="draggable-dialog-title"
      // maxWidth='xl'
    >
      <div style={{margin:'10px'}} >
      <TextField 
      
                        value={note}
                        disabled
                        style={{width:'300px'}}
          label="Notes"
          placeholder="write your note here.."
          multiline
          rows={7}

          variant="outlined"
        />        
        </div>
  </Dialog>

                </Grid>
            </Grid>
    </div>
  );
}
