import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import {NavLink} from "react-router-dom";
import "../../../MainCSS.css"
import { D6_sl_MenuList } from './D6_sl_MenuList';
import {useCookies} from 'react-cookie'
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from  '@material-ui/icons/ArrowDropDown';



import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';




const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  color: white;
  margin-right: 90px;
  font-size: 16px;
  li {
    // padding: 18px 10px 2px;
    padding: 40px 20px;
  }
  li a {
    text-decoration: none;
    color: white;
  }
  li a:hover {
    color: var(--ycolor);
  }
  @media (max-width: 990px) {
    padding-top: 0.75rem;
    
      li {
    padding: 27px 10px;
    }
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    text-align: center;
    flex-flow: column;
    padding-right: 0px;
    background-color: rgb(0,0,0,0.9);
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    top: 90px;
    right: 0;
    z-index: 1000;
    height: 350px;
    width: 100%;
    padding-top: 26px;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
      padding: 18px 10px;
    }
  }
`;
const D6_SL_Menu = (props) => {
  const{live}=props
  const [anchorEl, setAnchorEl] = React.useState(null);
const {open}=props
  const[auth,setAuth,deleteAuth]=useCookies(['auth'])

  const[category,setCategory]=useState(auth&&auth.auth?auth.auth.country.toLowerCase():'usa')
  const[categories,setCategories]=useState([])

useEffect(() => {
 if(auth.auth){
   setCategory(auth.auth.country.toLowerCase())
 }
}, [auth.auth])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // useEffect(() => {
  //  fetch(live+'country',{
  //  method:'GET'
  // })
  // .then(res=>res.json())
  // .then((resp)=>setCategories(resp))
  // }, [])

  // const [anchorEl, setAnchorEl] =useState(null);
  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  //   console.log('target',event.currentTarget)
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const openp = Boolean(anchorEl);
  // console.log(anchorEl)
 
const logout=()=>{
  deleteAuth(['auth'])
  window.location.href='/'
}
const Category=(value)=>{

  setCategory(value)
  props.country(value)

}

  return (
    <Ul open={open} className="D6_SL_Navbar_List">
  
    
                        <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/">
                              {D6_sl_MenuList[0]}
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/business">
                            {D6_sl_MenuList[1]}                           
                             </NavLink>
                        </li> */}
                        <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/pricing">
                            {D6_sl_MenuList[2]}                            
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/faq">
                            {D6_sl_MenuList[3]}                         
                             </NavLink>
                        </li>{!auth.auth?
                        <li>
                            <NavLink className="D6_SL_Navbar_Link1" activeClassName="active" exact to="/signin">
                            {D6_sl_MenuList[4]}                         
                             </NavLink>
                        </li>:<li >
                        <div  className='select' >

                         <NavLink    className='button-name' exact to="#" aria-controls="simple-menu" aria-haspopup="true" >
        {auth.auth.fname?auth.auth.fname:auth.auth.lname?auth.auth.lname:''}
      </NavLink>
      <ArrowDropDownIcon className="btn-d" onClick={(e)=>handleClick(e)}/>
      </div>
      <Menu
        className="name_menu"
        
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        disableScrollLock={ true }
        onClose={handleClose}
      >
        <MenuItem onClick={()=>window.location.href='/dash'}>My account</MenuItem>
        <MenuItem onClick={()=>logout()}>Logout</MenuItem>
      </Menu>
                            {/* <div    className="D6_SL_Navbar_Link1"  activeClassName="active" >

                            Sign Out  
                             </div> */}
                        </li>}
                        {/* <li >
  <select  id = "dropdown"  value={category} onChange={(e)=>Category(e.target.value)}>
   
  {categories.sort((a, b) => a.ID > b.ID ? 1 : -1).map((category, idx) =>
                       {
                         return <option key={idx} value={category.name} >{category.countryCode}</option>
                            
                         
                       }

             )  }
</select><br />
                         
                       

  

        </li>   */}
                       
    </Ul>
  )
}


export default D6_SL_Menu