import React, { useState ,useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import {useCookies} from 'react-cookie'
import './profile.css'
import {  Grid,Button } from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';



export default function MyProf(props) {
  const {cards,business,points,live,template}=props
  const[filter,setFilter]=useState([])

  const[auth]=useCookies(['auth'])
  useEffect(() => {
  if(!auth.auth){
    window.location.href='/signin'
  }
  }, [auth.auth])
  useEffect(()=>{ if(auth.auth&&cards[0]){
    //  console.log('',value)
        setFilter(cards.filter((count)=>
        (count.memberID.toString().includes(auth.auth.memberID.toString()))
      ))}else{
          setFilter(null)
      }
        
    },[cards,auth.auth])

  
  return (
  <Grid container  spacing={1} item xs={12} className="D6_ProfileCont">
        {/* <Grid item xs={12} className="D6_ProfBottom"> </Grid> */}

      <Grid item sm={4} xs={12} className="D6_ProfileContGrid">
      <Paper className="D6_ProfileCardGrid" elevation={3}>
          <Grid item xs={12} >
          <Avatar alt="Remy Sharp" style={{width:'90px',height:'90px',marginInline:'auto'}}  />

          </Grid>
          <Grid item xs={12} style={{margin:'10px'}}>
            Cards created:{filter?filter.length:0}
            </Grid>
            <Grid item xs={12} style={{margin:'10px'}}>
              Available cards: {auth.auth.cards?auth.auth.cards:0}
            </Grid>
            {points&&points.points?<Grid item xs={12} style={{margin:'10px'}}>
              Halo Points: {points.points}
            </Grid>:null}
            <Grid item xs={12} style={{margin:'10px'}}>
              Referal Code: {points.referal}
            </Grid>
            <Grid item xs={12} style={{margin:'10px'}}>
               Business profiles created: {business.length}
            </Grid>
            <Grid item xs={12} style={{margin:'10px'}}>
              Available Business profiles: {auth.auth.profiles?auth.auth.profiles:0}
            </Grid>
           
           
            <Grid item xs={12} style={{margin:'30px'}}>
            <Button  onClick={()=>window.location.href='/'} style={{fontWeight:'lighter',width:'50%',backgroundColor:'#61126b',textTransform:'none',margin:'10px',alignItems:'center'}}>
                  Create new card
                  </Button>
            </Grid>
 
      </Paper>

    </Grid>
    {auth.auth?

    <Grid item sm={6} xs={12} className="D6_ProfileContGrid">
          <Paper   elevation={3} className="D6_ProfileGrid">

        {/* <Paper className="D6_ProfileGrid"> */}
          {/* <Grid   container style={{margin:'20px'}}> */}
          <Grid   container className="D6_ProfileData">
            <Grid  item xs={4}>
              Name
            </Grid>
            <Grid item xs={8}>
{auth.auth.fname+' '+auth.auth.lname}            </Grid>
          </Grid>
          <Grid  container className="D6_ProfileData">
            <Grid item xs={4} >
              Email
            </Grid>
            <Grid item xs={8}>
              {auth.auth.email}
            </Grid>
          </Grid>
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
              Mobile
            </Grid>
            <Grid item xs={8}>
            {auth.auth.phonenumber}
            </Grid>
          </Grid>
          {auth.auth.addLine1?
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
              Address
            </Grid>
            <Grid item xs={8}>
              {auth.auth.addLine1+', '+auth.auth.city+', '+auth.auth.stat}
            </Grid>
          </Grid>
          :null}
          {auth.auth.country?
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
              Country
            </Grid>
            <Grid item xs={8}>
              {auth.auth.country}
            </Grid>
          </Grid>:null}
          </Paper>
          <Paper  elevation={4} className="D6_ProfilePayGrid">
          <Grid className="D6_ProfileData" container>
            <Grid item xs={4}>
              Current plan
            </Grid>
            <Grid item xs={8}>
              {auth.auth.planCategory?((auth.auth.proPlan?'Halo Pro':auth.auth.planCategory)+' '+auth.auth.planType):"No plan selected"}
            </Grid>
          </Grid>
     
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
             {auth.auth.planCategory?'Plan purchase':'Account created'}
            </Grid>
            <Grid item xs={8}>
              {auth.auth.createdDate}
            </Grid>
          </Grid>
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
              Plan expiry
            </Grid>
            <Grid item xs={8}>
              {auth.auth.expiryDate?auth.auth.expiryDate:'NA'}
            </Grid>
          </Grid></Paper>


          {template?<Paper  style={{height:'auto'}} elevation={4} className="D6_ProfilePayGrid">
          <Grid className="D6_ProfileData" container>
            <Grid item xs={4}>
              Halo Smart Pass 
            </Grid>
            <Grid item xs={8}>
              Prime
            </Grid>
          </Grid>
     
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
            Purchased On
            </Grid>
            <Grid item xs={8}>
              {template.createdDate}
            </Grid>
          </Grid>
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
              Expiring On
            </Grid>
            <Grid item xs={8}>
              {template.expiryDate}
            </Grid>
          </Grid>
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
              Passes Issued
            </Grid>
            <Grid item xs={8}>
              {template.passes}
            </Grid>
          </Grid>
          <Grid container className="D6_ProfileData">
            <Grid item xs={4}>
              Passes Available
            </Grid>
            <Grid item xs={8}>
              {template.passesAvailable}
            </Grid>
          </Grid>
          </Paper>:null}

        {/* </Paper> */}
    </Grid>:null}

    <Grid item xs={12} className="D6_ProfBottom"> 
    </Grid>

  </Grid>
 
  )
}