import React ,{useState,useEffect,useRef}from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './site_preview.css';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useCookies} from 'react-cookie'
import { CardActionArea } from '@mui/material';
import {Container,Button} from "@mui/material";

import Chip from '@material-ui/core/Chip';

import Site from '../customer_site/site'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import StripePay from '../Payments/stripePay';
import Card from '@mui/material/Card';
import Img1 from '../../assets/Images/HomeHair1.png'
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import * as htmlToImage from 'html-to-image';
import { QRCode } from 'react-qrcode-logo';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ComponentToPrint = React.forwardRef(({companyData}, ref) => (
  <div   className='qr-code' ref={ref}>
        <QRCode
          size={160}
          value={`https://halodigital.app/business/${companyData.username?companyData.username:companyData.siteID}`}
        //   logoImage={logo}
        ecLevel='H'
          logoOpacity='1'
          qrStyle='dots'
          logoWidth='60'
          quietZone='5'
          fgColor='#61126b'
        
        />
  </div>
));
const exportAsPicture = (val) => {
    

  var data = document.getElementsByClassName(val)

  for (var i = 0; i < data.length; ++i){
      htmlToImage.toPng(data[i]).then((dataUrl) => {
        saveAs(dataUrl,val);
      });
  }



}
const saveAs = (blob, fileName) =>{
  var elem = window.document.createElement('a');
  elem.href = blob
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    }));
  }
  URL.revokeObjectURL(elem.href);
  elem.remove()
}
export default function Site_Preview(props) {

  const {live,countryF,dcard_url,username,companyData}=props
  const[auth,setAuth]=useCookies(['auth'])
    
    

  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [labelC,setLabelC]=useState('name')
  const [label1,setLabel1]=useState('name')

  const [edit,setEdit]=useState(companyData?false:true)
const [country,setCountry]=useState([])
const [filter,setFilter]=useState([])
const [processing,setProcessing]=useState(false)
const [processing1,setProcessing1]=useState(false)
const [deleting,setDeleting]=useState('')
const [site1,setSite1]=useState(false)
const componentRef = useRef();

const image1=`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/site/${companyData.image?companyData.imageN:null}`
  const[company,setCompany]=useState({
      name:companyData.siteName?companyData.siteName:'',
      subHead:companyData.subHeading?companyData.subHeading:'',
      moreInfo:companyData.moreInfo?companyData.moreInfo:'',
      image:'',
      imageC:companyData.image?image1:'',
      companyID:'',
      live:companyData.live?companyData.live:1
  })

  const[product1,setProduct1]=useState({
    name:'',
    type:'',
    category1:'',
    category2:'',
    moreInfo:'',
    contactLink:'',
    contactIcon:'',
    image:'',
    orderLink:'',
    productID:'',
    email:'',
    live:1



})
const [checkedW,setCheckedW]=useState((companyData&&companyData.live===0)?false:company.live===0?false:true)
const checkedWeb=()=>{
  setCheckedW(!checkedW)
}
const [checkedW1,setCheckedW1]=useState(product1.live===1?true:product1.live===0?false:true)
const checkedWeb1=()=>{
  setCheckedW1(!checkedW1)
}
const [productC,setProductC]=useState('')


  
const [products,setProducts]=useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

const [open1,setOpen1]=useState(false)
const [open2,setOpen2]=useState(false)
const [open3,setOpen3]=useState(false)

const handleClose=()=>{
  setOpen1(false)
  setOpen2(false)
  setOpen3(false)
}
const [text,setText]=useState('')
 const ImageCover=(img)=>{
  if(img&&img.name)

  if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  
  {
    if(img.size/1048576<4){
setCompany({...company,image:img})   
  }
   else{
  alert('File should be <4MB')
     }
   } 
   else{
    alert('Upload jpg|jpeg|png format image')

   }
}
const ProductImg1=(img)=>{
  if(img&&img.name)

  if (img.name.toLowerCase().match(/\.(jpg|jpeg|png)$/))
  
  {
    if(img.size/1048576<4){
setProduct1({...product1,image:img})   
  }
   else{
  alert('File should be <4MB')
     }
   } 
   else{
    alert('Upload jpg|jpeg|png format image')

   }
}

const Label1=(val)=>{
  setProduct1({...product1,contactIcon:val})
  setLabel1(val)
}




async function CountryFilter(){
  let filter1=await country.filter((count)=>

  (count.planCategory.toLowerCase().includes('classic'))&&
  (count.planType.toLowerCase().includes('individual'))&&
  (count.countryName.toLowerCase().includes(auth.auth.country.toLowerCase()))



)
if(filter1[0]){
setFilter(filter1)}
else{
setFilter(country.filter((count)=>
(count.planCategory.toLowerCase().includes('classic'))&&
(count.planType.toLowerCase().includes('individual'))&&

(count.countryName.toLowerCase().includes('usa'))))
}
}

useEffect(() => {

    fetch(live+'c-list',
    {
    method: 'GET',
    headers:{
      'Content-Type' :'application/json'  
    },
  })
  .then(res => res.json())
  .then(resp=>setCountry(resp))
  
    
  },[])

useEffect(()=>{ 
  if(country[0]&&auth.auth){


if(countryF){
setFilter(country.filter((count)=>
(count.planCategory.toLowerCase().includes('classic'))&&
(count.planType.toLowerCase().includes('individual'))&&

(count.countryName.toLowerCase().includes(countryF))



))



}
if(!countryF){
CountryFilter()

// if(!filter[0]){





// }

}

  }

else{
  setFilter([])
}
      
  },[country,countryF,auth.auth])
useEffect(() => {

    fetch(live+`products?q=${dcard_url}`,
    {
    method: 'GET',
    headers:{
      'Content-Type' :'application/json'  
    },
  })
  .then(res => res.json())
  .then(resp=>setProductC(resp))
  
    
  },[processing,processing1,deleting])

const SaveCompany=()=>{
    if((!companyData&&!company.image)){
      alert('Please select background image')
    }
    else{
  setProcessing(true)

  const formData=new FormData()
  formData.append('name',company.name)
  formData.append('siteID',dcard_url)
  formData.append('username',username)

  formData.append('type',company.type)
  formData.append('subHead',company.subHead)

  formData.append('moreInfo',company.moreInfo)
  formData.append('memberID',auth.auth.memberID)


  formData.append('image',company.image)
  formData.append('id',companyData.ID?companyData.ID:company.companyID?company.companyID:'')
  
  formData.append('live',checkedW?1:0)

  fetch(live+'site'
,
{

  method:'POST',

  body:formData
  })

  .then(res=>res.json())
  .then((resp)=>SaveRes(resp))


  .catch(error=>console.log(error))

}}
const SaveRes=(res)=>{
  if(res.data){
    setAuth('auth',res.data1,{
      maxAge:3600 ,
      expires:0
   })
    setCompany({
      ...company,name:res.data.siteName,subHead:res.data.subHeading,
      moreInfo:res.data.moreInfo,image:res.data.image,imageC:`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/site/${res.data.imageN}`,
    companyID:res.data.ID,
    live:res.data.live
  })
  // setCheckedW(res.data===1?true:false)
    setProcessing(false)
    setEdit(false)
setSite1(true)
   setText(res.detail1)
   setOpen2(true)
  }
  else{
    setProcessing(false)
    setEdit(false)
setText(res.detail)
setOpen1(true)
  }
}
const Products=(val)=>{
  if(products){
    if(!product1.productID&&!product1.image){
      alert('Please select product image')
    }
    else{
    setProcessing1(true)
  const formData=new FormData()
  formData.append('name',product1.name)
  formData.append('siteID',dcard_url)
  formData.append('type',product1.type)

  formData.append('info',product1.moreInfo)
  formData.append('category1',product1.category1)

  formData.append('category2',product1.category2)
  formData.append('image',product1.image)
  formData.append('contact',product1.contactLink)
  formData.append('contactType',product1.contactIcon)
  formData.append('orderLink',product1.orderLink)
  formData.append('productID',product1.productID?product1.productID:'')
  formData.append('email',product1.email)

  formData.append('live',checkedW1?1:0)




  fetch(live+'product'
,
{

  method:'POST',

  body:formData
  })

  .then(res=>res.json())
  .then((resp)=>SavePro(resp))


  .catch(error=>console.log(error))
    }
  }
  if(val){
    setProduct1({...product1,
      name:val.productName,type:val.productType,category1:val.category1,
      category2:val.category2,moreInfo:val.productInfo,
      contactLink:val.contact,contactIcon:val.contactType,
      orderLink:val.orderLink,live:val.live,productID:val.productID,
      email:val.email?val.email:''
   
      
    })
    setCheckedW1(val.live===1?true:false)
    setProducts(true)
  }
  
  else{

    // setProductID(val)
    setProducts(true)
  }
}
const SavePro=(res)=>{
  if(res){
    setProcessing1(false)
    setProducts(false)
setProduct1({
  ...product1,productID:'',image:''
})
    Snack(res)
  }
}

const Snack=(val)=>{
  if(val.detail==='Success'){
   Text1(val.detail1)
  }
  else{
    setText(val.detail1)
    setOpen1(true)
  }
}
const  Text1=(val)=>{
  setText(val)
  setOpen2(true)
}
const Delete=(val)=>{
  setDeleting(val.productID)
  const formData=new FormData()
  formData.append('productID',val.productID)
  fetch(live+'product'
,
{

  method:'DELETE',

  body:formData
  })

  .then(res=>res.json())
  .then((resp)=>DeleteRes(resp))


  .catch(error=>console.log(error))

}
const DeleteRes=(res)=>{
  if(res){
    setDeleting(false)
    Deleting(res.detail)
  }



}

const Deleting=(val)=>{
  if(val==='Success'){
    setOpen3(true)
  }
  else{
    setText(val)
    setOpen1(true)
  }
    }
const createB=()=>{
  setSite1(true)
}
  return (
    <div className="Site_PreviewCont_Grid">
      {filter[0]&&!props.site&&!site1&&auth&&auth.auth.profiles<1?
      <StripePay filter={filter} cat='business page'  cardID={dcard_url} createB={createB}  plan='business' live={live} />
     
      :!props.site&&auth&&auth.auth.profiles>0||props.site||site1?
      <>
       <AppBar position="static" className="Site_PreviewAppBar">
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Business Preview" {...a11yProps(0)} />
          {/* <Tab label="Web View" {...a11yProps(1)} /> */}
        </Tabs>
      </AppBar>
  
      
    
  

  
   
      <Grid container spacing={0}  >
      <Snackbar open={open1}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        <AlertTitle>Alert!!</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>{text}</strong>
      </Alert>
      </Snackbar>
      <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>{text}</strong>
      </Alert>
      </Snackbar>
      <Snackbar open={open3}  anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        <AlertTitle>Success</AlertTitle>
        {/* <strong>Your plan will expire soon, please recharge or upgrade your plan!</strong> */}
        <strong>Product Deleted Successuflly</strong>
      </Alert>
      </Snackbar>
            <Grid container direction='row' item   >
              <>
                <Grid  item sm={12} xs={12}>
                      <Paper className="Site_Preview_Grid">
                      <div className="Site_Preview_BillInfo_head">
                      Please fill in your details
                    </div>
                      <div className="Site_Preview_Req_Form" >
                        <Grid container  alignItems="center">
                         {/* <Grid container className="Site_Preview_Opt_Fields"> */}
                         <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip   onClick={()=>setLabelC('name')} className='Site_PreviewC' label="Business Name"  />
                          </Grid>
                          <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabelC('sub heading')} className='Site_PreviewC' label="Sub Heading"  />
                          </Grid>
                          <Grid item sm={3}  xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabelC('more info')} className='Site_PreviewC' label="More Info About Business"  />
                          </Grid>
                          <Grid item sm={3}  xs={6} className="Site_Preview_Opt_Fields">
        <FormGroup style={{justifyContent:'center',marginTop:10}}  row>
      <FormControlLabel
        control={<Switch size='medium' className="switchD" edge="start" disabled={!edit} checked={checkedW} onChange={checkedWeb} />}
        label="Active"
      />
      </FormGroup>
          </Grid>
        {companyData&&(companyData.username||companyData.siteID)?<Grid item sm={3}  xs={6} className="Site_Preview_Opt_Fields">
                          < Chip  onClick={()=>setLabelC('Qrcode')} className='Site_PreviewC' label="Business QR Code"  />
                        </Grid>:null}
                       {labelC=='Qrcode'?<>
                       <Grid item xs={12}>
        <ComponentToPrint companyData={companyData} ref={componentRef}  />
        <Grid container justifyContent='center' >
              <Grid  onClick={() => exportAsPicture('qr-code')}item sm={6} xs={12}>
                <Paper style={{justifyContent:'center'}} className="D6_DWNLD_ButtonNewCard">Save QR Code</Paper>
              </Grid>
            </Grid>
            </Grid>
 
        </>:<Grid item xs={12}>
                        <TextField className="D6_Card_Opt_FieldsText" value={(labelC==='name'?company.name:labelC==='sub heading'?company.subHead:company.moreInfo)} 
                            label= {"Business"+' '+labelC} disabled={!edit} onChange={(e)=>{
                                labelC==='name'?setCompany({...company,name:e.target.value}):labelC==='sub heading'?setCompany({...company,subHead:e.target.value}):setCompany({...company,moreInfo:e.target.value})
                            }}  autoFocus size="small" 
                          variant="outlined"></TextField>
                        </Grid>}
                       
                         
                          
                          {/* </Grid> */}
                          
                       

                          
                        
                       
                       
 
                     
                        <Grid container  item  >
            
                          {/* <label for="files" class="btn">Select Image</label>
                          <input  type="file" onChange={(e)=>setImage1(e.target.files[0])}/><br /> */}
                         {!companyData&&!company.companyID||(companyData||company.companyID)&&edit? <Grid  style={{marginTop:'5px'}} item sm={4} xs={12}  className="Site_Preview_Opt_Fields">
                            <div style={{ display:'flex',justifyContent:'space-around',backgroundColor: '#f51464',borderRadius:'30px',height:'40%',paddingTop:'5px'}}>
                          <label for="filesC" style={{fontSize:'small',fontWeight:'bold' ,color: 'white',cursor:'pointer'}} >{company&&company.image&&company.image.name?'Uploaded':'Background Image'}</label>
                          </div>

                          <input type="file"  id="filesC" style={{visibility:'hidden'}} onChange={(e)=>ImageCover(e.target.files[0])} /><br />
                          </Grid>:(companyData||company.companyID)&&!edit?<Grid  style={{marginTop:'5px'}} item  className="Site_Preview_Opt_Fields">
                          < Chip  onClick={()=>setEdit(true)} className='Site_Preview_E' label="Edit Details"  />
                            </Grid>:null}
                        </Grid>
                        </Grid>
                      </div>
                      <Grid container justifyContent='center'>
                      {edit&&companyData?< Chip style={{marginBottom:'10px',marginRight:'10px'}}  className='Site_PreviewC' onClick={()=>SaveCompany()} label={!processing?"Save Business Details":"Saving"}  />:
 !companyData&&!company.companyID?< Chip style={{marginBottom:'10px'}}  className='Site_PreviewC' onClick={()=>SaveCompany()} label={!processing?"Create Business":'Creating'}/>:null}
{edit&&!processing&&companyData?< Chip style={{marginBottom:'30px'}}  className='Site_PreviewC' onClick={()=>setEdit(false)} label='Cancel'/>:null}
</Grid>

                     {productC&&productC[0]?<Grid container justifyContent="center" alignItems="center" fixed className="Site_GridS"   >

{productC.map((product,idx)=>{
return(
  <Grid item style={{textAlign:'-webkit-center'}} lg={4} sm={12} md={4} xs={12} key={idx}  className="Site_Grid1"  >
  <Container className="Site_Cont_P">
  <Card sx={{ maxWidth: 345 ,borderRadius:'10px !important'}}>
<CardActionArea style={{display:'block'}}>
<CardMedia
component="img"
//  height="240"
className='Site_IMG'
image={product&&product.image?`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/products/${product.imageN}`:Img1}

alt="green iguana"
/>
<CardContent className='card_color'>
<Typography gutterBottom variant="h5" className='Product_n' component="div">
{product&&product.productName?product.productName:'Product Name'}
</Typography>
{product&&product.productType?<Typography className='card_text'  variant="body2">
{product&&product.productType?product.productType:'Product Type'}
</Typography>:null}

{product&&product.productInfo?<Accordion className='Site_ACC2'>
<AccordionSummary
expandIcon={<ExpandMoreIcon style={{marginBottom:'20px'}} />}
aria-controls="panel1a-content"
id="panel1a-header"
className='Site_Summ'
>
<Typography className="Site_Head2">Product Info</Typography>
</AccordionSummary>

<AccordionDetails className='Site_Detail'>

<Typography   className='Site_Detail1' >
{product&&product.productInfo?product.productInfo:'This section includes additional information about the category/product'}
</Typography>

</AccordionDetails>

</Accordion>:null }
<Grid container  className='grid_chip' >

{ product&&product.category1?<Chip   className='Site_chip' color="secondary" label={product&&product.category1?product.category1:"Category1" } />
:null} {product&&product.category2?<Chip   className='Site_chip'  color="secondary" label={product&&product.category2?product.category2:"Category2" } />:null}
 
</Grid>
<Grid container  className='grid_chip' >

{product&&product.orderLink?<Chip   className='Site_chip'  color="secondary" label="Order" onClick={()=>window.open(product.orderLink,'_blank')} />
:null}<Chip   className='Site_chip1'  color="secondary" label={product.live===1?"Active":'Inactive'}  />

{product&&product.contactType==='Whatsapp'?<>
<i  onClick={()=>window.open(`https://wa.me/${product.contact}`)}className="fab fa-whatsapp Icon_Right1_S1"></i></>
:product&&product.contactType==='Contact'?<>
<i  onClick={()=>window.open(`tel:${product.contact}`)}className="fas fa-mobile-alt Icon_Right1_S"></i>
</>:null}
{product&&product.email?
<i  onClick={()=>window.open(`mailto:${product.email}`)}className="fas fa-envelope Icon_Right1_S"></i>:null}



</Grid>

</CardContent>
</CardActionArea>
</Card>
<Grid container  justifyContent='center'  className='grid_chip' >

 <Chip   className='Site_chip1' color="secondary" onClick={()=>Products(product)} label="Edit Product" />
 <Chip   className='Site_chip'  color="secondary" onClick={()=>Delete(product)} label={deleting===product.productID?"deleting":'Delete Product'} />
 
</Grid>
  </Container>
</Grid>
)
})
                     
}
                       </Grid>:null}
                      {/* <Paper className="Site_Preview_Grid"> */}
                      {products?  <>   <div className="Site_Preview_BillInfo_head">
                      Product 
                    </div>
                      <div className="Site_Preview_Req_Form" >
                        <Grid container spacing={0} alignItems="center">
                         {/* <Grid container className="Site_Preview_Opt_Fields"> */}
                         <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabel1('name')} className='Site_PreviewC' label="Product Name"  />
                          </Grid>
                          <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabel1('type')} className='Site_PreviewC' label="Product Type"  />
                          </Grid>
                          <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabel1('more info')} className='Site_PreviewC' label="Product Info"  />
                          </Grid>
                          <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabel1('category1')} className='Site_PreviewC' label="Product Category1"  />
                          </Grid>
                          <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabel1('category2')} className='Site_PreviewC' label="Product Category2"  />
                          </Grid>
                         
                          <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabel1('order link')} className='Site_PreviewC' label="Order Link"  />
                          </Grid>
                          <FormControl  component="fieldset">
                  
                  <RadioGroup row value={product1&&product1.contactIcon?product1.contactIcon:null} onChange={(e)=>Label1(e.target.value)}  name="row-radio-buttons-group">
                <FormControlLabel className='radio_text' value="Whatsapp" control={<Radio />} label="Whatsapp" />
                    <FormControlLabel className='radio_text' value="Contact" control={<Radio />} label="Contact No." />
                    {/* <FormControlLabel value="Paytm" control={<Radio />} label="Paytm" /> */}
                   
                  </RadioGroup>
                </FormControl>
                <Grid item sm={3}  xs={6} className="Site_Preview_Opt_Fields">
        <FormGroup style={{justifyContent:'center',marginTop:10}}  row>
      <FormControlLabel
        control={<Switch size='medium' className="switchD" edge="start"  checked={checkedW1} onChange={checkedWeb1} />}
        label="Active"
      />
      </FormGroup>
          </Grid>
          <Grid item sm={3} xs={6} className="Site_Preview_Opt_Fields">
                            < Chip  onClick={()=>setLabel1('Email')} className='Site_PreviewC' label="Email"  />
                          </Grid>
                          <Grid item xs={12}>
                        <TextField className="D6_Card_Opt_FieldsText"  autoFocus size="small" 
                       value={(label1==='Email'?product1.email:label1==='name'?product1.name:label1==='type'?product1.type:label1==='more info'?product1.moreInfo:label1==='category1'?product1.category1:label1==='category2'?product1.category2:label1==='order link'?product1.orderLink:label1==='Whatsapp'?product1.contactLink :label1==='Contact'?product1.contactLink:product1.contactLink)} 
                       label= {label1==='Contact'||label1==='Whatsapp'?(label1+' '+'number (including countrycode)'):("Product's"+' '+label1)}
                       onChange={(e)=>{
                        label1==='name'?setProduct1({...product1,name:e.target.value}):label1==='Email'?setProduct1({...product1,email:e.target.value}):label1==='type'?setProduct1({...product1,type:e.target.value}):label1==='more info'?setProduct1({...product1,moreInfo:e.target.value}):label1==='Contact'||label1==='Whatsapp'?setProduct1({...product1,contactLink:e.target.value}):label1==='category1'?setProduct1({...product1,category1:e.target.value}):label1==='category2'?setProduct1({...product1,category2:e.target.value}):label1==='order link'?setProduct1({...product1,orderLink:e.target.value}):setProduct1({...product1,contactLink:e.target.value})
                    }}
                       variant="outlined"></TextField>
                        </Grid>
                       
                     
                        <Grid container spacing={1} item  >
            
                       
                          <Grid  style={{marginTop:'5px'}} item sm={4} xs={12}  className="Site_Preview_Opt_Fields">
                            <div style={{ display:'flex',justifyContent:'space-around',backgroundColor: '#f51464',borderRadius:'30px',height:'40%',paddingTop:'5px'}}>
                          <label for="files12" style={{fontSize:'small',fontWeight:'bold' ,color: 'white',cursor:'pointer'}} >{product1&&product1.image&&product1.image.name?'Uploaded':'Product Image'}</label>
                          </div>

                          <input type="file"  id="files12" style={{visibility:'hidden'}} onChange={(e)=>ProductImg1(e.target.files[0])}  /><br />
                          </Grid>
                          
                    
                        </Grid>
                     
                        </Grid>
                      </div>
                      </>:null}
                   
              

                    
      
      
      
    
      
      

      
        <Grid container  style={{justifyContent:'center'}}   >

       < Chip   style={{marginRight:'10px'}} onClick={()=>Products()}   className='Site_PreviewC' label={!products?"Add Products":processing1?'Saving':'Save product'}  />
      {products&&!processing1?< Chip   style={{marginRight:'10px'}} onClick={()=>setProducts(false)}   className='Site_PreviewC' label='Cancel' />:null} 

        </Grid>
        
        
        </Paper>
      

                </Grid>
     
                </>
        </Grid>
      </Grid>
      <div style={{marginTop:'20px'}}>
        <Site company={company} live={live} productC={productC} product1={product1}   />
      </div>

       </>
       :<Box sx={{ display: 'flex',justifyContent:'center' ,paddingTop:'20px'}}>
      <CircularProgress />
    </Box>}
    </div>

  );
}
