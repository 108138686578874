import React,{useState} from 'react'
import   {BoxContainer,SubmitButton} from './D6_FE_CommonElements';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Marginer } from "./Marginer";
import './D6_OTPPage_CSS.css'
import { sha256 } from 'js-sha256';

import './D6_FE_LoginForm_CSS.css'
import './D6_FE_SignUpForm_CSS.css';

const D6_ForgotPassword = (props) => {
  const{live}=props
    const email=props.email
    const[processing,setProcessing]=useState(null)

      const [value,setValue]=useState('');
      const [label,setLabel]=useState(null);

      const [value2,setValue2]=useState('');
      var hash=sha256(value)
      var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))).{6,12}$");

const Value=(val)=>{
  setLabel(null)
  setValue(val)
  if(val.length>6&&!mediumRegex.test(val)){
    setLabel('Password should contain [a-z] [A-Z] [0-9] [6,12]')
  }
}
const Value2=(val)=>{
  setLabel(null)
  setValue2(val)
  if(val.length>6&&!mediumRegex.test(val)){
    setLabel('Password should contain [a-z] [A-Z] [0-9] [6,12]')
  }
}


      
      const onSubmit = ()=> {if(value===value2){
        setProcessing('true')

        //  fetch('http://127.0.0.1:8000/forpass',
        fetch(live+'forpass',

        {
        method: 'PUT',
        headers:{
        'Content-Type' :'application/json'
        },
        body: JSON.stringify({email:email ,password:hash})
        })
        .then(res => res.json())
      .then(res=> forgotRes(res))
      }
         else{
              setLabel("Password doesn't match")
         }
         const forgotRes=(res)=>{
          setProcessing('true')

           if(res.message=='Password updated'){

             window.location.href='/signin'
           }
           
         }
      };

    

    return (
    <>
         
    <BoxContainer>
    <div className="SignIn_Form_Div">
            <ValidatorForm
                //useRef="form"
             onSubmit={onSubmit}
               
            >

            <div className="D6_SIForm_TV_Div">
              <label className="D6_SIForm_Label">New Password</label>
              <TextValidator
                      label=""
                      value={value}     
  
                      onChange={(e)=>Value(e.target.value)}
                      name="password"
                      type="password"
                      validators={['required']}
                      errorMessages={['Password is required']}
                  />
            </div>

            <div className="D6_SIForm_TV_Div">
                <label className="D6_SIForm_Label"> Confirm New Password</label>
               <TextValidator
                    label=""
                    value={value2}     

                    onChange={(e)=>Value2(e.target.value)}
                    name="password"
                    type="password"
                    validators={['required']}
                    errorMessages={['Password is required']}
                   
                />
            </div>
            {(!label?null:value&&value2?<label className="D6_SIForm_errorpass">Password doesn't match</label>:null)}

          <Marginer direction="vertical" margin="1.6em" />
          <SubmitButton disabled={processing} type="submit">{!processing?'Change Password':'Processing'}</SubmitButton>
          <Marginer direction="vertical" margin="1em" />
          </ValidatorForm>
        </div>
     </BoxContainer>
    </>
    )
    
}

export default D6_ForgotPassword
