export const Array=[
    {id:1,
       name:'Likes'


    },
    {id:2,
        name:'AAAAA'

    },
    {id:3,
        name:'Popularity'
    },
    {id:4,
        name:'Today'

    },
    {id:5,
        name:'ABCD'

    },
    {id:6,
        name:'EFGH'

    }
]