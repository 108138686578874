import React,{useEffect,useRef} from 'react'
import Grid from '@material-ui/core/Grid';
import './D6_halo_pricingplt.css'

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useCookies} from 'react-cookie'
import TopUPPricing from './D6_halo_topUpP';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
export default function TopUp(props) {
  const{live,scroll1,cards}=props
    const [value, setValue] = React.useState(0);
    const{country,countryF}=props
    const[auth,setAuth]=useCookies(['auth'])
    const[filter,setFilter]=React.useState([]);

    const myRef=useRef(null)

async function CountryFilter(){
  if(auth.auth){
  let filter1=await country.sort((a, b) => a.id > b.id ? 1 : -1).filter((count)=>

  (count.planCategory.toLowerCase().includes('halo topup'))
  // &&(count.countryName.toLowerCase().includes(auth.auth.country.toLowerCase()))



)
if(filter1[0]){
setFilter(filter1)}
else{
setFilter(country.sort((a, b) => a.id > b.id ? 1 : -1).filter((count)=>
(count.planCategory.toLowerCase().includes('halo topup'))
// &&(count.countryName.toLowerCase().includes('usa'))
))
}
  }
  else{
    setFilter(country.sort((a, b) => a.id > b.id ? 1 : -1).filter((count)=>
(count.planCategory.toLowerCase().includes('halo topup'))
// &&(count.countryName.toLowerCase().includes('usa'))
))
  }
}

// useEffect(() => {
//   if(myRef&&scroll1){
//     myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  
  
//   }      
       
//         }, [])

useEffect(()=>{ 
  if(country[0]){


if(countryF){
setFilter(country.sort((a, b) => a.id > b.id ? 1 : -1).filter((count)=>
(count.planCategory.toLowerCase().includes('halo topup'))&&

(count.countryName.toLowerCase().includes(countryF))



))



}
if(!countryF&&country[0]){
CountryFilter()

// if(!filter[0]){





// }

}

  }

else{
  setFilter([])
}
      
  },[country,countryF,auth.auth])
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const updatePlan=(value)=>{
        props.category('halo topup')
        props.updatePlan(value)
    }
    return (
        <>
            {/* <div className="Dec6_Pricing_BgImage_Block"></div> */}
            <div ref={myRef} className="D6_PricingCont_GridPlt">
                {/* <Grid container > */}
                    <Grid container item sm={12} xs={12} className="D6_PersCont_GridPlt">
                        <Grid  item sm={12} xs={12}>
                                <div className="D6_Pers_GridPlt">
                                    HALO PASSES TOP UP'S
                                </div>
                        </Grid>
                        <Grid  item sm={12} xs={12}>
                                <AppBar position="static" className="D6_PreviewAppBarPlt">
                    <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    <Tab label="Top Up Offers" {...a11yProps(0)} />
                    {/* <Tab label="Business" {...a11yProps(1)} /> */}
                    </Tabs>
                </AppBar>
                        </Grid>
                        <Grid  item sm={12} xs={12}>
                        <TabPanel value={value} index={0}>

                         <TopUPPricing  cards={cards}live={live} filter={filter} updatePlan={updatePlan}/>
                         </TabPanel>
                        </Grid>
                        {/* <Grid  item sm={12} xs={12}>
                        <TabPanel value={value} index={1}>

                        <Pricingpbizzplt live={live} filter={filter} updatePlan={updatePlan}/>
                        </TabPanel>
                      </Grid> */}
                        
                    </Grid>
            </div>

        </>
    )
}
