import React,{useEffect} from 'react'
import './D6_FE_Accordian_CSS.css'
// import Aos from 'aos';
// import 'aos/dist/aos.css'; 

const D6_FE_Heading = () => {
    // useEffect(() => {
    //     Aos.init({ duration: 1000 });
    // }, []); 
    return (
        <div classname="Heading_Div">
        <h2  className="Accordion_Heading">
                Frequently asked questions
            </h2>
        </div>
    )
}

export default D6_FE_Heading
