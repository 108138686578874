import React ,{useState}from 'react';

import Grid from '@material-ui/core/Grid';
import Image from '../../../assets/Images/HomeHair.png';
import './D6_halo_cardclsweb3.css';
import Avatar from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import HomePh from '@material-ui/icons/PhoneIphoneOutlined';
import WorkPh from '@material-ui/icons/PhoneInTalkOutlined';
import WorkEmail from '@material-ui/icons/EmailOutlined';
import Website from '@material-ui/icons/LanguageOutlined';
import Dialog from '@material-ui/core/Dialog';
import StorefrontIcon from '@material-ui/icons/Storefront';

import { QRCode } from 'react-qrcode-logo';

export default function CardClsWeb3(props) {
  const {name,email,designation,organization,web,mobile,mobile1,image,facebook,instagram,whatsapp,linkedIn,twitter,gitHub}=props
  const image2=`https://d6-halo.s3.us-east-2.amazonaws.com/static/media/profile/${props.image1?props.image1:null}`
  const val=`https://halodigital.app/${props.username?props.username:props.key1}`
  const [active,setActive]=useState(mobile?'m1':mobile1?'m2':'m1')
  const [qr1,setQr1]=useState('card')
  const val1= qr1=='business'&&props.business&&props.business==1?`https://halodigital.app/business/${props.username?props.username:props.key1}`:`https://halodigital.app/${props.username?props.username:props.key1}`

  const [open1, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="ClsDivW3">
    <div className="ClsBoxContainerWeb3">
    <div className="ClsTopContainerWeb3">
    {/* <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
    <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style="stroke: none; fill:red;">
    </path>
  </svg> */}
       </div>
       {/* <div className="BackDropLeftW">
       </div>
       <div className="BackDropRightW">
       </div> */}
       <div className="ClsAvatarCont3">
       <Avatar alt="Remy Sharp" src={props.image1?image2:image?URL.createObjectURL(image):Image} className="ClsAvatarPic3"/>
       </div>
       <div className="D6_CARD_ClsW3_Grid_Name">
                    {name?name:!name?'Your Name':null} 
                    </div>
                    <div className="D6_CARD_ClsW3_Grid_desig">
                    {designation?designation:!name?'Your Designation':null} 
                    </div>
                    <div className="D6_CARD_ClsW3_Grid_comp">
                    {organization?organization:!name?'Your Organization':null} 
                    </div>
            <Grid container spacing={0} direction='row' item xs={12} >
                <Grid  item xs={8} >
                    <div className="D6_Card_ClsW3_ContactData_Grid">
                   
                    {/* <div className="D6_CARD_ClsW3_Grid_desig">
                       Headline
                    </div> */}
                    <div className="D6_CARD_ClsW3_Grid_email">
                    <Grid container  onClick={()=>window.open(`mailto:${email}`)}direction="row" alignItems="center" item xs={12}>
                    <Grid item xs={2}>{
                                  !name||email?<WorkEmail  /> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                  {email?email:!name?'Your Email':null}
                                </Grid>
                        </Grid>
                    </div>
                    <div className="D6_CARD_ClsW3_Grid_web">
                          <Grid container   onClick={()=>{web.includes('://')?window.open(web,'_blank'):window.open(`https://${web}`,'_blank')}}direction="row" alignItems="center" item xs={12}>
                          <Grid item xs={2}>
                                  {!name||web?
                                  <Website /> :null}                       
                                </Grid>
                                <Grid item xs={10}>
                                {web?web:!name?'Your Website':null}         
                                </Grid>
                          </Grid>
                    </div>
                    <div className="D6_CARD_ClsW3_Grid_tel">
                         <Grid container direction="row" alignItems="center" item xs={12}>
                         <Grid item xs={2}>
                                  {!name||mobile?
                                  <HomePh onClick={()=>setActive('m1')}/>:null}                        
                                </Grid>
                                
                                <Grid item xs={2}>
                                {!name||mobile1?
                                  <WorkPh onClick={()=>setActive('m2')}/>:null}
                                </Grid>
                                <Grid onClick={()=>window.location.href=`tel:${mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:null}`} item xs={8}>
                                                                {mobile&&active==='m1'?mobile:mobile1&&active==='m2'?mobile1:!name?'Your Mobile Number':!mobile&&mobile1?mobile1:!mobile1&&mobile?mobile:null} 
 
                                </Grid>
                        </Grid>
                          </div>
                    </div>
                  </Grid>
                <Grid container direction='row' item xs={4} className="D6_Card_ClsW3_QRCont_Grid">
                      <Grid item xs={12} className="D6_Card_ClsW3_QR_Grid">
                      </Grid>
                      <Grid onClick={() => setOpen(!open1)} item xs={12} className="D6_Card_ClsW3_QR_Grid">
                      <QRCode
          size={90}
          value={props.image1?val:'https://halodigital.app'}
        //   logoImage={logo}
        ecLevel='H'
          logoOpacity='1'
          qrStyle='dots'
          // logoWidth='60'
          quietZone='5'
          fgColor='#000'
        
        />
                      </Grid>
                      <Dialog
                              disableScrollLock={ true }

                          style={{alignItems:'center'}}
                          open={open1}
                          // onClose={handleClose}
                          // PaperComponent={PaperComponent}
                          aria-labelledby="draggable-dialog-title"
                        >
                     <Grid item xs={12} className="D6_Card_ClsW1_QR_Grid">
                                          <QRCode
                                          
                              size={200}
                              value={props.image1?val1:'https://halodigital.app'}
                            //   logoImage={logo}
                            ecLevel='H'
                              logoOpacity='1'
                              qrStyle='dots'
                              // logoWidth='60'
                              quietZone='5'
                              fgColor='#000'
                            
                            /> 
                            <Grid container justifyContent='center' sm={12} xs={12}>
<div onClick={()=>{setQr1('card')}} className='change_qr' style={qr1=='card'?{borderBottomStyle:'solid',borderBottomWidth:'2px'}:{borderBottomStyle:"none"}} >Card</div>
{props.business&&props.business==1?<div onClick={()=>{setQr1('business')}} className='change_qr' style={qr1=='business'?{borderBottomStyle:'solid',borderBottomWidth:'2px'}:{borderBottomStyle:"none"}}>Business</div>
:null}
</Grid>

                            <div onClick={()=>{setOpen(false)}} style={{marginTop:'10px',fontWeight:900,backgroundColor:'black',color:'white'}} >Back</div>
                            </Grid>   </Dialog>
                </Grid>
            </Grid>
    </div>
    <Grid container  item xs={12}  alignItems="center" className="ClsBackDropBottomW3">
                <Grid container spacing={1}  item xs={12} alignItems="center"  >
                <Grid container item xs={12} alignItems="center" >
                  <div className="D6_Bottom_SMCont"></div>
                </Grid>
                {facebook?

                <Grid className="D6_Bottom_SM" item xs={2}>
                  <FacebookIcon onClick={()=>{facebook.includes('://')?window.open(facebook,'_blank'):window.open(`https://www.facebook.com/${facebook}`,'_blank')}} />                        
                </Grid>:null}
                {whatsapp?
                <Grid  className="D6_Bottom_SM" item xs={2}>
                  <WhatsAppIcon onClick={()=>{whatsapp.includes('://')?window.open(whatsapp,'_blank'):window.open(`https://wa.me/${whatsapp}`,'_blank')}} />
                </Grid>:null}
                {linkedIn?
                <Grid className="D6_Bottom_SM" item xs={2}>
                  <LinkedInIcon   onClick={()=>{linkedIn.includes('://')?window.open(linkedIn,'_blank'):window.open(`https://www.linkedin.com/in/${linkedIn}`,'_blank')}}/>
                </Grid>:null}
                {instagram?
                <Grid className="D6_Bottom_SM" item xs={2}>
                  <InstagramIcon onClick={()=>instagram.includes('://')?window.open(instagram,'_blank'): window.open(`https://www.instagram.com/${instagram}`,'_blank')} />                       
                </Grid>:null}
                {gitHub?
                <Grid  className="D6_Bottom_SM" item xs={2}>
                    <StorefrontIcon onClick={()=>{gitHub.includes('://')?window.open(gitHub,'_blank'):window.open(`${gitHub}`,'_blank')}}  />
                </Grid>:null}
                {twitter?
                <Grid className="D6_Bottom_SM" item xs={2}>
   <TwitterIcon  onClick={()=>{twitter.includes('://')?window.open(twitter,'_blank'): window.open(`https://twitter.com/${twitter}`,'_blank')}} />
                </Grid>:null}
                </Grid>
            </Grid>
    </div>
  );
}
